import {
  ApolloLink,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { RetryLink } from 'apollo-link-retry'
import fetch from 'isomorphic-unfetch'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/link-context'

import localStorageKeys from 'constants/localStorageKeys'
import { TypedTypePolicies } from 'gqlTypes/typePolicies'

// Set auth context
const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem(localStorageKeys.accessToken)

  return {
    headers: {
      ...headers,
      ...(authToken ? { Authorization: authToken } : {}),
    },
  }
})

// HTTP Links
const strapiLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  fetch,
})
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  fetch,
})

const httpLink = new RetryLink({
  attempts: {
    max: 1,
  },
}).split(
  (operation) => operation.operationName === 'uploadFile',
  uploadLink as any,
  strapiLink as any
)

interface CacheEntry {
  __ref: string
}

interface CacheEntries {
  entries?: CacheEntry[]
}

const mergeEntries = (
  existing: CacheEntries = {},
  incoming: CacheEntries
): CacheEntry[] => {
  const existingEntries = existing?.entries ?? []
  let newEntries: CacheEntry[] = []

  if (incoming?.entries?.length) {
    newEntries = incoming?.entries?.reduce(
      (acc: CacheEntry[], curr: CacheEntry) =>
        existingEntries.find(({ __ref }) => __ref === curr.__ref)
          ? acc
          : [...acc, curr],
      []
    )
  }

  return [...existingEntries, ...newEntries]
}

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      transactions: {
        keyArgs: ['filters', 'name'],
        merge(existing = {}, incoming) {
          return {
            ...existing,
            ...incoming,
            entries: mergeEntries(existing, incoming),
          }
        },
      },
    },
  },
  Project: {
    fields: {
      transactions: {
        keyArgs: ['filters', 'name'],
        merge(existing = {}, incoming) {
          return {
            ...existing,
            ...incoming,
            entries: mergeEntries(existing, incoming),
          }
        },
      },
    },
  },
}

export default new ApolloClient({
  link: ApolloLink.from([authLink, httpLink as any]),
  cache: new InMemoryCache({
    typePolicies,
  }),
})
