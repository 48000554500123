import React, { FC } from 'react'
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

// Styles
import { TooltipContainer } from './Chart.styles'

// Types
interface ChartProps<T> {
  dataKeyX: string
  dataKeyY: string
  data: T[]
}

// Local Components
const CustomizedYAxisTick: FC<any> = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        textAnchor="start"
        fill="#848484"
        dx={-60}
        fontSize={12}>
        € {payload.value}
      </text>
    </g>
  )
}

const CustomizedXAxisTick: FC<any> = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={30}
        textAnchor="middle"
        fill="#848484"
        fontSize={12}>
        {payload.value}
      </text>
    </g>
  )
}

const CustomTooltip: FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <p>{`${label} : € ${payload[0].value}`}</p>
      </TooltipContainer>
    )
  }

  return null
}

export const Chart = <T extends unknown>({
  dataKeyX,
  dataKeyY,
  data,
}: ChartProps<T>) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 10,
          bottom: 20,
        }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0E8484" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#FBFBFB" />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke="#D6D6D6" strokeOpacity={0.3} />
        <XAxis
          dataKey={dataKeyX}
          tickLine={false}
          axisLine={false}
          tick={<CustomizedXAxisTick />}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tick={<CustomizedYAxisTick />}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey={dataKeyY}
          stroke="#0E8484"
          strokeWidth="2px"
          fill="url(#colorUv)"
          dx={10}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default Chart
