import React, { useState, useRef, FC } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

// Constants
import { routes } from 'constants/routes'
import { getLocalStorageItem } from 'utils/localStorage'

// Components
import UserMenu from 'components/Layout/UserMenu/UserMenu'

// Utils
import { useOnClickOutside } from 'utils/useOnClickOutside'
import { useBreakpoint } from 'styles'

// Icons
import { ReactComponent as ExpensesIcon } from 'icons/expenses-icon.svg'
import { ReactComponent as StatisticsIcon } from 'icons/analytics-icon.svg'

// Styles
import {
  Container,
  UserMenuContainer,
  Menu,
  Toggle,
  Separator,
} from './Sidebar.styles'
import WorkspacesDropdown from './WorkspacesDropdown'

interface SidebarProps {
  hideMenuItems?: boolean
}

const Sidebar: FC<SidebarProps> = ({ hideMenuItems }) => {
  const { push, location } = useHistory()
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  const [open, setOpen] = useState(false)

  const renderMenuItems = (url: string) => {
    return [
      {
        text: 'Overview',
        icon: <ExpensesIcon width="24px" height="24px" />,
        url: routes.ENTRY.create({}),
        active: !!matchPath(url, {
          path: [
            routes.ENTRY.template(),
            routes.OVERVIEW.template(),
            routes.EXPENSES.template(),
            routes.PROJECT_DETAILS.template(),
            routes.PROJECT_BUDGET.template(),
          ],
          exact: true,
        }),
        onClick: () =>
          handleRouteChange(
            routes.OVERVIEW.create({ workspaceId: activeWorkspaceId })
          ),
      },
      {
        text: 'Statistics',
        icon: <StatisticsIcon width="24px" height="24px" />,
        url: routes.STATISTICS.create({ workspaceId: activeWorkspaceId }),
        active: !!matchPath(url, {
          path: [routes.STATISTICS.template()],
        }),
        onClick: () =>
          handleRouteChange(
            routes.STATISTICS.create({ workspaceId: activeWorkspaceId })
          ),
      },
    ]
  }
  const [menuItems, setMenuItems] = useState(renderMenuItems(location.pathname))

  const handleRouteChange = (url: string) => {
    push(url)
    setMenuItems(renderMenuItems(url))
  }

  // Mobile
  const isMobile = !useBreakpoint('sm')
  const containerRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(containerRef, () => setOpen(false))

  return (
    <>
      {isMobile && <Toggle $$open={open} onClick={() => setOpen(true)} />}

      <Container ref={containerRef} $$open={open}>
        <WorkspacesDropdown />
        <Separator />

        {!hideMenuItems && <Menu items={menuItems} />}

        <UserMenuContainer>
          <UserMenu />
        </UserMenuContainer>
      </Container>
    </>
  )
}

export default Sidebar
