import { route, param } from 'typesafe-react-router'

export const routes = {
  ENTRY: route(''),
  OVERVIEW: route(param('workspaceId')),

  SIGN_IN: route('signin'),
  SIGN_UP: route('signup'),
  SING_OUT: route('signout'),

  SETTINGS: route('settings'),

  // Expense
  EXPENSES: route(param('workspaceId'), 'e'),

  // Workspace
  WORKSPACE_CREATE: route('workspace', 'create'),

  GROUPS_EDIT: route('groups', 'edit', param('groupId')),
  GROUPS_NEW: route('groups', 'new'),
  NEW_GROUP_FROM_ACTIVE_GROUP: route('group', param('groupId'), 'new-group'),

  WORKSPACE_SETTINGS: route(param('workspaceId'), 'settings'),
  WORKSPACE_SETTINGS_CATEGORIES: route(
    param('workspaceId'),
    'settings',
    'categories'
  ),
  WORKSPACE_SETTINGS_TAGS: route(param('workspaceId'), 'settings', 'tags'),
  WORKSPACE_SETTINGS_MEMBERS: route(
    param('workspaceId'),
    'settings',
    'members'
  ),
  WORKSPACE_ACCEPT_INVITATION: route(
    param('workspaceId'),
    'accept-invitation',
    param('token')
  ),

  // Project
  PROJECT_DETAILS: route(param('workspaceId'), 'p', param('id')),
  PROJECT_BUDGET: route(param('workspaceId'), 'p', param('id'), 'budget'),

  // Statistics
  STATISTICS: route(param('workspaceId'), 'statistics'),
}
