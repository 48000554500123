import styled from 'styled-components'
import { CalendarTodaySharp as CalendarUnstyled } from '@mui/icons-material'

export const Container = styled.div`
  height: 40px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;
  font-size: 16px;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
`

export const Input = styled.input`
  display: flex;
  flex: 1 0 0;
  width: 110px;
  padding: 0 12px;
  border: 0;

  :focus {
    outline: 0;
  }

  ::placeholder {
    font-size: 16px;
    opacity: 50%;
    color: ${({ theme }) => theme.colors.bombay};
  }
`

export const CalendarIcon = styled(CalendarUnstyled)`
  color: ${({ theme }) => theme.colors.mineShaft};
`
