import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'

import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'
import Layout from 'components/Layout'

import { routes } from 'constants/routes'
import { ProjectInfo } from '../../components/ProjectInfo'
import { useModal } from 'components/Modal/ModalContext'
import { ExpenseFormModal } from 'modules/Expenses/components/ExpenseFormModal'
import { CreateExpense } from 'modules/Expenses/components/ExpenseForm'
import { useGetProjectDetailsQuery } from 'gqlTypes'
import Tabs from 'components/Tabs/Tabs'
import {
  ProjectInfoContainer,
  layoutContainerStyles,
} from './ProjectDetailsLayout.styles'
import { ProjectTotals } from '../ProjectTotals'

export const ProjectDetailsLayout = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { workspaceId, id } = useParams<{ workspaceId: string; id: string }>()

  const { showModal, hideModal } = useModal()

  // Operations
  const { data: projectData, loading, refetch } = useGetProjectDetailsQuery({
    variables: {
      id,
    },
  })
  const project = projectData?.project
  const { name, category } = project ?? {
    name: '',
  }
  const projectInfo = { id, name, category }

  const totalIncome = project?.transactions.totals.income || 0
  const totalExpense = project?.transactions.totals.expense ?? 0

  const handleAddTransaction = () => {
    showModal(ExpenseFormModal, {
      title: `New Transaction`,
      children: <CreateExpense projectId={id} />,
      onCloseClick: hideModal,
    })
  }

  const handleProjectTotalsFilter = (value?: [string, string]) => {
    refetch({
      filters: {
        date: value
          ? {
              startDate: value[0],
              endDate: value[1],
            }
          : undefined,
      },
    })
  }

  // TODO: Implement this
  // const [
  //   deleteProject,
  //   { loading: deleteProjectLoading },
  // ] = useDeleteProjectMutation({
  //   refetchQueries: [
  //     {
  //       query: GetProjectsDocument,
  //       variables: {
  //         workspaceId: activeWorkspaceId,
  //       },
  //     },
  //   ],
  // })

  // const handleDeleteProject = (id: string) => {
  //   deleteProject({
  //     variables: {
  //       id,
  //     },
  //   })
  // }

  // Category options
  // const { data: categoriesData } = useGetCategoriesQuery({
  //   variables: {
  //     filters: {},
  //   },
  // })

  // TODO:
  // - query categories with the current parent exepense
  // - return total amount while filtering
  // -- -- maybe it's a good idea to return total for the search and overall total

  // const getCategoryOptions = (filters: FiltersState) =>
  //   categoriesData?.categories?.data?.map((category) => {
  //     return {
  //       value: category.id!,
  //       text: category.attributes?.name!,
  //       active: filters.filters.category === category.id,
  //       onClick: (value: string) => {
  //         filters.setFilters({ ...filters.filters, category: value as string })
  //         filters.updateSearchValue('category' as any, value)
  //       },
  //     }
  //   }) || []

  const loadingData = loading || !project

  return (
    <Layout containerStyleOverrides={layoutContainerStyles}>
      {loadingData ? <Spinner /> : null}

      <ProjectInfoContainer>
        <ProjectInfo {...(projectInfo as any)} />
        <Button onClick={handleAddTransaction} marginLeft="auto">
          Add transaction
        </Button>
      </ProjectInfoContainer>

      <Box mt="5rem">
        <ProjectTotals
          totalExpense={totalExpense}
          totalIncome={totalIncome}
          onChange={handleProjectTotalsFilter}
        />
      </Box>

      <Grid container marginTop="4rem" marginBottom="4.8rem">
        <Grid item xs={12}>
          <Tabs
            tabs={[
              {
                name: 'Transactions',
                path: routes.PROJECT_DETAILS.create({
                  workspaceId,
                  id,
                }),
              },
              {
                name: 'Budget',
                path: routes.PROJECT_BUDGET.create({
                  workspaceId,
                  id,
                }),
              },
            ]}
          />
        </Grid>
      </Grid>

      {children}
    </Layout>
  )
}
