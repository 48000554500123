import React, { FC } from 'react'
import { Formik, Form as FormikForm } from 'formik'

import styled from 'styled-components'

// Types
interface Values {
  [field: string]: any
}

interface FormProps {
  initialValues?: Values
  onSubmit?: (values: Values) => void
}

// Validators
export const validateRequired = (value: string) =>
  !value ? 'Required' : undefined

const FormStyled = styled(FormikForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

// Component
const Form: FC<FormProps> = ({
  children,
  initialValues = {},
  onSubmit,
  ...styles
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit?.(values)}
      enableReinitialize>
      <FormStyled {...styles}>{children}</FormStyled>
    </Formik>
  )
}

export default Form
