import React, { FC, SyntheticEvent, RefObject } from 'react'
import { SpaceProps, LayoutProps } from 'styled-system'

import {
  ButtonContainer,
  IconButtonContainer,
  LinkContainer,
} from './Button.styles'

// Types
type ButtonType = 'button' | 'submit' | 'link' | 'icon'

export type Variant = 'primary' | 'secondary' | 'tertiary'

export type Size = 'small' | 'default' | 'big'

export interface ButtonProps {
  type?: ButtonType
  variant?: Variant
  size?: Size
  fullWidth?: boolean
  onClick?: (data?: any) => void
  url?: string
  icon?: React.ReactNode
  disabled?: boolean
  buttonRef?: RefObject<HTMLButtonElement>
  active?: boolean
}

// Components
const Button: FC<ButtonProps & SpaceProps & LayoutProps> = ({
  children,
  type = 'button',
  variant = 'primary',
  size = 'default',
  fullWidth,
  url,
  onClick,
  disabled = false,
  buttonRef,
  active,
  ...props
}) => {
  const handleLinkClick = (event: SyntheticEvent) => {
    event.preventDefault()
    onClick && onClick()
  }

  switch (type) {
    case 'icon': {
      return (
        <IconButtonContainer type="button" onClick={onClick} {...props}>
          {props.icon || null}
          {children && <div>{children}</div>}
        </IconButtonContainer>
      )
    }
    case 'link': {
      return (
        <LinkContainer href={url} {...props} onClick={handleLinkClick}>
          {children}
        </LinkContainer>
      )
    }
    default: {
      return (
        <ButtonContainer
          ref={buttonRef}
          type={type}
          $$variant={variant}
          $$size={size}
          $$fullWidth={fullWidth}
          $$active={active}
          onClick={onClick}
          disabled={disabled}
          {...props}>
          {props.icon || null}
          {children}
        </ButtonContainer>
      )
    }
  }
}

export default Button
