import React from 'react'
import Select, { StylesConfig } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import ObjectID from 'bson-objectid'
import { useField } from 'formik'

import BaseField, { BaseFieldProps } from '../BaseField/BaseField'
import { validateRequired } from 'components/Form'

// Types
interface Option {
  value: string
  label: string
}

interface NewOptionProps {
  name: string
}

interface SelectFieldProps<T> extends Omit<BaseFieldProps, 'value'> {
  options: T[] | Option[]
  isMulti?: boolean
  isCreatable?: boolean
  placeholder?: string
  onCreateNew?: (option: NewOptionProps) => void
  selectStyles?: StylesConfig<any>
}

const SelectField = <T extends unknown = Option>({
  name,
  label,
  options,
  onCreateNew,
  isMulti = false,
  isCreatable = false,
  placeholder = '',
  required,
  validate,
  selectStyles,
  ...styles
}: SelectFieldProps<T>) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, field, { setValue }] = useField({
    name,
    required,
    validate: validate ? validate : required ? validateRequired : undefined,
  })

  const defaultValue = (options as Option[])?.find(
    (option) => option?.value === field.value
  )

  return (
    <BaseField name={name} label={label} {...styles}>
      {isCreatable ? (
        <CreatableSelect
          isClearable
          options={options}
          value={field.value}
          onChange={(option) => {
            if (!option) {
              setValue(null)
              return
            }

            if (option?.__isNew__!) {
              const categoryId = ObjectID.generate()

              onCreateNew &&
                onCreateNew({
                  name: option.label,
                })
              setValue({ value: categoryId, label: option.label })
            } else {
              setValue(option)
            }
          }}
        />
      ) : (
        <Select
          options={options}
          defaultValue={defaultValue}
          onChange={({ value }) => setValue(value)}
          isMulti={isMulti}
          placeholder={placeholder}
          styles={selectStyles}
        />
      )}
    </BaseField>
  )
}

export default SelectField
