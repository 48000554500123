import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Formik, Form } from 'formik'

// Components
import Modal, { ModalInjectProps } from 'components/Modal/Modal'
import { InputField, ColorPickerField } from 'components/Form'
import Button from 'components/Button/Button'

// GQL
import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  GetCategoriesDocument,
} from 'gqlTypes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

interface EditCategoryModalProps extends ModalInjectProps {
  edit: true
  id: string
  name: string
  color?: string
  onCloseClick: () => void
}

interface CategoryModalProps extends ModalInjectProps {
  edit?: false
  onCloseClick: () => void
}

const CategoryModal: FC<CategoryModalProps | EditCategoryModalProps> = ({
  onCloseClick,
  ...props
}) => {
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  const [createCategory] = useCreateCategoryMutation({
    onCompleted: () => {
      onCloseClick()
    },
    onError: (error) => {
      console.error(error)
      // TODO: Add toast
    },
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const [updateCategory] = useUpdateCategoryMutation({
    onCompleted: () => {
      onCloseClick()
    },
    onError: (error) => {
      console.error(error)
      // TODO: Add toast
    },
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const handleSubmit = (values: any) => {
    if (props.edit) {
      updateCategory({
        variables: {
          id: props.id,
          input: {
            name: values.name,
            color: values.color,
          },
        },
      })
    } else {
      createCategory({
        variables: {
          input: {
            name: values.name,
            color: values.color,
            workspace: activeWorkspaceId,
          },
        },
      })
    }
  }

  return (
    <Formik initialValues={props} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Modal title="Edit Category" onCloseClick={onCloseClick}>
          <Modal.Body>
            <Form>
              <Grid container spacing="32px" padding="0 48px 32px">
                <Grid item sm={12}>
                  <InputField
                    name="name"
                    label="Name"
                    placeholder="Category name"
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <ColorPickerField name="color" label="Color" required />
                </Grid>
              </Grid>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Grid container item padding="0">
              <Grid item margin="0 16px 0 auto">
                <Button variant="secondary">Cancel</Button>
              </Grid>
              <Grid item>
                <Button onClick={submitForm}>Save</Button>
              </Grid>
            </Grid>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}

export default CategoryModal
