import React, { FC, useState } from 'react'
import { LayoutProps } from 'styled-system'
import { FieldValidator } from 'formik'

// Component
import Button from 'components/Button/Button'

// Styles
import {
  Container,
  LabelContainer,
  Label,
  LabelButton,
  Value,
} from './BaseField.styles'

export interface BaseFieldProps extends LayoutProps {
  name: string
  label: string
  required?: boolean
  value?: string
  placeholder?: string
  helpText?: string
  validate?: FieldValidator
  editable?: boolean
  loading?: boolean
  disabled?: boolean
  onSaveClick?: () => void
}

const BaseField: FC<BaseFieldProps> = ({
  name,
  label,
  helpText,
  validate,
  children,
  value,
  editable,
  loading,
  onSaveClick,
  ...styles
}) => {
  const [isEditing, setIsEditing] = useState(false)

  if (editable) {
    if (isEditing) {
      return (
        <Container {...styles} editable={editable}>
          <LabelContainer isEditing>
            <Label htmlFor={name}>{label}</Label>
            <LabelButton onClick={() => setIsEditing(!isEditing)}>
              Cancel
            </LabelButton>
          </LabelContainer>
          {children}
          {editable && onSaveClick && isEditing ? (
            <Button
              type="button"
              marginTop="24px"
              disabled={loading}
              onClick={() => {
                onSaveClick()
                setIsEditing(false)
              }}>
              Save
            </Button>
          ) : null}
        </Container>
      )
    } else {
      return (
        <Container {...styles} editable={editable}>
          <LabelContainer>
            <Label htmlFor={name}>{label}</Label>
            <LabelButton onClick={() => setIsEditing(!isEditing)}>
              Edit
            </LabelButton>
          </LabelContainer>
          <Value>{value}</Value>
        </Container>
      )
    }
  }

  return (
    <Container {...styles}>
      <Label htmlFor={name}>{label}</Label>
      {children}
      {editable && onSaveClick && isEditing ? (
        <Button type="button" marginTop="24px" disabled={loading}>
          Save
        </Button>
      ) : null}
    </Container>
  )
}

export default BaseField
