import React from 'react'
import { useParams } from 'react-router-dom'

import Layout from 'components/Layout'
import Tabs from 'components/Tabs/Tabs'
import { HeaderContainer } from './WorkspaceSettingsLayout.styles'
import { getLocalStorageItem } from 'utils/localStorage'
import { routes } from 'constants/routes'

export const WorkspaceSettingsLayout = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()

  return (
    <Layout>
      <HeaderContainer>
        <h1>Workspace Settings</h1>
        <Tabs
          tabs={[
            {
              name: 'General',
              path: routes.WORKSPACE_SETTINGS.create({ workspaceId }),
            },
            {
              name: 'Members',
              path: routes.WORKSPACE_SETTINGS_MEMBERS.create({
                workspaceId: getLocalStorageItem('activeWorkspace'),
              }),
            },
            {
              name: 'Categories',
              path: routes.WORKSPACE_SETTINGS_CATEGORIES.create({
                workspaceId: getLocalStorageItem('activeWorkspace'),
              }),
            },
            {
              name: 'Tags',
              path: routes.WORKSPACE_SETTINGS_TAGS.create({
                workspaceId: getLocalStorageItem('activeWorkspace'),
              }),
            },
          ]}
        />
      </HeaderContainer>

      {children}
    </Layout>
  )
}
