import React, { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'

import client from 'graphql/apolloClient'

import { AccountProvider } from 'context/AccountContext'
import { ModalProvider } from 'components/Modal/ModalContext'

// Styles
import theme from 'styles/theme'
import GlobalStyle from 'styles/globalStyle'

const Providers: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ApolloProvider client={client}>
      <AccountProvider>
        <BrowserRouter>
          <ModalProvider>{children}</ModalProvider>
        </BrowserRouter>
      </AccountProvider>
    </ApolloProvider>
  </ThemeProvider>
)
export default Providers
