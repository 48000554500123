export const easings = {
  easeIn: 'cubic-bezier(0.4, 0.0, 1, 1)',
  easeOut: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  easeOutExponential: 'cubic-bezier(0.19, 1, 0.22, 1)',
  easeInOut: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  spring: 'cubic-bezier(0.9, -0.85, 0.25, 1.75)',
  oscillate: 'cubic-bezier(0,.15,.4,1)',
}

export const transitions = {
  timing: {
    ...easings,
  },
}
