import React, { FC, useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'

// Utils
import { useBreakpoint } from 'styles'

// Styles
import {
  Toggle,
  OptionsContainer,
  OptionItem,
  OptionSection,
  Arrow,
  Button,
  ButtonTextContainer,
  ChevronContainer,
  Chevron,
} from './Dropdown.styles'
import Modal from 'components/Modal/Modal'
import { ButtonProps } from 'components/Button/Button'

// Types
interface Option {
  text?: string
  value: string
  active?: boolean
  onClick?: (value: string) => void
  separator?: boolean
}

interface BaseToggleProps {
  icon?: React.ReactNode
  active?: boolean
  elementVariant?: 'rounded' | 'button' | 'custom'
}

type RoundedToggleProps = BaseToggleProps & {
  elementVariant: 'rounded'
}

type ButtonToggleProps = BaseToggleProps & {
  elementVariant: 'button'
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  fullWidth?: ButtonProps['fullWidth']
  disabled?: ButtonProps['disabled']
}

type ToggleProps =
  | RoundedToggleProps
  | ButtonToggleProps
  | { elementVariant: 'custom' }

export interface DropdownProps {
  toggle: React.ReactNode
  toggleProps?: ToggleProps
  options: Option[]
  onClick?: () => void
}

export const Dropdown: FC<DropdownProps> = ({
  toggle,
  toggleProps,
  options,
  onClick,
}) => {
  const isMobile = !useBreakpoint('sm')

  // Popper
  const [open, setOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<
    HTMLDivElement | HTMLButtonElement | null
  >(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'preventOverflow', enabled: false }],
    placement: 'bottom-end',
  })

  // Click outsite handler
  const menuContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        isMobile ||
        menuContainerRef?.current?.contains(event.target as Node)
      ) {
        return
      }

      setOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMobile, popperElement])

  // Options
  const renderOptions = () => {
    return options.map((option: Option) => {
      if (option.separator)
        return <hr key={`separator-${new Date().getTime()}`} />

      if (option.onClick) {
        return (
          <OptionItem
            key={`${option.value}`}
            onClick={() => {
              option.onClick?.(option.value)
              setOpen(false)
            }}
            $$active={option.active}>
            {option.text}
          </OptionItem>
        )
      }

      return (
        <OptionSection key={`${option.value}`}>{option.text}</OptionSection>
      )
    })
  }

  const renderToggle = () => {
    switch (toggleProps?.elementVariant) {
      case 'button': {
        return (
          <Button
            size={toggleProps.size || 'small'}
            variant={toggleProps.variant || 'tertiary'}
            icon={toggleProps?.icon}
            buttonRef={setReferenceElement as any}
            active={toggleProps.active}
            fullWidth={toggleProps.fullWidth}>
            <ButtonTextContainer
              disabled={toggleProps.disabled}
              onClick={onClick}>
              {toggle}
            </ButtonTextContainer>
            <ChevronContainer onClick={() => setOpen(!open)}>
              <Chevron />
            </ChevronContainer>
          </Button>
        )
      }
      case 'rounded': {
        return (
          <Toggle
            ref={setReferenceElement}
            onClick={() => setOpen(!open)}
            $$open={open}>
            {toggle}
            <Arrow />
          </Toggle>
        )
      }
      case 'custom': {
        return <div onClick={() => setOpen(!open)}>{toggle}</div>
      }
    }
  }

  return (
    <>
      {renderToggle()}

      {open ? (
        isMobile ? (
          <Modal onCloseClick={() => setOpen(false)}>
            <Modal.Body>
              <OptionsContainer>{renderOptions()}</OptionsContainer>
            </Modal.Body>
          </Modal>
        ) : (
          <div ref={menuContainerRef}>
            <OptionsContainer
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}>
              {renderOptions()}
            </OptionsContainer>
          </div>
        )
      ) : null}
    </>
  )
}
