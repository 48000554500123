import styled from 'styled-components'
import { Input as UnstyledInput } from 'components/Form/InputField/InputField.styles'
import Modal from 'components/Modal/Modal'

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;
  justify-content: space-between;
  position: relative;
`

export const Input = styled(UnstyledInput)`
  width: 100%;
  border: 0;
`

export const ModalBody = styled(Modal.Body)`
  width: 80rem;
`
