import React, { FC } from 'react'
import { matchPath, useHistory } from 'react-router'

// Styles
import { Container, Button } from './Tabs.styles'

interface Tab {
  name: string
  path: string
}

interface TabsProps {
  tabs: Tab[]
}

const Tabs: FC<TabsProps> = ({ tabs }) => {
  const { location, push } = useHistory()

  return (
    <Container>
      {tabs.map((tab) => (
        <Button
          key={`tab-${tab.name}`}
          onClick={() => push(tab.path)}
          active={Boolean(
            matchPath(location.pathname, { path: tab.path, exact: true })
          )}>
          {tab.name}
        </Button>
      ))}
    </Container>
  )
}

export default Tabs
