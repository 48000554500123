import styled, { css } from 'styled-components'
import { Field } from 'formik'

import { InputType } from './InputField'

export const Input = styled(Field)<{ type: InputType }>`
  height: 40px;
  padding: 0 12px;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;

  ${({ type }) =>
    type === 'textarea' &&
    css`
      min-height: 72px;
      padding: 12px;
    `}

  :focus {
    outline: 0;
  }

  ::placeholder {
    font-size: 16px;
    opacity: 50%;
    color: ${({ theme }) => theme.colors.bombay};
  }
`

export const Error = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.pomegranate};
`
