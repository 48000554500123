import {
  GetExpenseDocument,
  UpdateExpenseMutationVariables,
  useUpdateExpenseMutation,
} from 'gqlTypes'

interface UseUpdateExpenseProps {
  id: string
  onCompleted: () => void
}

export const useUpdateExpense = ({
  id,
  onCompleted,
}: UseUpdateExpenseProps) => {
  const [mutate] = useUpdateExpenseMutation({
    refetchQueries: [
      {
        query: GetExpenseDocument,
        variables: {
          id,
        },
      },
    ],
    onCompleted,
  })

  const updateExpense = ({ id, input }: UpdateExpenseMutationVariables) => {
    mutate({
      variables: {
        id,
        input,
      },
    })
  }

  return [updateExpense]
}
