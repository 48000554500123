import localStorageKeys from 'constants/localStorageKeys'

export const getLocalStorageItem = (key: keyof typeof localStorageKeys) => {
  const rawItem =
    window.localStorage.getItem(localStorageKeys[key]) ?? undefined

  try {
    if (rawItem === undefined) return undefined
    const parsedItem = JSON.parse(rawItem)
    if (typeof parsedItem === 'object') {
      return parsedItem
    } else {
      return parsedItem.toString()
    }
  } catch {
    return rawItem
  }
}

export const setLocalStorageItem = (
  key: keyof typeof localStorageKeys,
  value: string
) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(localStorageKeys[key], value)
  }
}

export const removeItem = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    // The error will be ignored
  }
}
