import React, { FC } from 'react'
import { Formik } from 'formik'

// Components
import Layout from 'components/Layout'
import { InputField } from 'components/Form'

// Hooks
import { useAccount } from 'context/AccountContext'

// Styles
import { HeaderContainer } from './AccountSettingsView.styles'
import { Grid } from '@mui/material'

// GQL
import { useUpdateUserMutation } from 'gqlTypes'

const AccountSettingsView: FC = () => {
  const { user } = useAccount()
  const [updateUser] = useUpdateUserMutation()

  const handleSubmit = (data: { email?: string; name?: string }) => {
    try {
      updateUser({
        variables: {
          id: user?.id!,
          input: {
            firstName: data.name,
          },
        },
      })
    } catch {
      console.log('Error updating email')
    }
  }

  return (
    <Layout
      header={
        <HeaderContainer>
          <h1>Account Settings</h1>
        </HeaderContainer>
      }>
      <Formik
        initialValues={{
          name: user?.firstName,
          email: user?.email,
        }}
        onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <Grid container item spacing={2} md={8} lg={6}>
            <Grid item sm={12}>
              <InputField
                name="name"
                label="Name"
                editable
                onSaveClick={submitForm}
              />
            </Grid>
            <Grid item sm={12}>
              <InputField
                name="email"
                label="Email"
                editable
                onSaveClick={submitForm}
              />
            </Grid>
            {/*
            // TODO: Do this later
            <Grid item sm={12}>
              <InputField
                name="password"
                label="Password"
                editable
                onSaveClick={() => {}}
              />
            </Grid>
            */}
          </Grid>
        )}
      </Formik>
    </Layout>
  )
}

export default AccountSettingsView
