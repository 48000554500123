import React, { FC, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.min.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import { Container, Input, CalendarIcon } from './DatePicker.styles'

interface CustomInputProps {
  value?: string
  onClick?: any
}

interface DatePickerProps {
  onChange?: any
  value?: Date
}

const DateRangePicker: FC<DatePickerProps> = ({ value, onChange }) => {
  const [startDate, setStartDate] = useState(value)
  const [open, setOpen] = useState(false)

  return (
    <div style={{ position: 'relative' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          open={open}
          value={startDate}
          inputFormat="dd/MM/yyyy"
          onChange={(value) => {
            setStartDate(value as any)
            onChange(value)
          }}
          onClose={() => setOpen(false)}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Container>
              <CalendarIcon fontSize="large" />
              <Input
                ref={inputRef}
                {...inputProps}
                onClick={() => setOpen(true)}
              />
            </Container>
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default DateRangePicker
