import React, { FC, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/async-creatable'
import ObjectID from 'bson-objectid'
import { useField } from 'formik'

// Components
import BaseField, { BaseFieldProps } from '../BaseField/BaseField'
import { validateRequired } from 'components/Form'

// Constants
import { CATEGORY_COLORS } from 'constants/colors'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

// Styles
import { colors } from 'styles/colors'
import {
  useCreateCategoryMutation,
  GetCategoriesDocument,
  useGetCategoriesQuery,
  CategoryFragment,
} from 'gqlTypes'

// Select styles
const singleValueStyles = (provided: any, state: any, override: any) => {
  const opacity = state.isDisabled ? 0.5 : 1
  const transition = 'opacity 300ms'

  return {
    ...provided,
    opacity,
    transition,
    fontSize: 12,
    fontWeight: 700,
    color: colors.white,
    background: '#e2e2e2',
    borderRadius: 4,
    padding: '2px 8px',
    textTransform: 'capitalize',
    ...override,
  }
}

const baseCustomStyles = {
  menu: (provided: any) => ({
    ...provided,
    fontSize: 12,
    textTransform: 'capitalize',
  }),

  control: (provided: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 300,
  }),

  singleValue: (provided: any, state: any) => {
    return singleValueStyles(provided, state, {})
  },

  multiValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return {
      ...provided,

      opacity,
      transition,
      fontSize: 12,
      fontWeight: 700,
      color: colors.white,
      background: 'red',
      borderRadius: 4,
      padding: '2px 8px',
    }
  },

  multiValueLabel: (provided: any) => {
    return {
      ...provided,
      color: colors.white,
    }
  },
}

// Types
interface LocalValue {
  value: string
  label: string
  color: string
}

interface CategoryFieldProps extends Omit<BaseFieldProps, 'value'> {
  multi?: boolean
  placeholder?: string
  onCreateNew?: (category: CategoryFragment) => void
}

const CategoryField: FC<CategoryFieldProps> = ({
  name,
  label,
  onCreateNew,
  placeholder = '',
  required,
  validate,
  multi = false,
  ...styles
}) => {
  const workspaceId = getLocalStorageItem('activeWorkspace')

  const { data: categoriesData, refetch } = useGetCategoriesQuery({
    variables: {
      workspaceId,
      filters: {
        name: '',
      },
    },
  })
  const categories = categoriesData?.categories?.entries

  // eslint-disable-next-line no-empty-pattern
  const [{}, field, { setValue }] = useField({
    name,
    required,
    validate: validate ? validate : required ? validateRequired : undefined,
  })
  const [localValue, setLocalValue] = useState<LocalValue | undefined>()

  const [customStyles, setCustomStyles] = useState(baseCustomStyles)

  useEffect(() => {
    if (field.value && !localValue) {
      const categoryDetails = categories?.find(
        (category) => category.id === field.value
      )
      if (!categoryDetails) return

      const { ...newCustomStyles } = customStyles

      newCustomStyles.singleValue = (provided: any, state: any) =>
        singleValueStyles(provided, state, {
          background: categoryDetails.color,
        })
      setCustomStyles(newCustomStyles)
      setLocalValue({
        value: categoryDetails.id,
        label: categoryDetails.name,
        color: categoryDetails.color,
      })
    }
  }, [field, customStyles, localValue, categories])

  const [createCategory] = useCreateCategoryMutation({
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId,
        },
      },
    ],
    onCompleted: (data) => {
      onCreateNew?.(data.createCategory!)
    },
  })

  // const categoriesOptions =
  //   categories?.map((category) => {
  //     return {
  //       value: category.id,
  //       label: category.name,
  //       color: category.color,
  //     }
  //   }) || []

  const handleCreateCategory = ({
    name,
    color,
  }: {
    name: string
    color: string
  }) => {
    createCategory({
      variables: {
        input: {
          workspace: workspaceId,
          name,
          color,
        },
      },
    })
  }

  const loadOptions = (searchValue: string) =>
    new Promise<any>(async (resolve) => {
      const resolt = await refetch({
        workspaceId,
        filters: {
          name: searchValue,
        },
      })

      const mappedCategories = resolt.data.categories.entries.map(
        (category) => {
          return {
            value: category.id,
            label: category.name,
            color: category.color,
          }
        }
      )

      resolve(mappedCategories)
    })

  return (
    <BaseField name={name} label={label} {...styles}>
      <CreatableSelect
        cacheOptions
        isClearable
        defaultOptions
        isMulti={multi}
        loadOptions={loadOptions}
        styles={customStyles}
        value={localValue}
        onChange={(option: any) => {
          if (!option) {
            setValue(null)
            return
          }

          const { ...newCustomStyles } = customStyles

          if (option?.__isNew__!) {
            const categoryId = ObjectID.generate()

            const categoryRandomColorIndex = Math.floor(
              Math.random() * Object.keys(CATEGORY_COLORS).length - 1
            )
            const color = Object.values(CATEGORY_COLORS)[
              categoryRandomColorIndex
            ]
            newCustomStyles.singleValue = (provided: any, state: any) =>
              singleValueStyles(provided, state, {
                background: color,
              })

            handleCreateCategory({
              name: option.label,
              color: color,
            })

            setValue(categoryId)
            setLocalValue({
              value: categoryId,
              label: option.label,
              color: color,
            })
          } else {
            newCustomStyles.singleValue = (provided: any, state: any) =>
              singleValueStyles(provided, state, {
                background: option.color,
              })
            setValue(option.value)
            setLocalValue(option)
          }

          setCustomStyles(newCustomStyles)
        }}
      />
    </BaseField>
  )
}

export default CategoryField
