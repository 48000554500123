import { Grid } from '@mui/material'
import React from 'react'
import {
  ParentExpenseHeaderContainer,
  Tag,
} from '../ProjectDetailsLayout/ProjectDetailsLayout.styles'
import { BaseProjectDetailsFragment } from 'gqlTypes'
import Button from 'components/Button/Button'
import { useModal } from 'components/Modal/ModalContext'
import { ProjectFormModal } from '../ProjectForm/ProjectFormModal'
import { UpdateProject } from '../ProjectForm/UpdateProject'

interface ProjectInfoProps {
  id: string
  name: string
  category: BaseProjectDetailsFragment['category']
}

export const ProjectInfo = ({ id, name, category }: ProjectInfoProps) => {
  const { showModal, hideModal } = useModal()

  const handleViewProjectDetails = () => {
    showModal(ProjectFormModal, {
      title: `Edit Project`,
      children: <UpdateProject id={id} />,
      onCloseClick: hideModal,
    })
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ParentExpenseHeaderContainer>
          <h1>{name}</h1>

          {category?.name ? (
            <Tag tag={category?.name!} color={category?.color!} />
          ) : null}
        </ParentExpenseHeaderContainer>
        <Button type="link" onClick={handleViewProjectDetails}>
          <small>View Project Details</small>
        </Button>
      </Grid>
    </Grid>
  )
}
