import React, { FC } from 'react'
import { WorkspaceMembersList } from 'modules/Workspace/components/WorkspaceMembersList'
import { ButtonContainer } from './WorkspaceSettingsMembersView.styles'
import Button from 'components/Button/Button'
import { useModal } from 'components/Modal/ModalContext'
import { InviteMember } from './InviteMember'

export const WorkspaceSettingsMembersView: FC = () => {
  const { showModal } = useModal()

  const handleInviteClick = () => {
    showModal(InviteMember, {})
  }

  return (
    <>
      <ButtonContainer>
        <Button onClick={handleInviteClick}>Invite People</Button>
      </ButtonContainer>

      <WorkspaceMembersList />
    </>
  )
}
