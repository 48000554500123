import React, { FC, useRef, useState, useEffect } from 'react'
import { FileUpload } from '@mui/icons-material'

// Components
import BaseField, { BaseFieldProps } from 'components/Form/BaseField/BaseField'
import {
  Input,
  FilesContainer,
  FilePreviewContainer,
  PDFIcon,
  PictureIcon,
  DeleteIcon,
  FileName,
  FileLink,
  TextIconContainer,
} from './FileUploadField.styles'
import Button from 'components/Button/Button'

// GQL
import { useUploadFileMutation, useDeleteFileMutation } from 'gqlTypes'
import { useField } from 'formik'

const ALLOWED_IMAGE_EXTENSIONs = '.jpg, .jpeg, .png, .pdf'

interface FileUploadFieldProps extends BaseFieldProps {
  onUploadStart?: () => void
  onUploadEnd?: () => void
}

export interface File {
  id: string
  name: string
  url?: string
  ext?: string
}

interface FilePreview extends File {
  loading?: boolean
}

interface FilePreviewProps extends FilePreview {
  onDeleteClick?: (id: string) => void
}

const FilePreview = ({
  id,
  ext,
  name,
  url,
  loading,
  onDeleteClick,
}: FilePreviewProps) => {
  const content = (
    <TextIconContainer>
      {ext?.includes('pdf') ? <PDFIcon /> : <PictureIcon />}
      <FileName>{name}</FileName>
    </TextIconContainer>
  )

  return (
    <FilePreviewContainer $loading={loading}>
      {url ? (
        <>
          <FileLink href={url} download={name} target="__blank">
            {content}
          </FileLink>
          <DeleteIcon onClick={() => onDeleteClick?.(id)} />
        </>
      ) : (
        content
      )}
    </FilePreviewContainer>
  )
}

const FileUploadField: FC<FileUploadFieldProps> = ({
  name,
  label,
  required,
  validate,
  onUploadStart,
  onUploadEnd,
  ...styles
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [filePreviews, setFilePreviews] = useState<FilePreview[]>([])

  // eslint-disable-next-line no-empty-pattern
  const [{}, field, { setValue }] = useField<File[]>({
    name,
    required,
  })

  // Mutations
  const [uploadFile, { error }] = useUploadFileMutation()
  const [deleteFile] = useDeleteFileMutation()

  useEffect(() => {
    if (field?.value?.length && !filePreviews.length) {
      const previewImagesArr = field.value.map((file) => ({
        id: file.id,
        name: file.name,
        ext: file.ext,
        url: file.url,
      }))
      setFilePreviews(previewImagesArr)
    }
  }, [field, filePreviews])

  // Returns an updated list of FilePreview
  const getFilePreviewsUpdated = (
    array: FilePreview[],
    key: keyof FilePreview,
    keyValue: string,
    properties: Partial<FilePreview>
  ) =>
    array.map((filePreview) =>
      filePreview[key] === keyValue
        ? {
            ...filePreview,
            ...properties,
          }
        : filePreview
    )

  const updateFilePreview = (
    key: keyof FilePreview,
    keyValue: string,
    properties: Partial<FilePreview>
  ) => {
    setFilePreviews((currentFilePreviews) =>
      getFilePreviewsUpdated(currentFilePreviews, key, keyValue, properties)
    )
  }

  const removeFileFromFilePreviews = (id: string) => {
    const updatedFilePreviews = (currentFilePreviews: FilePreview[]) =>
      currentFilePreviews.reduce(
        (acc: FilePreview[], curr) => (curr.id === id ? acc : [...acc, curr]),
        []
      )
    setFilePreviews((currentFilePreviews) =>
      updatedFilePreviews(currentFilePreviews || [])
    )
  }

  const handleOpenFileBrowser = () => {
    if (!inputRef.current) return

    inputRef.current.click()
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // This variable is to keep state updates in sync, since we can't relly on useEffect
    // for this case
    let localPreviews: FilePreview[] = filePreviews

    // TODO: Handle case, when a upload is ongoing and user closes the modal
    onUploadStart?.()

    const files = event.target.files

    if (!files) return
    const filesArray = Array.from(files)

    // Set file previews
    const newFilePreviews = filesArray.map((file) => ({
      id: file.name,
      name: file.name,
      ext: file.type,
      loading: true,
    }))
    setFilePreviews([...(filePreviews || []), ...newFilePreviews])

    localPreviews.push(...newFilePreviews)

    // Upload each one of the files
    const uploadedFilesDataPromise = filesArray.map(async (file) => {
      const { data } = await uploadFile({
        variables: {
          input: {
            file,
            name: file.name,
          },
        },
      })

      localPreviews = getFilePreviewsUpdated(localPreviews, 'name', file.name, {
        id: data?.uploadFile.id!,
        loading: false,
        url: data?.uploadFile.url,
      })

      updateFilePreview('name', file.name, {
        id: data?.uploadFile.id!,
        loading: false,
        url: data?.uploadFile.url,
      })

      return {
        id: data?.uploadFile.id!,
        name: file.name,
        url: data?.uploadFile.url,
      }
    })

    // // Update form values with uploaded data
    await Promise.all(uploadedFilesDataPromise)

    setValue(localPreviews.map(({ id, name, url }) => ({ id, name, url })))

    onUploadEnd?.()
  }

  const handleDeleteClick = async (id: string) => {
    try {
      // Set loading state for the file under file previews
      updateFilePreview('id', id, {
        loading: true,
      })

      await deleteFile({
        variables: {
          id,
        },
      })

      // Remove the file from file previews
      removeFileFromFilePreviews(id)

      const newFields = filePreviews.reduce(
        (acc: FilePreview[], curr) => (curr.id === id ? acc : [...acc, curr]),
        []
      )
      setValue(newFields.map(({ id, name, url }) => ({ id, name, url })))
    } catch {
      console.error('Error removing file')
      // showToast({
      //   type: 'error',
      //   message: 'Error removing file.',
      // })
    }
  }

  return (
    <BaseField name={name} label={label} {...styles}>
      <Input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept={ALLOWED_IMAGE_EXTENSIONs}
        multiple={true}
      />

      <FilesContainer>
        {filePreviews?.map((file) => (
          <FilePreview
            key={file.id}
            {...file}
            onDeleteClick={handleDeleteClick}
          />
        ))}
      </FilesContainer>

      <Button
        type="icon"
        icon={<FileUpload sx={{ fontSize: 48 }} />}
        onClick={handleOpenFileBrowser}>
        Upload Image
      </Button>
      {error && <>Error</>}
    </BaseField>
  )
}

export default FileUploadField
