import React, { FC, RefObject } from 'react'

// Styles
import { Container, Input, SearchIcon, ClearIcon } from './SearchInput.styles'

// Types
interface SearchInputProps {
  placeholder: string
  name: string
  value: string
  inputRef: RefObject<HTMLInputElement>
  onFocus?: (event: React.FocusEvent) => void
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  onClearClick: () => void
}

export const SearchInput: FC<SearchInputProps> = ({
  placeholder,
  name,
  value,
  inputRef,
  onFocus,
  onChange,
  onClearClick,
}) => {
  return (
    <Container>
      <SearchIcon />
      <Input
        ref={inputRef}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        autoComplete="off"
      />
      <ClearIcon onClick={onClearClick} />
    </Container>
  )
}
