import styled from 'styled-components'

export const Container = styled.div<{
  borderStyle?: 'solid' | 'dotted'
  variant?: 'primary' | 'secondary'
}>`
  display: flex;
  border-width: 2px;
  border-radius: 8px;
  border-color: ${({ theme, borderStyle }) =>
    borderStyle === 'solid' ? 'transparent' : theme.colors.alto};
  border-style: ${({ borderStyle }) => borderStyle};
  box-shadow: ${({ variant }) =>
    variant === 'primary' ? '0 1px 10px 0 hsla(0, 0%, 0%, 0.08)' : 'none'};
`
