import React, { FC, useEffect } from 'react'
import { SpaceProps } from 'styled-system'
import { useFormikContext } from 'formik'

import { ButtonProps } from 'components/Button/Button'
import { Dropdown, DropdownProps } from 'components/Dropdown'

interface DropdownButtonFieldProps extends SpaceProps {
  value?: string
  options: DropdownProps['options']
  type?: ButtonProps['type']
  disabled?: ButtonProps['disabled']
  onSubmit?: (values: any) => void
}

const DropdownButtonField: FC<DropdownButtonFieldProps> = ({
  value,
  options,
  disabled,
  onSubmit,
}) => {
  const { values, isSubmitting, isValid, validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const activeOption =
    options.find((option) => option.value === value) || options[0]

  const optionsWithActive = options.map((option) => {
    option.active = option.value === activeOption.value
    return option
  })

  return (
    <Dropdown
      toggle={activeOption.text}
      toggleProps={{
        elementVariant: 'button',
        fullWidth: true,
        variant: 'primary',
        size: 'default',
        disabled: !isValid || isSubmitting || disabled,
      }}
      options={optionsWithActive}
      onClick={() => {
        onSubmit?.(values)
      }}
    />
  )
}

export default DropdownButtonField
