import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import moment from 'moment'

// Components
import { useModal } from 'components/Modal/ModalContext'

// Constants
import { routes } from 'constants/routes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

// Types
import ProjectFormContent, { FormValues } from './ProjectFormContent'
import { useCreateProjectMutation } from 'gqlTypes'

const initialValues: FormValues = {
  name: '',
  date: moment().format('YYYY-MM-DD HH:mm:ss'),
}

export const CreateProjectForm = () => {
  const { hideModal } = useModal()
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  // Route handling
  const { push } = useHistory()

  const [createProject] = useCreateProjectMutation({
    onCompleted: hideModal,
  })

  // Handles
  const handleCancel = () => {
    hideModal()
    push(routes.ENTRY.create({}))
  }

  const handleSubmit = ({ ...values }: FormValues) => {
    const commonFormData = {
      ...values,
      workspaceId: activeWorkspaceId,
    }

    createProject({
      variables: {
        input: commonFormData,
      },
    })
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <ProjectFormContent
          submitButtonText="Create Project"
          loading={false}
          onCancel={handleCancel}
        />
      </Form>
    </Formik>
  )
}
