import styled, { css } from 'styled-components'
import { ReactComponent as ChevronUnstyled } from 'icons/chevron-down-icon.svg'

export const Container = styled.div`
  display: flex;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 17.8rem;
  padding-right: 3.2rem;
  margin-right: 3.2rem;
  border-right: 1px solid hsl(0, 0%, 90%);
  color: red;
`

export const DropdownFilterContent = styled.div`
  position: relative;
  color: hsl(0, 0%, 0%);
`

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Chevron = styled(ChevronUnstyled)`
  display: flex;
`

export const FilterTitle = styled.div`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  color: hsl(0, 0%, 17%);
`

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;

  :last-child {
    margin-left: 4rem;
  }
`

export const TotalTitle = styled.div`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
  color: hsl(0, 0%, 52%);
`

export const totalValue = css`
  font-size: 3.2rem;
  line-height: 1.1;
  font-weight: 600;
  color: hsl(0, 0%, 17%);
`

export const TotalValueSpent = styled.div`
  ${totalValue};
  color: hsl(0, 100%, 63%);
`

export const TotalValueIncome = styled.div`
  ${totalValue};
  color: hsl(180, 81%, 29%);
`
