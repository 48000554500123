/* eslint-disable */
import * as Types from './operations'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    name
    color
  }
`
export const BudgetAllocationFragmentDoc = gql`
  fragment BudgetAllocation on BudgetAllocation {
    id
    category {
      ...Category
    }
    allocatedAmount
    spentAmount
  }
  ${CategoryFragmentDoc}
`
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    id
    name
  }
`
export const BaseExpenseDetailsFragmentDoc = gql`
  fragment BaseExpenseDetails on Transaction {
    id
    type
    name
    category {
      ...Category
    }
    tags {
      ...Tag
    }
    date
    amount
    notes
    pinned
  }
  ${CategoryFragmentDoc}
  ${TagFragmentDoc}
`
export const FileFragmentDoc = gql`
  fragment File on File {
    id
    name
    url
    mimetype
  }
`
export const ExpenseDetailsFragmentDoc = gql`
  fragment ExpenseDetails on Transaction {
    id
    type
    name
    category {
      ...Category
    }
    tags {
      ...Tag
    }
    date
    amount
    notes
    pinned
    files {
      ...File
    }
    draft
  }
  ${CategoryFragmentDoc}
  ${TagFragmentDoc}
  ${FileFragmentDoc}
`
export const TransactionsTotalsFragmentDoc = gql`
  fragment TransactionsTotals on TransactionsTotals {
    expense
    income
  }
`
export const ProjectDetailsWithTotalsFragmentDoc = gql`
  fragment ProjectDetailsWithTotals on Project {
    id
    name
    date
    transactions {
      totals {
        ...TransactionsTotals
      }
    }
  }
  ${TransactionsTotalsFragmentDoc}
`
export const BaseProjectDetailsFragmentDoc = gql`
  fragment BaseProjectDetails on Project {
    id
    name
    description
    date
    category {
      ...Category
    }
    tags {
      ...Tag
    }
  }
  ${CategoryFragmentDoc}
  ${TagFragmentDoc}
`
export const BaseUserFragmentDoc = gql`
  fragment BaseUser on User {
    id
    firstName
    lastName
    email
    photo {
      ...File
    }
    personalWorkspace {
      id
      name
    }
  }
  ${FileFragmentDoc}
`
export const WorkspaceMemberFragmentDoc = gql`
  fragment WorkspaceMember on WorkspaceMember {
    id
    user {
      ...BaseUser
    }
  }
  ${BaseUserFragmentDoc}
`
export const WorkspaceInvitationFragmentDoc = gql`
  fragment WorkspaceInvitation on WorkspaceInvitation {
    id
    email
    role
  }
`
export const WorkspaceDetailsFragmentDoc = gql`
  fragment WorkspaceDetails on Workspace {
    id
    name
    members {
      entries {
        ...WorkspaceMember
        ...WorkspaceInvitation
      }
    }
    owner {
      ...BaseUser
    }
    image {
      ...File
    }
  }
  ${WorkspaceMemberFragmentDoc}
  ${WorkspaceInvitationFragmentDoc}
  ${BaseUserFragmentDoc}
  ${FileFragmentDoc}
`
export const CreateBudgetDocument = gql`
  mutation createBudget($input: CreateBudgetInput!) {
    createBudget(input: $input) {
      id
    }
  }
`
export type CreateBudgetMutationFn = Apollo.MutationFunction<
  Types.CreateBudgetMutation,
  Types.CreateBudgetMutationVariables
>

/**
 * __useCreateBudgetMutation__
 *
 * To run a mutation, you first call `useCreateBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetMutation, { data, loading, error }] = useCreateBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateBudgetMutation,
    Types.CreateBudgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateBudgetMutation,
    Types.CreateBudgetMutationVariables
  >(CreateBudgetDocument, options)
}
export type CreateBudgetMutationHookResult = ReturnType<
  typeof useCreateBudgetMutation
>
export type CreateBudgetMutationResult = Apollo.MutationResult<
  Types.CreateBudgetMutation
>
export type CreateBudgetMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateBudgetMutation,
  Types.CreateBudgetMutationVariables
>
export const UpdateBudgetDocument = gql`
  mutation updateBudget($id: ID!, $input: UpdateBudgetInput!) {
    updateBudget(id: $id, input: $input) {
      id
    }
  }
`
export type UpdateBudgetMutationFn = Apollo.MutationFunction<
  Types.UpdateBudgetMutation,
  Types.UpdateBudgetMutationVariables
>

/**
 * __useUpdateBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetMutation, { data, loading, error }] = useUpdateBudgetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateBudgetMutation,
    Types.UpdateBudgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateBudgetMutation,
    Types.UpdateBudgetMutationVariables
  >(UpdateBudgetDocument, options)
}
export type UpdateBudgetMutationHookResult = ReturnType<
  typeof useUpdateBudgetMutation
>
export type UpdateBudgetMutationResult = Apollo.MutationResult<
  Types.UpdateBudgetMutation
>
export type UpdateBudgetMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateBudgetMutation,
  Types.UpdateBudgetMutationVariables
>
export const DeleteBudgetDocument = gql`
  mutation deleteBudget($id: ID!) {
    deleteBudget(id: $id)
  }
`
export type DeleteBudgetMutationFn = Apollo.MutationFunction<
  Types.DeleteBudgetMutation,
  Types.DeleteBudgetMutationVariables
>

/**
 * __useDeleteBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetMutation, { data, loading, error }] = useDeleteBudgetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteBudgetMutation,
    Types.DeleteBudgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteBudgetMutation,
    Types.DeleteBudgetMutationVariables
  >(DeleteBudgetDocument, options)
}
export type DeleteBudgetMutationHookResult = ReturnType<
  typeof useDeleteBudgetMutation
>
export type DeleteBudgetMutationResult = Apollo.MutationResult<
  Types.DeleteBudgetMutation
>
export type DeleteBudgetMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteBudgetMutation,
  Types.DeleteBudgetMutationVariables
>
export const CreateBudgetAllocationDocument = gql`
  mutation createBudgetAllocation($input: CreateBudgetAllocationInput!) {
    createBudgetAllocation(input: $input) {
      id
    }
  }
`
export type CreateBudgetAllocationMutationFn = Apollo.MutationFunction<
  Types.CreateBudgetAllocationMutation,
  Types.CreateBudgetAllocationMutationVariables
>

/**
 * __useCreateBudgetAllocationMutation__
 *
 * To run a mutation, you first call `useCreateBudgetAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetAllocationMutation, { data, loading, error }] = useCreateBudgetAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBudgetAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateBudgetAllocationMutation,
    Types.CreateBudgetAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateBudgetAllocationMutation,
    Types.CreateBudgetAllocationMutationVariables
  >(CreateBudgetAllocationDocument, options)
}
export type CreateBudgetAllocationMutationHookResult = ReturnType<
  typeof useCreateBudgetAllocationMutation
>
export type CreateBudgetAllocationMutationResult = Apollo.MutationResult<
  Types.CreateBudgetAllocationMutation
>
export type CreateBudgetAllocationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateBudgetAllocationMutation,
  Types.CreateBudgetAllocationMutationVariables
>
export const GetBudgetDocument = gql`
  query getBudget($projectId: ID!) {
    budget(projectId: $projectId) {
      id
      totalAmount
      spentAmount
      unallocatedAmount
      startDate
      endDate
      recurrent
      allocations {
        ...BudgetAllocation
      }
    }
  }
  ${BudgetAllocationFragmentDoc}
`

/**
 * __useGetBudgetQuery__
 *
 * To run a query within a React component, call `useGetBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetBudgetQuery,
    Types.GetBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetBudgetQuery, Types.GetBudgetQueryVariables>(
    GetBudgetDocument,
    options
  )
}
export function useGetBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetBudgetQuery,
    Types.GetBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetBudgetQuery,
    Types.GetBudgetQueryVariables
  >(GetBudgetDocument, options)
}
export type GetBudgetQueryHookResult = ReturnType<typeof useGetBudgetQuery>
export type GetBudgetLazyQueryHookResult = ReturnType<
  typeof useGetBudgetLazyQuery
>
export type GetBudgetQueryResult = Apollo.QueryResult<
  Types.GetBudgetQuery,
  Types.GetBudgetQueryVariables
>
export const CreateCategoryDocument = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`
export type CreateCategoryMutationFn = Apollo.MutationFunction<
  Types.CreateCategoryMutation,
  Types.CreateCategoryMutationVariables
>

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCategoryMutation,
    Types.CreateCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateCategoryMutation,
    Types.CreateCategoryMutationVariables
  >(CreateCategoryDocument, options)
}
export type CreateCategoryMutationHookResult = ReturnType<
  typeof useCreateCategoryMutation
>
export type CreateCategoryMutationResult = Apollo.MutationResult<
  Types.CreateCategoryMutation
>
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCategoryMutation,
  Types.CreateCategoryMutationVariables
>
export const UpdateCategoryDocument = gql`
  mutation updateCategory($id: ID!, $input: UpdateCategoryInput!) {
    updateCategory(id: $id, input: $input) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`
export type UpdateCategoryMutationFn = Apollo.MutationFunction<
  Types.UpdateCategoryMutation,
  Types.UpdateCategoryMutationVariables
>

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCategoryMutation,
    Types.UpdateCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateCategoryMutation,
    Types.UpdateCategoryMutationVariables
  >(UpdateCategoryDocument, options)
}
export type UpdateCategoryMutationHookResult = ReturnType<
  typeof useUpdateCategoryMutation
>
export type UpdateCategoryMutationResult = Apollo.MutationResult<
  Types.UpdateCategoryMutation
>
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCategoryMutation,
  Types.UpdateCategoryMutationVariables
>
export const DeleteCategoryDocument = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`
export type DeleteCategoryMutationFn = Apollo.MutationFunction<
  Types.DeleteCategoryMutation,
  Types.DeleteCategoryMutationVariables
>

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteCategoryMutation,
    Types.DeleteCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteCategoryMutation,
    Types.DeleteCategoryMutationVariables
  >(DeleteCategoryDocument, options)
}
export type DeleteCategoryMutationHookResult = ReturnType<
  typeof useDeleteCategoryMutation
>
export type DeleteCategoryMutationResult = Apollo.MutationResult<
  Types.DeleteCategoryMutation
>
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCategoryMutation,
  Types.DeleteCategoryMutationVariables
>
export const GetCategoriesDocument = gql`
  query getCategories($workspaceId: ID!, $filters: CategoryFiltersInput) {
    categories(workspaceId: $workspaceId, filters: $filters) {
      entries {
        ...Category
      }
    }
  }
  ${CategoryFragmentDoc}
`

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCategoriesQuery,
    Types.GetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetCategoriesQuery,
    Types.GetCategoriesQueryVariables
  >(GetCategoriesDocument, options)
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCategoriesQuery,
    Types.GetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetCategoriesQuery,
    Types.GetCategoriesQueryVariables
  >(GetCategoriesDocument, options)
}
export type GetCategoriesQueryHookResult = ReturnType<
  typeof useGetCategoriesQuery
>
export type GetCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesLazyQuery
>
export type GetCategoriesQueryResult = Apollo.QueryResult<
  Types.GetCategoriesQuery,
  Types.GetCategoriesQueryVariables
>
export const CreateExpenseDocument = gql`
  mutation createExpense($input: CreateExpenseInput!) {
    createExpense(input: $input) {
      id
    }
  }
`
export type CreateExpenseMutationFn = Apollo.MutationFunction<
  Types.CreateExpenseMutation,
  Types.CreateExpenseMutationVariables
>

/**
 * __useCreateExpenseMutation__
 *
 * To run a mutation, you first call `useCreateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseMutation, { data, loading, error }] = useCreateExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateExpenseMutation,
    Types.CreateExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateExpenseMutation,
    Types.CreateExpenseMutationVariables
  >(CreateExpenseDocument, options)
}
export type CreateExpenseMutationHookResult = ReturnType<
  typeof useCreateExpenseMutation
>
export type CreateExpenseMutationResult = Apollo.MutationResult<
  Types.CreateExpenseMutation
>
export type CreateExpenseMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateExpenseMutation,
  Types.CreateExpenseMutationVariables
>
export const UpdateExpenseDocument = gql`
  mutation updateExpense($id: ID!, $input: UpdateExpenseInput!) {
    updateExpense(id: $id, input: $input) {
      id
    }
  }
`
export type UpdateExpenseMutationFn = Apollo.MutationFunction<
  Types.UpdateExpenseMutation,
  Types.UpdateExpenseMutationVariables
>

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateExpenseMutation,
    Types.UpdateExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateExpenseMutation,
    Types.UpdateExpenseMutationVariables
  >(UpdateExpenseDocument, options)
}
export type UpdateExpenseMutationHookResult = ReturnType<
  typeof useUpdateExpenseMutation
>
export type UpdateExpenseMutationResult = Apollo.MutationResult<
  Types.UpdateExpenseMutation
>
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateExpenseMutation,
  Types.UpdateExpenseMutationVariables
>
export const DeleteExpenseDocument = gql`
  mutation deleteExpense($id: ID!) {
    deleteExpense(id: $id)
  }
`
export type DeleteExpenseMutationFn = Apollo.MutationFunction<
  Types.DeleteExpenseMutation,
  Types.DeleteExpenseMutationVariables
>

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteExpenseMutation,
    Types.DeleteExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteExpenseMutation,
    Types.DeleteExpenseMutationVariables
  >(DeleteExpenseDocument, options)
}
export type DeleteExpenseMutationHookResult = ReturnType<
  typeof useDeleteExpenseMutation
>
export type DeleteExpenseMutationResult = Apollo.MutationResult<
  Types.DeleteExpenseMutation
>
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteExpenseMutation,
  Types.DeleteExpenseMutationVariables
>
export const CreateProjectExpenseDocument = gql`
  mutation createProjectExpense($input: CreateProjectExpenseInput!) {
    createProjectExpense(input: $input) {
      id
    }
  }
`
export type CreateProjectExpenseMutationFn = Apollo.MutationFunction<
  Types.CreateProjectExpenseMutation,
  Types.CreateProjectExpenseMutationVariables
>

/**
 * __useCreateProjectExpenseMutation__
 *
 * To run a mutation, you first call `useCreateProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectExpenseMutation, { data, loading, error }] = useCreateProjectExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateProjectExpenseMutation,
    Types.CreateProjectExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateProjectExpenseMutation,
    Types.CreateProjectExpenseMutationVariables
  >(CreateProjectExpenseDocument, options)
}
export type CreateProjectExpenseMutationHookResult = ReturnType<
  typeof useCreateProjectExpenseMutation
>
export type CreateProjectExpenseMutationResult = Apollo.MutationResult<
  Types.CreateProjectExpenseMutation
>
export type CreateProjectExpenseMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateProjectExpenseMutation,
  Types.CreateProjectExpenseMutationVariables
>
export const PinOrUnpinExpenseDocument = gql`
  mutation pinOrUnpinExpense($id: ID!, $input: PinOrUnpinExpenseInput!) {
    pinOrUnpinExpense(id: $id, input: $input) {
      id
      pinned
    }
  }
`
export type PinOrUnpinExpenseMutationFn = Apollo.MutationFunction<
  Types.PinOrUnpinExpenseMutation,
  Types.PinOrUnpinExpenseMutationVariables
>

/**
 * __usePinOrUnpinExpenseMutation__
 *
 * To run a mutation, you first call `usePinOrUnpinExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinOrUnpinExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinOrUnpinExpenseMutation, { data, loading, error }] = usePinOrUnpinExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePinOrUnpinExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.PinOrUnpinExpenseMutation,
    Types.PinOrUnpinExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.PinOrUnpinExpenseMutation,
    Types.PinOrUnpinExpenseMutationVariables
  >(PinOrUnpinExpenseDocument, options)
}
export type PinOrUnpinExpenseMutationHookResult = ReturnType<
  typeof usePinOrUnpinExpenseMutation
>
export type PinOrUnpinExpenseMutationResult = Apollo.MutationResult<
  Types.PinOrUnpinExpenseMutation
>
export type PinOrUnpinExpenseMutationOptions = Apollo.BaseMutationOptions<
  Types.PinOrUnpinExpenseMutation,
  Types.PinOrUnpinExpenseMutationVariables
>
export const ConvertToParentExpenseDocument = gql`
  mutation convertToParentExpense(
    $id: ID!
    $input: ConvertToParentExpenseInput!
  ) {
    convertToParentExpense(id: $id, input: $input) {
      ...BaseExpenseDetails
    }
  }
  ${BaseExpenseDetailsFragmentDoc}
`
export type ConvertToParentExpenseMutationFn = Apollo.MutationFunction<
  Types.ConvertToParentExpenseMutation,
  Types.ConvertToParentExpenseMutationVariables
>

/**
 * __useConvertToParentExpenseMutation__
 *
 * To run a mutation, you first call `useConvertToParentExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertToParentExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertToParentExpenseMutation, { data, loading, error }] = useConvertToParentExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertToParentExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ConvertToParentExpenseMutation,
    Types.ConvertToParentExpenseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ConvertToParentExpenseMutation,
    Types.ConvertToParentExpenseMutationVariables
  >(ConvertToParentExpenseDocument, options)
}
export type ConvertToParentExpenseMutationHookResult = ReturnType<
  typeof useConvertToParentExpenseMutation
>
export type ConvertToParentExpenseMutationResult = Apollo.MutationResult<
  Types.ConvertToParentExpenseMutation
>
export type ConvertToParentExpenseMutationOptions = Apollo.BaseMutationOptions<
  Types.ConvertToParentExpenseMutation,
  Types.ConvertToParentExpenseMutationVariables
>
export const GetExpenseDocument = gql`
  query getExpense($id: ID!) {
    transaction(id: $id) {
      ...ExpenseDetails
    }
  }
  ${ExpenseDetailsFragmentDoc}
`

/**
 * __useGetExpenseQuery__
 *
 * To run a query within a React component, call `useGetExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExpenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetExpenseQuery,
    Types.GetExpenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetExpenseQuery, Types.GetExpenseQueryVariables>(
    GetExpenseDocument,
    options
  )
}
export function useGetExpenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetExpenseQuery,
    Types.GetExpenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetExpenseQuery,
    Types.GetExpenseQueryVariables
  >(GetExpenseDocument, options)
}
export type GetExpenseQueryHookResult = ReturnType<typeof useGetExpenseQuery>
export type GetExpenseLazyQueryHookResult = ReturnType<
  typeof useGetExpenseLazyQuery
>
export type GetExpenseQueryResult = Apollo.QueryResult<
  Types.GetExpenseQuery,
  Types.GetExpenseQueryVariables
>
export const GetExpensesTotalsDocument = gql`
  query getExpensesTotals($workspaceId: ID!) {
    transactions(workspaceId: $workspaceId) {
      totals {
        ...TransactionsTotals
      }
    }
  }
  ${TransactionsTotalsFragmentDoc}
`

/**
 * __useGetExpensesTotalsQuery__
 *
 * To run a query within a React component, call `useGetExpensesTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpensesTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpensesTotalsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetExpensesTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetExpensesTotalsQuery,
    Types.GetExpensesTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetExpensesTotalsQuery,
    Types.GetExpensesTotalsQueryVariables
  >(GetExpensesTotalsDocument, options)
}
export function useGetExpensesTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetExpensesTotalsQuery,
    Types.GetExpensesTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetExpensesTotalsQuery,
    Types.GetExpensesTotalsQueryVariables
  >(GetExpensesTotalsDocument, options)
}
export type GetExpensesTotalsQueryHookResult = ReturnType<
  typeof useGetExpensesTotalsQuery
>
export type GetExpensesTotalsLazyQueryHookResult = ReturnType<
  typeof useGetExpensesTotalsLazyQuery
>
export type GetExpensesTotalsQueryResult = Apollo.QueryResult<
  Types.GetExpensesTotalsQuery,
  Types.GetExpensesTotalsQueryVariables
>
export const GetExpensesDocument = gql`
  query getExpenses(
    $workspaceId: ID!
    $filters: TransactionFiltersInput
    $pagination: PaginationInput
  ) {
    transactions(
      workspaceId: $workspaceId
      filters: $filters
      pagination: $pagination
    ) {
      entries {
        ...BaseExpenseDetails
      }
      totals {
        ...TransactionsTotals
      }
      pagination {
        limit
        page
        totalPages
        total
      }
    }
  }
  ${BaseExpenseDetailsFragmentDoc}
  ${TransactionsTotalsFragmentDoc}
`

/**
 * __useGetExpensesQuery__
 *
 * To run a query within a React component, call `useGetExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpensesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetExpensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetExpensesQuery,
    Types.GetExpensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetExpensesQuery,
    Types.GetExpensesQueryVariables
  >(GetExpensesDocument, options)
}
export function useGetExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetExpensesQuery,
    Types.GetExpensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetExpensesQuery,
    Types.GetExpensesQueryVariables
  >(GetExpensesDocument, options)
}
export type GetExpensesQueryHookResult = ReturnType<typeof useGetExpensesQuery>
export type GetExpensesLazyQueryHookResult = ReturnType<
  typeof useGetExpensesLazyQuery
>
export type GetExpensesQueryResult = Apollo.QueryResult<
  Types.GetExpensesQuery,
  Types.GetExpensesQueryVariables
>
export const CreateProjectDocument = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
    }
  }
`
export type CreateProjectMutationFn = Apollo.MutationFunction<
  Types.CreateProjectMutation,
  Types.CreateProjectMutationVariables
>

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateProjectMutation,
    Types.CreateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateProjectMutation,
    Types.CreateProjectMutationVariables
  >(CreateProjectDocument, options)
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>
export type CreateProjectMutationResult = Apollo.MutationResult<
  Types.CreateProjectMutation
>
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateProjectMutation,
  Types.CreateProjectMutationVariables
>
export const UpdateProjectDocument = gql`
  mutation updateProject($id: ID!, $input: UpdateProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
    }
  }
`
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  Types.UpdateProjectMutation,
  Types.UpdateProjectMutationVariables
>

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateProjectMutation,
    Types.UpdateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateProjectMutation,
    Types.UpdateProjectMutationVariables
  >(UpdateProjectDocument, options)
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>
export type UpdateProjectMutationResult = Apollo.MutationResult<
  Types.UpdateProjectMutation
>
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateProjectMutation,
  Types.UpdateProjectMutationVariables
>
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  Types.DeleteProjectMutation,
  Types.DeleteProjectMutationVariables
>

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteProjectMutation,
    Types.DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteProjectMutation,
    Types.DeleteProjectMutationVariables
  >(DeleteProjectDocument, options)
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>
export type DeleteProjectMutationResult = Apollo.MutationResult<
  Types.DeleteProjectMutation
>
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteProjectMutation,
  Types.DeleteProjectMutationVariables
>
export const GetProjectsDocument = gql`
  query getProjects(
    $workspaceId: ID!
    $filters: ProjectFiltersInput
    $pagination: PaginationInput
  ) {
    projects(
      workspaceId: $workspaceId
      filters: $filters
      pagination: $pagination
    ) {
      entries {
        ...ProjectDetailsWithTotals
      }
      pagination {
        limit
        page
        totalPages
        total
      }
    }
  }
  ${ProjectDetailsWithTotalsFragmentDoc}
`

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetProjectsQuery,
    Types.GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetProjectsQuery,
    Types.GetProjectsQueryVariables
  >(GetProjectsDocument, options)
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetProjectsQuery,
    Types.GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetProjectsQuery,
    Types.GetProjectsQueryVariables
  >(GetProjectsDocument, options)
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>
export type GetProjectsQueryResult = Apollo.QueryResult<
  Types.GetProjectsQuery,
  Types.GetProjectsQueryVariables
>
export const GetProjectDetailsDocument = gql`
  query getProjectDetails($id: ID!, $filters: TransactionFiltersInput) {
    project(id: $id) {
      ...BaseProjectDetails
      transactions(filters: $filters) {
        totals {
          ...TransactionsTotals
        }
      }
    }
  }
  ${BaseProjectDetailsFragmentDoc}
  ${TransactionsTotalsFragmentDoc}
`

/**
 * __useGetProjectDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProjectDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetProjectDetailsQuery,
    Types.GetProjectDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetProjectDetailsQuery,
    Types.GetProjectDetailsQueryVariables
  >(GetProjectDetailsDocument, options)
}
export function useGetProjectDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetProjectDetailsQuery,
    Types.GetProjectDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetProjectDetailsQuery,
    Types.GetProjectDetailsQueryVariables
  >(GetProjectDetailsDocument, options)
}
export type GetProjectDetailsQueryHookResult = ReturnType<
  typeof useGetProjectDetailsQuery
>
export type GetProjectDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProjectDetailsLazyQuery
>
export type GetProjectDetailsQueryResult = Apollo.QueryResult<
  Types.GetProjectDetailsQuery,
  Types.GetProjectDetailsQueryVariables
>
export const GetProjectTransactionsDocument = gql`
  query getProjectTransactions(
    $id: ID!
    $filters: TransactionFiltersInput
    $pagination: PaginationInput
  ) {
    project(id: $id) {
      transactions(filters: $filters, pagination: $pagination) {
        entries {
          ...BaseExpenseDetails
        }
        totals {
          ...TransactionsTotals
        }
        pagination {
          limit
          page
          totalPages
          total
        }
      }
    }
  }
  ${BaseExpenseDetailsFragmentDoc}
  ${TransactionsTotalsFragmentDoc}
`

/**
 * __useGetProjectTransactionsQuery__
 *
 * To run a query within a React component, call `useGetProjectTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetProjectTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetProjectTransactionsQuery,
    Types.GetProjectTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetProjectTransactionsQuery,
    Types.GetProjectTransactionsQueryVariables
  >(GetProjectTransactionsDocument, options)
}
export function useGetProjectTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetProjectTransactionsQuery,
    Types.GetProjectTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetProjectTransactionsQuery,
    Types.GetProjectTransactionsQueryVariables
  >(GetProjectTransactionsDocument, options)
}
export type GetProjectTransactionsQueryHookResult = ReturnType<
  typeof useGetProjectTransactionsQuery
>
export type GetProjectTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectTransactionsLazyQuery
>
export type GetProjectTransactionsQueryResult = Apollo.QueryResult<
  Types.GetProjectTransactionsQuery,
  Types.GetProjectTransactionsQueryVariables
>
export const GetStatisticsDocument = gql`
  query getStatistics($workspaceId: ID!, $filters: StatisticsFilterInput) {
    statistics(workspaceId: $workspaceId, filters: $filters) {
      chart {
        data {
          year
          total
          months {
            month
            total
          }
        }
      }
      summary {
        expenses {
          total
        }
        categories {
          category {
            id
            name
            color
          }
          totals {
            total
            percentage
          }
        }
      }
    }
  }
`

/**
 * __useGetStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetStatisticsQuery,
    Types.GetStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetStatisticsQuery,
    Types.GetStatisticsQueryVariables
  >(GetStatisticsDocument, options)
}
export function useGetStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetStatisticsQuery,
    Types.GetStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetStatisticsQuery,
    Types.GetStatisticsQueryVariables
  >(GetStatisticsDocument, options)
}
export type GetStatisticsQueryHookResult = ReturnType<
  typeof useGetStatisticsQuery
>
export type GetStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetStatisticsLazyQuery
>
export type GetStatisticsQueryResult = Apollo.QueryResult<
  Types.GetStatisticsQuery,
  Types.GetStatisticsQueryVariables
>
export const CreateTagDocument = gql`
  mutation createTag($input: CreateTagInput!) {
    createTag(input: $input) {
      ...Tag
    }
  }
  ${TagFragmentDoc}
`
export type CreateTagMutationFn = Apollo.MutationFunction<
  Types.CreateTagMutation,
  Types.CreateTagMutationVariables
>

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTagMutation,
    Types.CreateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateTagMutation,
    Types.CreateTagMutationVariables
  >(CreateTagDocument, options)
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>
export type CreateTagMutationResult = Apollo.MutationResult<
  Types.CreateTagMutation
>
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTagMutation,
  Types.CreateTagMutationVariables
>
export const UpdateTagDocument = gql`
  mutation updateTag($id: ID!, $input: UpdateTagInput!) {
    updateTag(id: $id, input: $input) {
      ...Tag
    }
  }
  ${TagFragmentDoc}
`
export type UpdateTagMutationFn = Apollo.MutationFunction<
  Types.UpdateTagMutation,
  Types.UpdateTagMutationVariables
>

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTagMutation,
    Types.UpdateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateTagMutation,
    Types.UpdateTagMutationVariables
  >(UpdateTagDocument, options)
}
export type UpdateTagMutationHookResult = ReturnType<
  typeof useUpdateTagMutation
>
export type UpdateTagMutationResult = Apollo.MutationResult<
  Types.UpdateTagMutation
>
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTagMutation,
  Types.UpdateTagMutationVariables
>
export const DeleteTagDocument = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id)
  }
`
export type DeleteTagMutationFn = Apollo.MutationFunction<
  Types.DeleteTagMutation,
  Types.DeleteTagMutationVariables
>

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTagMutation,
    Types.DeleteTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteTagMutation,
    Types.DeleteTagMutationVariables
  >(DeleteTagDocument, options)
}
export type DeleteTagMutationHookResult = ReturnType<
  typeof useDeleteTagMutation
>
export type DeleteTagMutationResult = Apollo.MutationResult<
  Types.DeleteTagMutation
>
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTagMutation,
  Types.DeleteTagMutationVariables
>
export const GetTagsDocument = gql`
  query getTags($filters: TagFiltersInput) {
    tags(filters: $filters) {
      entries {
        ...Tag
      }
    }
  }
  ${TagFragmentDoc}
`

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetTagsQuery,
    Types.GetTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetTagsQuery, Types.GetTagsQueryVariables>(
    GetTagsDocument,
    options
  )
}
export function useGetTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetTagsQuery,
    Types.GetTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetTagsQuery, Types.GetTagsQueryVariables>(
    GetTagsDocument,
    options
  )
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>
export type GetTagsQueryResult = Apollo.QueryResult<
  Types.GetTagsQuery,
  Types.GetTagsQueryVariables
>
export const UploadFileDocument = gql`
  mutation uploadFile($input: FileInput!) {
    uploadFile(input: $input) {
      ...File
    }
  }
  ${FileFragmentDoc}
`
export type UploadFileMutationFn = Apollo.MutationFunction<
  Types.UploadFileMutation,
  Types.UploadFileMutationVariables
>

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadFileMutation,
    Types.UploadFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UploadFileMutation,
    Types.UploadFileMutationVariables
  >(UploadFileDocument, options)
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>
export type UploadFileMutationResult = Apollo.MutationResult<
  Types.UploadFileMutation
>
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadFileMutation,
  Types.UploadFileMutationVariables
>
export const DeleteFileDocument = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`
export type DeleteFileMutationFn = Apollo.MutationFunction<
  Types.DeleteFileMutation,
  Types.DeleteFileMutationVariables
>

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteFileMutation,
    Types.DeleteFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteFileMutation,
    Types.DeleteFileMutationVariables
  >(DeleteFileDocument, options)
}
export type DeleteFileMutationHookResult = ReturnType<
  typeof useDeleteFileMutation
>
export type DeleteFileMutationResult = Apollo.MutationResult<
  Types.DeleteFileMutation
>
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteFileMutation,
  Types.DeleteFileMutationVariables
>
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      user {
        id
      }
      jwt
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<
  Types.LoginMutation,
  Types.LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LoginMutation,
    Types.LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(
    LoginDocument,
    options
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  Types.LoginMutation,
  Types.LoginMutationVariables
>
export const RegisterDocument = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      user {
        id
      }
      jwt
    }
  }
`
export type RegisterMutationFn = Apollo.MutationFunction<
  Types.RegisterMutation,
  Types.RegisterMutationVariables
>

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RegisterMutation,
    Types.RegisterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.RegisterMutation,
    Types.RegisterMutationVariables
  >(RegisterDocument, options)
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>
export type RegisterMutationResult = Apollo.MutationResult<
  Types.RegisterMutation
>
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  Types.RegisterMutation,
  Types.RegisterMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >(UpdateUserDocument, options)
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<
  Types.UpdateUserMutation
>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>
export const GetUserDocument = gql`
  query getUser($filters: UserFiltersInput!) {
    user(filters: $filters) {
      id
      firstName
      email
      personalWorkspace {
        id
        name
      }
      workspaces {
        entries {
          id
          name
        }
      }
      photo {
        ...File
      }
    }
  }
  ${FileFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  Types.GetUserQuery,
  Types.GetUserQueryVariables
>
export const CreateWorkspaceDocument = gql`
  mutation createWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      id
    }
  }
`
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  Types.CreateWorkspaceMutation,
  Types.CreateWorkspaceMutationVariables
>

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateWorkspaceMutation,
    Types.CreateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateWorkspaceMutation,
    Types.CreateWorkspaceMutationVariables
  >(CreateWorkspaceDocument, options)
}
export type CreateWorkspaceMutationHookResult = ReturnType<
  typeof useCreateWorkspaceMutation
>
export type CreateWorkspaceMutationResult = Apollo.MutationResult<
  Types.CreateWorkspaceMutation
>
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateWorkspaceMutation,
  Types.CreateWorkspaceMutationVariables
>
export const UpdateWorkspaceDocument = gql`
  mutation updateWorkspace($id: ID!, $input: UpdateWorkspaceInput!) {
    updateWorkspace(id: $id, input: $input) {
      id
    }
  }
`
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<
  Types.UpdateWorkspaceMutation,
  Types.UpdateWorkspaceMutationVariables
>

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateWorkspaceMutation,
    Types.UpdateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateWorkspaceMutation,
    Types.UpdateWorkspaceMutationVariables
  >(UpdateWorkspaceDocument, options)
}
export type UpdateWorkspaceMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceMutation
>
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<
  Types.UpdateWorkspaceMutation
>
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateWorkspaceMutation,
  Types.UpdateWorkspaceMutationVariables
>
export const DeleteWorkspaceDocument = gql`
  mutation deleteWorkspace($id: ID!) {
    deleteWorkspace(id: $id)
  }
`
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<
  Types.DeleteWorkspaceMutation,
  Types.DeleteWorkspaceMutationVariables
>

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteWorkspaceMutation,
    Types.DeleteWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteWorkspaceMutation,
    Types.DeleteWorkspaceMutationVariables
  >(DeleteWorkspaceDocument, options)
}
export type DeleteWorkspaceMutationHookResult = ReturnType<
  typeof useDeleteWorkspaceMutation
>
export type DeleteWorkspaceMutationResult = Apollo.MutationResult<
  Types.DeleteWorkspaceMutation
>
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteWorkspaceMutation,
  Types.DeleteWorkspaceMutationVariables
>
export const InviteWorkspaceMemberDocument = gql`
  mutation inviteWorkspaceMember($input: InviteMemberInput!) {
    inviteWorkspaceMember(input: $input)
  }
`
export type InviteWorkspaceMemberMutationFn = Apollo.MutationFunction<
  Types.InviteWorkspaceMemberMutation,
  Types.InviteWorkspaceMemberMutationVariables
>

/**
 * __useInviteWorkspaceMemberMutation__
 *
 * To run a mutation, you first call `useInviteWorkspaceMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteWorkspaceMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteWorkspaceMemberMutation, { data, loading, error }] = useInviteWorkspaceMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteWorkspaceMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.InviteWorkspaceMemberMutation,
    Types.InviteWorkspaceMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.InviteWorkspaceMemberMutation,
    Types.InviteWorkspaceMemberMutationVariables
  >(InviteWorkspaceMemberDocument, options)
}
export type InviteWorkspaceMemberMutationHookResult = ReturnType<
  typeof useInviteWorkspaceMemberMutation
>
export type InviteWorkspaceMemberMutationResult = Apollo.MutationResult<
  Types.InviteWorkspaceMemberMutation
>
export type InviteWorkspaceMemberMutationOptions = Apollo.BaseMutationOptions<
  Types.InviteWorkspaceMemberMutation,
  Types.InviteWorkspaceMemberMutationVariables
>
export const DeleteWorkspaceMemberDocument = gql`
  mutation deleteWorkspaceMember($id: ID!) {
    deleteWorkspaceMember(id: $id)
  }
`
export type DeleteWorkspaceMemberMutationFn = Apollo.MutationFunction<
  Types.DeleteWorkspaceMemberMutation,
  Types.DeleteWorkspaceMemberMutationVariables
>

/**
 * __useDeleteWorkspaceMemberMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMemberMutation, { data, loading, error }] = useDeleteWorkspaceMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkspaceMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteWorkspaceMemberMutation,
    Types.DeleteWorkspaceMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteWorkspaceMemberMutation,
    Types.DeleteWorkspaceMemberMutationVariables
  >(DeleteWorkspaceMemberDocument, options)
}
export type DeleteWorkspaceMemberMutationHookResult = ReturnType<
  typeof useDeleteWorkspaceMemberMutation
>
export type DeleteWorkspaceMemberMutationResult = Apollo.MutationResult<
  Types.DeleteWorkspaceMemberMutation
>
export type DeleteWorkspaceMemberMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteWorkspaceMemberMutation,
  Types.DeleteWorkspaceMemberMutationVariables
>
export const DeleteWorkspaceInvitationDocument = gql`
  mutation deleteWorkspaceInvitation($id: ID!) {
    deleteWorkspaceInvitation(id: $id)
  }
`
export type DeleteWorkspaceInvitationMutationFn = Apollo.MutationFunction<
  Types.DeleteWorkspaceInvitationMutation,
  Types.DeleteWorkspaceInvitationMutationVariables
>

/**
 * __useDeleteWorkspaceInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceInvitationMutation, { data, loading, error }] = useDeleteWorkspaceInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkspaceInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteWorkspaceInvitationMutation,
    Types.DeleteWorkspaceInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteWorkspaceInvitationMutation,
    Types.DeleteWorkspaceInvitationMutationVariables
  >(DeleteWorkspaceInvitationDocument, options)
}
export type DeleteWorkspaceInvitationMutationHookResult = ReturnType<
  typeof useDeleteWorkspaceInvitationMutation
>
export type DeleteWorkspaceInvitationMutationResult = Apollo.MutationResult<
  Types.DeleteWorkspaceInvitationMutation
>
export type DeleteWorkspaceInvitationMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteWorkspaceInvitationMutation,
  Types.DeleteWorkspaceInvitationMutationVariables
>
export const AcceptWorkspaceInviteDocument = gql`
  mutation acceptWorkspaceInvite($token: String!) {
    acceptWorkspaceInvite(token: $token)
  }
`
export type AcceptWorkspaceInviteMutationFn = Apollo.MutationFunction<
  Types.AcceptWorkspaceInviteMutation,
  Types.AcceptWorkspaceInviteMutationVariables
>

/**
 * __useAcceptWorkspaceInviteMutation__
 *
 * To run a mutation, you first call `useAcceptWorkspaceInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptWorkspaceInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptWorkspaceInviteMutation, { data, loading, error }] = useAcceptWorkspaceInviteMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptWorkspaceInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AcceptWorkspaceInviteMutation,
    Types.AcceptWorkspaceInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.AcceptWorkspaceInviteMutation,
    Types.AcceptWorkspaceInviteMutationVariables
  >(AcceptWorkspaceInviteDocument, options)
}
export type AcceptWorkspaceInviteMutationHookResult = ReturnType<
  typeof useAcceptWorkspaceInviteMutation
>
export type AcceptWorkspaceInviteMutationResult = Apollo.MutationResult<
  Types.AcceptWorkspaceInviteMutation
>
export type AcceptWorkspaceInviteMutationOptions = Apollo.BaseMutationOptions<
  Types.AcceptWorkspaceInviteMutation,
  Types.AcceptWorkspaceInviteMutationVariables
>
export const GetWorkspaceDocument = gql`
  query getWorkspace($id: ID!) {
    workspace(id: $id) {
      ...WorkspaceDetails
    }
  }
  ${WorkspaceDetailsFragmentDoc}
`

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetWorkspaceQuery,
    Types.GetWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetWorkspaceQuery,
    Types.GetWorkspaceQueryVariables
  >(GetWorkspaceDocument, options)
}
export function useGetWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkspaceQuery,
    Types.GetWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetWorkspaceQuery,
    Types.GetWorkspaceQueryVariables
  >(GetWorkspaceDocument, options)
}
export type GetWorkspaceQueryHookResult = ReturnType<
  typeof useGetWorkspaceQuery
>
export type GetWorkspaceLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceLazyQuery
>
export type GetWorkspaceQueryResult = Apollo.QueryResult<
  Types.GetWorkspaceQuery,
  Types.GetWorkspaceQueryVariables
>
export const GetWorkspacesDocument = gql`
  query getWorkspaces {
    workspaces {
      entries {
        ...WorkspaceDetails
      }
    }
  }
  ${WorkspaceDetailsFragmentDoc}
`

/**
 * __useGetWorkspacesQuery__
 *
 * To run a query within a React component, call `useGetWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetWorkspacesQuery,
    Types.GetWorkspacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.GetWorkspacesQuery,
    Types.GetWorkspacesQueryVariables
  >(GetWorkspacesDocument, options)
}
export function useGetWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetWorkspacesQuery,
    Types.GetWorkspacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.GetWorkspacesQuery,
    Types.GetWorkspacesQueryVariables
  >(GetWorkspacesDocument, options)
}
export type GetWorkspacesQueryHookResult = ReturnType<
  typeof useGetWorkspacesQuery
>
export type GetWorkspacesLazyQueryHookResult = ReturnType<
  typeof useGetWorkspacesLazyQuery
>
export type GetWorkspacesQueryResult = Apollo.QueryResult<
  Types.GetWorkspacesQuery,
  Types.GetWorkspacesQueryVariables
>
