import React, {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react'

// Utils
import { useBreakpoint } from 'styles'

// Styles
import {
  Backdrop as BackdropStyle,
  ModalContainer,
  ScrollableContainer,
  CrossIcon,
  Title,
  Body,
  Footer,
} from './Modal.styles'

interface BackdropProps {
  mounted: boolean
  onClick: () => void
}

const Backdrop: FC<BackdropProps> = ({ mounted, onClick, children }) => {
  const backdropRef = useRef<HTMLDivElement>(null)
  const hasMouseDown = useRef(false)

  /**
   * Unintended modal closing is happening because of event
   * bubbling to Backdrop, so we need to detect when a complete
   * click (mouse down and up) is happening within Backdrop.
   * The modal will only be closed when a previous mouseDown
   * event is triggered before click.
   *
   * Learn more: https://github.com/sketch-hq/cloud-frontend/pull/2183
   */
  const handleMouseDown = useCallback((event: any) => {
    if (event.target !== backdropRef.current) {
      return
    }

    hasMouseDown.current = true
  }, [])

  const handleClick = useCallback(
    (event: any) => {
      if (
        !onClick ||
        event.target !== backdropRef.current ||
        !hasMouseDown.current
      ) {
        return
      }

      onClick()
    },
    [onClick]
  )
  return (
    <BackdropStyle
      ref={backdropRef}
      mounted={mounted}
      {...(onClick && { onClick: handleClick })}
      onMouseDown={handleMouseDown}>
      {children}
    </BackdropStyle>
  )
}

export interface ModalInjectProps {
  title?: string | ReactNode
  onCloseClick?: () => void
}

const Modal: FC<ModalInjectProps & { className?: string }> = ({
  title,
  children,
  onCloseClick,
  className,
}) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onCloseClick?.()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)

    if (!mounted) {
      setMounted(true)
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
      setMounted(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMobile = !useBreakpoint('sm')

  return (
    <Backdrop mounted={mounted} onClick={() => onCloseClick?.()}>
      <ModalContainer mounted={mounted} className={className}>
        {!isMobile && (
          <span onClick={onCloseClick}>
            <CrossIcon />
          </span>
        )}

        {title && <Title>{title}</Title>}
        <ScrollableContainer>{children}</ScrollableContainer>
      </ModalContainer>
    </Backdrop>
  )
}

interface ModalBodyProps {
  padded?: boolean
}

const ModalBody: FC<ModalBodyProps> = ({ padded, children }) => {
  return <Body padded={padded}>{children}</Body>
}

const ModalFooter: FC = ({ children }) => {
  return <Footer>{children}</Footer>
}

export default Object.assign(Modal, {
  Body: ModalBody,
  Footer: ModalFooter,
})
