import styled, { css } from 'styled-components'

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
`

export const MenuButton = styled.a<{ $$active: boolean }>`
  display: flex;
  flex-direction: column;
  height: 9rem;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mineShaft};
  border-left: 6px solid transparent;
  padding: 0 3rem;

  svg {
    margin-right: 1.2rem;
  }

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.keppel};
    background: ${({ theme }) => `${theme.colors.keppel}1a`};
    border-left: 6px solid ${({ theme }) => theme.colors.surfieGreen};
  }

  ${({ $$active }) =>
    $$active &&
    css`
      color: ${({ theme }) => theme.colors.keppel};
      background: ${({ theme }) => `${theme.colors.keppel}1a`};
      border-left: 6px solid ${({ theme }) => theme.colors.surfieGreen};
    `}
`
