import React, { useState } from 'react'
import { Formik, Form } from 'formik'

import { useModal } from 'components/Modal/ModalContext'
import { getLocalStorageItem } from 'utils/localStorage'
import { MemberRole, useInviteWorkspaceMemberMutation } from 'gqlTypes'
import { Grid } from '@mui/material'
import { ButtonField } from 'components/Form'
import Modal from 'components/Modal/Modal'
import { InviteMemberInput } from './InviteMemberInput'
import { WorkspaceMemberItem } from 'modules/Workspace/components/WorkspaceMemberItem'

interface FormValues {
  email: string
  role: MemberRole
}

const initialValues: FormValues = {
  email: '',
  role: MemberRole.Member,
}

interface Invite {
  id: string
  email: string
  role: string
}

export const InviteMember = () => {
  const { hideModal } = useModal()
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')
  const [invites, setInvites] = useState<Invite[]>([])

  const [invite] = useInviteWorkspaceMemberMutation({
    onCompleted: () => {
      hideModal()
    },
  })

  const handleAdd = (values: any) => {
    setInvites((currentInvites) => [...currentInvites, values])
  }

  const handleRemove = (id: string) => {
    const newInvites = invites.reduce(
      (acc, curr) => (curr.id === id ? acc : [...acc, curr]) as any,
      []
    )
    setInvites(newInvites)
  }

  const handleSubmit = () => {
    invite({
      variables: {
        input: {
          invites: invites.map((invite) => ({
            email: invite.email,
            role: invite.role,
          })) as any,
          workspaceID: activeWorkspaceId,
        },
      },
    })
  }

  return (
    <Modal title="Invite People" onCloseClick={hideModal}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form style={{ width: '600px' }}>
          <Modal.Body padded>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InviteMemberInput onAdd={handleAdd} />
              </Grid>
              <Grid item xs={12}>
                {invites.map((invite) => (
                  <WorkspaceMemberItem
                    key={`invite-${invite.id}`}
                    id={invite.id}
                    email={invite.email}
                    removable
                    onRemoveClick={handleRemove}
                  />
                ))}
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Grid container spacing={2}>
              <Grid item>
                <ButtonField
                  type="submit"
                  fullWidth
                  disabled={invites.length === 0}>
                  Send invite
                </ButtonField>
              </Grid>
            </Grid>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}
