import React from 'react'
import {
  Amount,
  Card,
  CategoryColor,
  CategoryName,
  InnerContainer,
  InnerContainerRow,
  LimitAmount,
  RemainingAmount,
} from './Allocation.styles'

interface AllocationProps {
  color: string
  name: string
  allocatedAmount?: number
  spentAmount: number
  remainingAmount?: number
}

export const Allocation = ({
  color,
  name,
  allocatedAmount,
  spentAmount,
  remainingAmount,
}: AllocationProps) => {
  return (
    <Card>
      <CategoryColor center={!allocatedAmount} color={color}>
        &nbsp;
      </CategoryColor>

      <InnerContainer>
        <InnerContainerRow>
          <CategoryName>{name}</CategoryName>
          <Amount>{spentAmount} €</Amount>
        </InnerContainerRow>

        {allocatedAmount && remainingAmount ? (
          <InnerContainerRow>
            <RemainingAmount>Remaining: {remainingAmount} €</RemainingAmount>
            <LimitAmount>Limit: {allocatedAmount} €</LimitAmount>
          </InnerContainerRow>
        ) : null}
      </InnerContainer>
    </Card>
  )
}
