import React from 'react'

import { ButtonProps as BaseButtonProps } from 'components/Button/Button'
import { SplitButtonContainer, ButtonContainer } from './SplitButton.styles'

type BaseButtonOnlyProps = Pick<
  BaseButtonProps,
  'active' | 'disabled' | 'onClick'
>

interface ButtonProps extends BaseButtonOnlyProps {
  type?: 'button' | 'submit'
  text: string
  value: string
}

export interface SplitButtonProps {
  leftButton: ButtonProps
  rightButton: ButtonProps
  value?: string
  onChange?: (value: string) => void
}

export const SplitButton = ({
  leftButton,
  rightButton,
  value,
  onChange,
}: SplitButtonProps) => {
  const { type: leftButtonType, ...leftButtonProps } = leftButton
  const { type: rightButtonType, ...rightButtonProps } = rightButton

  return (
    <SplitButtonContainer>
      <ButtonContainer
        type={leftButtonType || 'button'}
        $$active={leftButtonProps.value === value}
        {...leftButtonProps}
        onClick={() => onChange?.(leftButtonProps.value)}>
        {leftButtonProps.text}
      </ButtonContainer>
      <ButtonContainer
        type={rightButtonType || 'button'}
        {...rightButtonProps}
        $$active={rightButtonProps.value === value}
        onClick={() => onChange?.(rightButtonProps.value)}>
        {rightButtonProps.text}
      </ButtonContainer>
    </SplitButtonContainer>
  )
}
