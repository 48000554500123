import React from 'react'

import Layout from 'components/Layout'
import { Expenses } from './Expenses'
import { useBreakpoint } from 'styles'
import { FloatingButton } from 'components/FloatingButton'
import Button from 'components/Button/Button'
import { ExpenseFormModal } from '../../components/ExpenseFormModal'
import { CreateExpense } from 'modules/Expenses/components/ExpenseForm'
import { useModal } from 'components/Modal/ModalContext'

export const ExpensesView = () => {
  const { showModal, hideModal } = useModal()

  const isMobile = !useBreakpoint('md')

  const handleAddExpense = () => {
    showModal(ExpenseFormModal, {
      title: `New Transaction`,
      children: <CreateExpense />,
      onCloseClick: hideModal,
    })
  }

  return (
    <Layout
      header={
        <>
          <div id="layout-header-portal"></div>

          {isMobile ? (
            <FloatingButton onClick={handleAddExpense} />
          ) : (
            <Button marginLeft="auto" onClick={handleAddExpense}>
              Add transaction
            </Button>
          )}
        </>
      }>
      <Expenses />
    </Layout>
  )
}
