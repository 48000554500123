import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// Constants
import { routes } from 'constants/routes'

// Local Components
import Menu from './Menu'

// Styles
import {
  Container,
  ToggleContainer,
  Avatar,
  Arrow,
  OptionSection as OptionSectionStyle,
  OptionItem as OptionItemStyle,
} from './WorkspacesDropdown.styles'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage'
import { WorkspaceDetailsFragment, useGetWorkspacesQuery } from 'gqlTypes'

interface OptionProps {
  $$active?: boolean
  onClick: () => void
}

const OptionSection = ({ children }: React.PropsWithChildren<{}>) => (
  <OptionSectionStyle>{children}</OptionSectionStyle>
)

const OptionItem = ({
  children,
  $$active,
  onClick,
}: React.PropsWithChildren<OptionProps>) => (
  <OptionItemStyle onClick={onClick} $$active={$$active}>
    {children}
  </OptionItemStyle>
)

const OptionSeparator = () => <hr key={`separator-${new Date().getTime()}`} />

const WorkspacesDropdown: FC = () => {
  // Options
  const { push } = useHistory()
  const locationParams = useParams<{ workspaceId: string }>()

  const activeWorkspace = getLocalStorageItem('activeWorkspace')
  const { data } = useGetWorkspacesQuery()

  const allUserWorkspaces = data?.workspaces.entries ?? []

  // Select workspace
  const [
    selectedWorkspace,
    setSelectedWorkspace,
  ] = useState<null | WorkspaceDetailsFragment>()

  useEffect(() => {
    if (!selectedWorkspace) {
      setSelectedWorkspace(
        allUserWorkspaces.find(({ id }) => id === activeWorkspace)
      )
    }
  }, [activeWorkspace, allUserWorkspaces, selectedWorkspace])

  const handleWorkspaceClick = (workspace: WorkspaceDetailsFragment) => {
    setLocalStorageItem('activeWorkspace', workspace.id)
    setSelectedWorkspace(workspace)

    push(routes.OVERVIEW.create({ workspaceId: workspace.id }))
  }

  // Map workspaces to workspaces dropdown
  const mappedWorkspaces = allUserWorkspaces.reduce((acc: any, workspace) => {
    return [
      ...acc,
      <OptionItem
        onClick={() => handleWorkspaceClick(workspace)}
        $$active={selectedWorkspace?.id === workspace!.id}>
        {workspace.name}
      </OptionItem>,
    ]
  }, [])

  return (
    <Container>
      <Menu
        toggle={
          <ToggleContainer>
            <Avatar titleAccess={selectedWorkspace?.name} />
            <Arrow />
          </ToggleContainer>
        }
        options={[
          <OptionSection>Member of</OptionSection>,
          ...mappedWorkspaces,
          <OptionSeparator />,
          <OptionItem
            onClick={() =>
              push(
                routes.WORKSPACE_SETTINGS.create({
                  workspaceId: locationParams.workspaceId,
                })
              )
            }>
            Workspace Settings
          </OptionItem>,
          <OptionItem onClick={() => push(routes.WORKSPACE_CREATE.create({}))}>
            Create Workspace
          </OptionItem>,
        ]}
      />
    </Container>
  )
}

export default WorkspacesDropdown
