import React, { FC } from 'react'
import moment from 'moment'

// GQL
import { BaseExpenseDetailsFragment } from 'gqlTypes'

// Components
import Tag from 'components/Tag/Tag'
import ActionsMenu from 'components/ActionsMenu/ActionsMenu'

// Utils
import { useBreakpoint } from 'styles'

// Styles
import {
  Container,
  ActionsMenuColumn,
  Column,
  Row,
  AmountColumnMobile,
} from './ExpensesList.styles'

// Types
interface ListProps {
  items?: BaseExpenseDetailsFragment[]

  maxHeight?: string
  clickable?: boolean
  canPinExpenses?: boolean

  onClick?: (item: BaseExpenseDetailsFragment) => void
  onEditClick: (item: BaseExpenseDetailsFragment) => void
  onDeleteClick: (id: string) => void
  onPinClick?: (item: BaseExpenseDetailsFragment) => void
  onConvertExpenseClick?: (item: BaseExpenseDetailsFragment) => void
  onFetchMore?: (nextPage: number) => void
  placeholders?: JSX.Element[]
}

const List: FC<ListProps> = ({
  items,

  maxHeight,
  clickable = true,
  canPinExpenses = true,

  onDeleteClick,
  onClick,
  onEditClick,
  onPinClick,
  onConvertExpenseClick,

  placeholders,
}) => {
  const isMobile = !useBreakpoint('md')

  const handleRowClick = (item: BaseExpenseDetailsFragment) => {
    clickable && onClick && onClick(item)
  }

  const getActionMenuOptions = (item: BaseExpenseDetailsFragment) => {
    const options = [
      {
        name: 'Edit',
        onClick: () => onEditClick(item),
      },
      {
        name: 'Delete',
        onClick: () => onDeleteClick(item.id!),
      },
    ]

    if (canPinExpenses) {
      options.unshift({
        name: item.pinned ? 'Unpin' : 'Pin',
        onClick: () => onPinClick?.(item),
      })
    }

    if (onConvertExpenseClick) {
      options.push({
        name: 'Convert into group',
        onClick: () => onConvertExpenseClick(item),
      })
    }

    return options
  }

  const renderItemsForDesktop = (item: BaseExpenseDetailsFragment) => (
    <>
      <Column onClick={() => handleRowClick(item)} width={0.4}>
        {item.name}
      </Column>
      <Column onClick={() => handleRowClick(item)} width={0.2}>
        {moment(item.date).format('DD-MM-YYYY')}
      </Column>
      <Column onClick={() => handleRowClick(item)} width={0.18}>
        {item.category?.name && item.category?.color ? (
          <Tag tag={item.category?.name} color={item.category?.color} />
        ) : null}
      </Column>
      <Column onClick={() => handleRowClick(item)} width={0.14}>
        {item.tags?.map((tag) => (
          <Tag key={tag.id} variant="tag" tag={tag.name!} />
        ))}
      </Column>
      <Column onClick={() => handleRowClick(item)} width={0.14}>
        {item.amount?.toFixed(2)} €
      </Column>
      <ActionsMenuColumn width={50}>
        <ActionsMenu options={getActionMenuOptions(item)} />
      </ActionsMenuColumn>
    </>
  )

  const renderItemsForMobile = (item: BaseExpenseDetailsFragment) => (
    <>
      <Column $$flexDirection="column" width={1}>
        <Column onClick={() => handleRowClick(item)} width={0.92}>
          {item.name}
        </Column>

        <Column width={1}>
          <Column width={0.75} $$flexDirection="column">
            <Column onClick={() => handleRowClick(item)} width={1}>
              {moment(item.date).format('DD-MM-YYYY')}
            </Column>
            {item.category?.name && item.category?.color ? (
              <Column onClick={() => handleRowClick(item)} width={1}>
                <Tag tag={item.category?.name} color={item.category?.color} />
              </Column>
            ) : null}
            {item.tags?.length ? (
              <Column onClick={() => handleRowClick(item)} width={1}>
                {item.tags?.map((tag) => (
                  <Tag key={tag.id} variant="tag" tag={tag.name!} />
                ))}
              </Column>
            ) : null}
          </Column>

          <AmountColumnMobile>
            <Column onClick={() => handleRowClick(item)} width={1}>
              {item.amount?.toFixed(2)} €
            </Column>
            <ActionsMenuColumn>
              <ActionsMenu options={getActionMenuOptions(item)} />
            </ActionsMenuColumn>
          </AmountColumnMobile>
        </Column>
      </Column>
    </>
  )

  return (
    <Container maxHeight={maxHeight}>
      {items?.map((item) => (
        <Row key={item.id} clickable={clickable} expenseGroup={false}>
          {isMobile ? renderItemsForMobile(item) : renderItemsForDesktop(item)}
        </Row>
      ))}

      {items?.length ? placeholders : null}
    </Container>
  )
}

export default List
