import React from 'react'
import { Grid } from '@mui/material'

import { ButtonField, CategoryField, PriceField } from 'components/Form'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'
import { CreateBudgetAllocationMutationVariables } from 'gqlTypes'
import { useFormikContext } from 'formik'

export interface FormValues
  extends Omit<CreateBudgetAllocationMutationVariables['input'], 'budgetId'> {}

interface BudgetAllocationFormContentProps {
  loading: boolean
  submitButtonText: string
  onCancel?: () => void
}

export const BudgetAllocationFormContent = ({
  loading,
  submitButtonText,
  onCancel,
}: BudgetAllocationFormContentProps) => {
  const { setFieldValue } = useFormikContext<FormValues>()

  if (loading) {
    return (
      <Modal.Body padded>
        <Spinner fullPage={false} />
      </Modal.Body>
    )
  }

  return (
    <>
      <Modal.Body padded>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CategoryField
              name="categoryId"
              label="Category"
              onCreateNew={(category) =>
                setFieldValue('category', category?.id!)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PriceField
              name="allocatedAmount"
              label="Amount to allocate"
              required
            />
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="secondary" fullWidth onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ButtonField type="submit" fullWidth disabled={loading}>
              {submitButtonText}
            </ButtonField>
          </Grid>
        </Grid>
      </Modal.Footer>
    </>
  )
}
