import React, { FC } from 'react'
import { Box } from '@mui/material'

import { useModal } from 'components/Modal/ModalContext'
import Button from 'components/Button/Button'

import TagList from '../components/TagList/TagList'
import TagModal from '../components/TagModal/TagModal'

import {
  useDeleteTagMutation,
  GetTagsDocument,
  useGetTagsQuery,
  TagFragment,
} from 'gqlTypes'
import { getLocalStorageItem } from 'utils/localStorage'

export const WorkspaceSettingsTagsView: FC = () => {
  const { hideModal, showModal } = useModal()
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  // Queries
  const { data } = useGetTagsQuery({
    variables: {
      filters: {
        workspaceId: activeWorkspaceId,
      },
    },
  })

  // Mutations
  const [deleteTag] = useDeleteTagMutation({
    refetchQueries: [
      {
        query: GetTagsDocument,
        variables: {
          filters: {
            workspace: {
              id: { eq: activeWorkspaceId },
            },
          },
        },
      },
    ],
  })

  const handleAddTag = () => {
    showModal(TagModal, {
      onCloseClick: hideModal,
    })
  }

  const handleEditTag = (item: TagFragment) => {
    showModal(TagModal, {
      edit: true,
      id: item.id!,
      name: item?.name!,
      onCloseClick: hideModal,
    })
  }

  const handleDeleteTag = (id: string) => {
    deleteTag({
      variables: {
        id,
      },
    })
  }

  return (
    <>
      <Box ml="auto">
        <Button onClick={handleAddTag}>Add tag</Button>
      </Box>
      <TagList
        items={data?.tags?.entries}
        onEditClick={handleEditTag}
        onDeleteClick={handleDeleteTag}
      />
    </>
  )
}
