import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

// Components
import Spinner from 'components/Spinner/Spinner'
import Layout from 'components/Layout'
import { BaseFiltersType } from 'components/Filters'

// GQL
import {
  useGetProjectsQuery,
  useGetExpensesTotalsQuery,
  useGetWorkspacesQuery,
} from 'gqlTypes'

// Context
import { useModal } from 'components/Modal/ModalContext'

// Constants
import { routes } from 'constants/routes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

// Local Components
import { ExpenseFormModal } from '../../../Expenses/components/ExpenseFormModal'

// Styles
import {
  AddProjectCard,
  AddProjectText,
  FutureItem,
  OverviewGridContainer,
  WorkspaceTitle,
  layoutContainerStyles,
} from './Overview.styles'
import { CreateProjectForm } from 'modules/Projects/components/ProjectForm'
import { OverviewGridItem } from 'modules/Overview/components/OverviewGridItem'

export interface FiltersType extends BaseFiltersType {
  group?: boolean
  pinned?: boolean
  category?: string
}

const Overview = () => {
  const { push } = useHistory()
  const params = useParams<{ workspaceId: string }>()

  const { showModal, hideModal } = useModal()

  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')
  const { data } = useGetWorkspacesQuery()
  const currentWorkspace = data?.workspaces.entries.find(
    ({ id }) => activeWorkspaceId === id
  )

  // Expenses
  const expensesResult = useGetExpensesTotalsQuery({
    variables: { workspaceId: activeWorkspaceId },
  })

  // Projects
  const projectsResult = useGetProjectsQuery({
    variables: {
      workspaceId: activeWorkspaceId,
    },
    notifyOnNetworkStatusChange: true,
  })

  // TODO: Review we will really support pin or unpin expenses
  // const [pinOrUnpinExpense] = usePinOrUnpinExpenseMutation({
  //   refetchQueries: [
  //     {
  //       query: GetExpensesDocument,
  //       // variables: getVariables(),
  //     },
  //   ],
  // })
  // const handlePinUnpinExpense = (item: BaseExpenseDetailsFragment) => {
  //   pinOrUnpinExpense({
  //     variables: {
  //       id: item.id!,
  //       input: {
  //         pinned: !item?.pinned,
  //       },
  //     },
  //   })
  // }

  // Handlers
  const handleCloseForm = () => {
    push(routes.OVERVIEW.create({ workspaceId: params.workspaceId }))
    hideModal()
  }

  const handleAddProject = () => {
    showModal(ExpenseFormModal, {
      title: 'New Project',
      onCloseClick: handleCloseForm,
      children: <CreateProjectForm />,
    })
  }

  // TODO: Review commented code
  // Category options
  // const { data: categoriesData } = useGetCategoriesQuery({
  //   variables: {
  //     workspaceId: activeWorkspaceId,
  //   },
  // })

  // const getCategoryOptions = (filters: FiltersState) =>
  //   categoriesData?.categories?.entries?.map((category) => {
  //     return {
  //       value: category.id!,
  //       text: category.name!,
  //       active: filters.filters.category === category.id,
  //       onClick: (value: string) => {
  //         filters.setFilters({ ...filters.filters, category: value as string })
  //         filters.updateSearchValue('category' as any, value)
  //       },
  //     }
  //   }) || []

  // const getFilterComponents = (filters: FiltersState) => [
  //   {
  //     type: 'button' as const,
  //     displayName: 'Expense Groups',
  //     name: 'group',
  //     icon: <GroupOutlined />,
  //     value: filters.filters.group,
  //   },
  //   {
  //     type: 'button' as const,
  //     displayName: 'Pinned',
  //     name: 'pinned',
  //     icon: <PushPinOutlined />,
  //     value: filters.filters.pinned,
  //   },
  //   {
  //     type: 'choice' as const,
  //     displayName: 'Categories',
  //     name: 'categories',
  //     options: getCategoryOptions,
  //     value: filters.filters.category,
  //   },
  // ]

  // const handleLocalFilterChange = <T extends keyof FiltersType>(
  //   name: T,
  //   value: NonNullable<FiltersType[T]>,
  //   { filters, setFilters, updateSearchValue }: FiltersState
  // ) => {
  //   switch (name) {
  //     case 'group':
  //     case 'pinned': {
  //       setFilters({ ...filters, [name]: value as boolean })
  //       updateSearchValue(name as any, value)
  //       break
  //     }

  //     default: {
  //       setFilters({ ...filters, [name]: value })
  //     }
  //   }
  // }

  // const handleSearch = async (filters: FiltersType) => {
  //   const filtersValidated: TransactionFiltersInput = {
  //     name: filters.name || null,
  //     categoryId: filters.category,
  //     pinned: filters.pinned,
  //   }

  //   if (filters.date?.length === 2 && filters.date[1] !== null) {
  //     filtersValidated.date = {
  //       startDate: filters.date[0] as string,
  //       endDate: filters.date[1] as string,
  //     }
  //   }

  //   // refetch(getVariables(filtersValidated))
  // }

  return (
    <Layout
      header={
        <>
          {currentWorkspace ? (
            <WorkspaceTitle>{currentWorkspace?.name} workspace</WorkspaceTitle>
          ) : null}
        </>
      }
      containerStyleOverrides={layoutContainerStyles}>
      {expensesResult.loading || projectsResult.loading ? <Spinner /> : null}

      <FutureItem marginBottom>
        <h1>Upcoming transactions</h1>
        <p>
          Here we will show all transactions that will be executed within a
          week.
        </p>
      </FutureItem>

      <OverviewGridContainer>
        <OverviewGridItem
          id="general-expenses"
          name="General Expenses"
          income={expensesResult.data?.transactions.totals.income || 0}
          expense={expensesResult.data?.transactions.totals.expense || 0}
          onClick={() =>
            push(
              routes.EXPENSES.create({
                workspaceId: getLocalStorageItem('activeWorkspace'),
              })
            )
          }
        />

        {projectsResult.data?.projects.entries.map((project) => (
          <OverviewGridItem
            key={`overview-project-${project.id}`}
            id={project.id}
            name={project.name}
            date={project.date}
            income={project.transactions.totals.income}
            expense={project.transactions.totals.expense}
            onClick={(id) => {
              push(
                routes.PROJECT_DETAILS.create({
                  workspaceId: getLocalStorageItem('activeWorkspace'),
                  id,
                })
              )
            }}
          />
        ))}

        <AddProjectCard onClick={handleAddProject}>
          <AddProjectText>+ Project</AddProjectText>
        </AddProjectCard>
      </OverviewGridContainer>

      <FutureItem marginTop>
        <h1>Chart</h1>
        <p>Yearly chart showing income/ expenses from all projects.</p>
      </FutureItem>
    </Layout>
  )
}

export default Overview
