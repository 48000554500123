import styled, { css } from 'styled-components'
import { layout } from 'styled-system'

export const Container = styled.div<{ editable?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ editable }) =>
    editable &&
    css`
      padding-bottom: 22px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
    `}

  ${layout}
`

export const Label = styled.label<{ variant?: 'default' | 'radio' }>`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};

  ${({ variant }) =>
    variant === 'radio' &&
    css`
      display: flex;
      align-items: center;
    `};

  & input {
    margin-right: 8px;
  }
`

export const LabelContainer = styled.div<{ isEditing?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ isEditing }) =>
    isEditing &&
    css`
      margin-bottom: 6px;
    `}
`

export const LabelButton = styled.button.attrs({ type: 'button' })`
  outline: 0;
  border: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mineShaft};
  cursor: pointer;
`

export const Value = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray};
`
