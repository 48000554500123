import React from 'react'
import { Formik, Form } from 'formik'

import { useModal } from 'components/Modal/ModalContext'
import { useUpdateExpense } from 'operations'
import { TransactionType, useGetExpenseQuery } from 'gqlTypes'
import ExpenseForm, { FormValues } from './ExpenseForm'

interface UpdateExpenseProps {
  id: string
}

export const UpdateExpense = ({ id }: UpdateExpenseProps) => {
  const { hideModal } = useModal()

  // Operations
  const { data: expenseData, loading } = useGetExpenseQuery({
    variables: {
      id,
    },
  })
  const expense = expenseData?.transaction

  // Expenses operations
  const [updateExpense] = useUpdateExpense({
    id,
    onCompleted: hideModal,
  })

  // Handles
  const handleSubmit = ({ amount, files, type, ...values }: FormValues) => {
    const filesMapped = files?.map((file) => file.id)
    const commonFormData = {
      ...values,
      amount: parseFloat(amount || '0'),
      files: filesMapped,
      type: type as TransactionType,
    }

    updateExpense({
      id,
      input: commonFormData,
    })
  }

  const getInitialFormValues = (): FormValues => {
    const tags = expense?.tags?.map(({ id }) => id)

    // Let's support only one file for now
    const files = expense?.files?.map((file) => ({
      id: file.id,
      name: file.name,
      url: file.url,
      ext: file.mimetype,
    }))

    return {
      type: expense?.type!,
      name: expense?.name!,
      categoryId: expense?.category?.id || undefined,
      tags,
      amount: expense?.amount.toFixed(2),
      date: expense?.date || new Date(),
      notes: expense?.notes || '',
      files: files || [],
    }
  }

  if (loading) return null

  return (
    <Formik initialValues={getInitialFormValues()} onSubmit={handleSubmit}>
      <Form>
        <ExpenseForm
          loading={loading}
          onCancel={hideModal}
          onSubmit={handleSubmit}
        />
      </Form>
    </Formik>
  )
}
