import React from 'react'
import {
  Card,
  Date,
  IncomeExpenseWrapper,
  IncomeExpenseContainer,
  IncomeTitle,
  ExpenseTitle,
  Name,
  Separator,
  TextContainer,
} from './OverviewGridItem.styles'
import moment from 'moment'
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg'
import { ReactComponent as MinusIcon } from 'icons/minus-icon.svg'

interface OverviewGridItem {
  id: string
  name: string
  date?: string
  income: number
  expense: number
  onClick: (id: string) => void
}

export const OverviewGridItem = ({
  id,
  name,
  date,
  income,
  expense,
  onClick,
}: React.PropsWithChildren<OverviewGridItem>) => {
  const formatAmount = (amount: number) =>
    amount > 0 ? amount.toFixed(2) : amount

  return (
    <Card onClick={() => onClick(id)}>
      <Name>{name}</Name>
      {date ? <Date>Started {moment(date).format('MMMM d, YYYY')}</Date> : null}
      <IncomeExpenseWrapper>
        <IncomeExpenseContainer>
          <ExpenseTitle>Expenses</ExpenseTitle>
          <TextContainer>
            <MinusIcon />
            {formatAmount(expense)} €
          </TextContainer>
        </IncomeExpenseContainer>
        <Separator />
        <IncomeExpenseContainer>
          <IncomeTitle>Income</IncomeTitle>
          <TextContainer>
            <PlusIcon />
            {formatAmount(income)} €
          </TextContainer>
        </IncomeExpenseContainer>
      </IncomeExpenseWrapper>
    </Card>
  )
}
