import styled from 'styled-components'
import { Card as RawCard } from 'components/Card'

export const Card = styled(RawCard)`
  display: flex;
  min-width: 31.7rem;
  height: 6.2rem;
  align-items: center;
  cursor: pointer;
  padding: 1.4rem 1.6rem;
  margin-bottom: 2.4rem;

  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const InnerContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CategoryColor = styled.div<{ color: string; center: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${({ color }) => color};
  display: flex;
  flex-shrink: 0;
  margin-right: 0.8rem;
  align-self: ${({ center }) => (center ? 'center' : 'flex-start')};
`

export const CategoryName = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mineShaft};
`

export const Amount = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #2c2c2c;
`

export const RemainingAmount = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.bombay};
`

export const LimitAmount = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: hsl(0, 100%, 61%);
`
