const SYSTEM_FONT_FAMILY = 'ProximaNova, sans-serif'

export const typography = {
  fontFamily: SYSTEM_FONT_FAMILY,
  heading: {
    fontSize: '16px',
  },
  text: {
    fontSize: '13px',
    lineHeight: 1.6,
  },
}
