import React, { FC } from 'react'

import BaseField, { BaseFieldProps } from 'components/Form/BaseField/BaseField'
import { Label } from 'components/Form/BaseField/BaseField.styles'
import { validateRequired } from 'components/Form'
import { Input, Error } from './InputField.styles'
import { useField } from 'formik'

export type InputType =
  | 'text'
  | 'email'
  | 'password'
  | 'number'
  | 'date'
  | 'textarea'
  | 'radio'

interface InputFieldProps extends BaseFieldProps {
  type?: InputType
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface DynamicInputProps {
  type?: InputType
  component?: any
}

const InputField: FC<InputFieldProps> = ({
  name,
  label,
  placeholder,
  type = 'text',
  required,
  validate,
  editable,
  value: initialValue,
  disabled,
  onChange,
  ...styles
}) => {
  const [{ value }, { error }, { setValue }] = useField({ name })

  const inputProps: DynamicInputProps = {}
  if (type === 'textarea') {
    inputProps.component = 'textarea'
  } else {
    inputProps.type = type
  }

  if (type === 'radio') {
    return (
      <Label variant="radio">
        <Input
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          required={required}
          validate={
            validate ? validate : required ? validateRequired : undefined
          }
          value={initialValue}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.currentTarget.value)
            onChange?.(event)
          }}
        />
        {label}
      </Label>
    )
  }

  return (
    <BaseField
      name={name}
      label={label}
      editable={editable}
      value={value}
      {...styles}>
      <Input
        type={type}
        {...inputProps}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        required={required}
        disabled={disabled}
        validate={validate ? validate : required ? validateRequired : undefined}
      />
      {error && <Error>{error}</Error>}
    </BaseField>
  )
}

export default InputField
