import React, { FC, ReactElement } from 'react'
import { CategoryOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { DateRange } from '@mui/lab/DateRangePicker'

// Components
import { SearchInput } from 'components/SearchInput'
import { Dropdown } from 'components/Dropdown'
import DateRangePicker from 'components/DatePicker/DateRangePicker'
import { ModalInjectProps } from 'components/Modal/Modal'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'

// Styles
import { FiltersContainer, Menu } from './Filters.styles'

import { useFilterControls } from './useFilterControls'

export interface BaseFiltersType {
  name?: string
  date?: DateRange<unknown>
}

interface BaseFilter {
  type: string
  name: string
  displayName: string
  value?: string
}

export interface FiltersState {
  filters: any
  setFilters: React.Dispatch<React.SetStateAction<any>>
  updateSearchValue: <T extends keyof BaseFiltersType>(
    name: T,
    value: NonNullable<BaseFiltersType[T]>
  ) => void
}

interface ButtonFilter extends BaseFilter {
  type: 'button'
  icon: ReactElement
}

interface DropdownFilterOption {
  value: string
  text: string
  active: boolean
  onClick: (value: string) => void
}

interface DropdownFilter extends BaseFilter {
  type: 'choice'
  options: (state: FiltersState) => DropdownFilterOption[]
}

type FilterComponents = (ButtonFilter | DropdownFilter)[]

interface FiltersProps {
  filterComponents?: (state: FiltersState) => FilterComponents
  onChange: <T extends keyof BaseFiltersType>(
    name: T,
    value: NonNullable<BaseFiltersType[T]>,
    state: FiltersState
  ) => void
  onSubmit: (value: BaseFiltersType) => void
}

// Helper components
interface SearchMenuProps extends FiltersState {
  filterComponents?: (state: FiltersState) => FilterComponents
  onFilterChange: <T extends keyof BaseFiltersType>(
    name: T,
    value: NonNullable<BaseFiltersType[T]>
  ) => void
  onDatePickerOpenChange: (open: boolean) => void
}

interface MobileMenuProps extends ModalInjectProps, SearchMenuProps {
  filterComponents?: (state: FiltersState) => FilterComponents
  searchInputRef: React.RefObject<HTMLInputElement>
  searchValue: string
  onClearClick: () => void
  onCloseClick: () => void
}

const commonFilters = ({
  filters,
  setFilters,
  updateSearchValue,
  filterComponents,
  onFilterChange,
  onDatePickerOpenChange,
}: SearchMenuProps) => {
  const arr =
    filterComponents?.({ filters, setFilters, updateSearchValue })?.map(
      (filter) => {
        switch (filter.type) {
          case 'button': {
            return (
              <Button
                key={`filter-${filter.name}`}
                size="small"
                variant="tertiary"
                icon={filter.icon}
                active={!!filter.value}
                onClick={() =>
                  onFilterChange(filter.name as any, !filter.value)
                }>
                {filter.displayName}
              </Button>
            )
          }

          case 'choice': {
            return (
              <Dropdown
                key={`filter-${filter.name}`}
                toggle={<>{filter.displayName}</>}
                toggleProps={{
                  icon: <CategoryOutlined />,
                  elementVariant: 'button',
                  active: !!filter.value,
                }}
                options={filter.options({
                  filters,
                  setFilters,
                  updateSearchValue,
                })}
              />
            )
          }
        }
      }
    ) || []

  return [
    ...arr,
    <DateRangePicker
      key={`filter-date-range`}
      onOpen={() => onDatePickerOpenChange(true)}
      onClose={() => onDatePickerOpenChange(false)}
      onChange={(value) => onFilterChange('date', value)}
      active={!!filters.date?.length}
    />,
  ]
}
const DesktopMenu: FC<SearchMenuProps> = ({
  filters,
  setFilters,
  updateSearchValue,
  filterComponents,
  onFilterChange,
  onDatePickerOpenChange,
}) => (
  <Menu>
    {commonFilters({
      filters,
      setFilters,
      updateSearchValue,
      filterComponents,
      onFilterChange,
      onDatePickerOpenChange,
    })}
  </Menu>
)

const MobileMenu: FC<MobileMenuProps> = ({
  filterComponents,
  searchInputRef,
  searchValue,
  filters,
  setFilters,
  updateSearchValue,
  onFilterChange,
  onClearClick,
  onCloseClick,
  onDatePickerOpenChange,
}) => {
  const filtersList = commonFilters({
    filters,
    setFilters,
    updateSearchValue,
    filterComponents,
    onFilterChange,
    onDatePickerOpenChange,
  })
  const handleCancel = () => {
    onClearClick()
    onCloseClick()
  }

  return (
    <Modal onCloseClick={onCloseClick}>
      <Modal.Body padded>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchInput
              inputRef={searchInputRef}
              name="name"
              value={searchValue}
              placeholder="Search"
              onChange={(event) => onFilterChange('name', event.target.value)}
              onClearClick={onClearClick}
            />
          </Grid>
          {filtersList.map((filter, index) => (
            <Grid key={`mobile-filter-${index}`} item xs={12}>
              {filter}
            </Grid>
          ))}
        </Grid>
      </Modal.Body>

      <Modal.Footer>
        <Grid container>
          <Grid item xs={12} mb={2} onClick={onCloseClick}>
            <Button fullWidth>Apply</Button>
          </Grid>
          <Grid item xs={12} onClick={handleCancel}>
            <Button fullWidth variant="secondary">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal.Footer>
    </Modal>
  )
}

export const Filters: FC<FiltersProps> = ({
  filterComponents,
  onChange,
  onSubmit,
}) => {
  const {
    containerRef,

    searchInputRef,
    searchValue,

    filters,
    handleFilterChange,
    setFilters,
    updateSearchValue,
    handleClearFilters,

    canShowMobileMenu,
    filterMenuOpen,
    handleMenuOpen,
    handleMenuClose,

    setDatePickerOpen,
  } = useFilterControls<BaseFiltersType>({ onSubmit })

  const handleChange = <T extends keyof BaseFiltersType>(
    name: T,
    value: NonNullable<BaseFiltersType[T]>
  ) => {
    // In case of one of the default filter, useFilterControls will take
    // care of that
    if (name === 'name' || name === 'date') {
      handleFilterChange(name, value)
      return
    }

    onChange(name, value, { filters, setFilters, updateSearchValue })
  }

  if (canShowMobileMenu) {
    return (
      <MobileMenu
        {...{
          filters,
          setFilters,
          updateSearchValue,
          searchInputRef,
          searchValue,
          filterComponents,
        }}
        onFilterChange={handleChange}
        onDatePickerOpenChange={setDatePickerOpen}
        onClearClick={handleClearFilters}
        onCloseClick={handleMenuClose}
      />
    )
  }

  return (
    <FiltersContainer ref={containerRef} $$open={filterMenuOpen}>
      <SearchInput
        inputRef={searchInputRef}
        name="name"
        value={searchValue}
        placeholder="Search"
        onFocus={handleMenuOpen}
        onChange={(event) => handleFilterChange('name', event.target.value)}
        onClearClick={handleClearFilters}
      />
      {filterMenuOpen && (
        <DesktopMenu
          {...{ filters, setFilters, updateSearchValue, filterComponents }}
          onFilterChange={handleChange}
          onDatePickerOpenChange={setDatePickerOpen}
        />
      )}
    </FiltersContainer>
  )
}
