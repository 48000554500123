import { Placement } from '@popperjs/core'
import React, { FC, useState, useEffect, useRef, ReactNode } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'

// Styles
const ButtonContainer = styled.div`
  cursor: pointer;
`

const IconContainer = styled.div`
  margin-right: 10px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  margin: 0 0 16px 6px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
`

const OptionItem = styled.div`
  display: flex;
  padding: 0.8rem 1.6rem;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.surfieGreen};
    background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
  }
`

// Types
interface Option {
  icon: ReactNode
  name: string
  onClick: () => void
}

interface ActionsMenuProps {
  toggle: ReactNode
  options: Option[]
  placement?: Placement
}

const ActionsMenu: FC<ActionsMenuProps> = ({
  toggle,
  options,
  placement = 'auto',
}) => {
  const [open, setOpen] = useState(false)

  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'preventOverflow', enabled: false }],
  })

  const menuContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (menuContainerRef?.current?.contains(event.target as Node)) {
        return
      }

      setOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popperElement])

  return (
    <>
      <ButtonContainer ref={setReferenceElement} onClick={() => setOpen(!open)}>
        {toggle}
      </ButtonContainer>
      {open ? (
        <div ref={menuContainerRef}>
          <MenuContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            {options.map((option) => (
              <OptionItem onClick={option.onClick}>
                <IconContainer>{option.icon}</IconContainer>
                {option.name}
              </OptionItem>
            ))}
          </MenuContainer>
        </div>
      ) : null}
    </>
  )
}

export default ActionsMenu
