import {
  CreateExpenseMutationVariables,
  GetExpensesDocument,
  useCreateExpenseMutation,
} from 'gqlTypes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

interface UseCreateExpenseProps {
  onCompleted: () => void
}

export const useCreateExpense = ({ onCompleted }: UseCreateExpenseProps) => {
  const activeWorkspace = getLocalStorageItem('activeWorkspace')

  const [mutate] = useCreateExpenseMutation({
    refetchQueries: [
      {
        query: GetExpensesDocument,
        variables: {
          workspaceId: activeWorkspace,
          pagination: { limit: 20, page: 1 },
        },
      },
    ],
    onCompleted,
  })

  const createExpense = ({
    ...values
  }: CreateExpenseMutationVariables['input']) => {
    return mutate({
      variables: {
        input: values,
      },
    })
  }

  return [createExpense]
}
