import styled from 'styled-components'
import { ExpandMore } from '@mui/icons-material'

export const Chevron = styled(ExpandMore)`
  justify-self: flex-end;

  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-right: 0;
  }
`
