import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Formik, Form } from 'formik'

// Components
import Modal, { ModalInjectProps } from 'components/Modal/Modal'
import { InputField } from 'components/Form'
import Button from 'components/Button/Button'

// GQL
import {
  useCreateTagMutation,
  useUpdateTagMutation,
  GetTagsDocument,
} from 'gqlTypes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

interface EditTagModalProps extends ModalInjectProps {
  edit: true
  id: string
  name: string
  onCloseClick: () => void
}

interface TagModalProps extends ModalInjectProps {
  edit?: false
  onCloseClick: () => void
}

const TagModal: FC<TagModalProps | EditTagModalProps> = ({
  onCloseClick,
  ...props
}) => {
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  const [createTag] = useCreateTagMutation({
    onCompleted: () => {
      onCloseClick()
    },
    onError: (error) => {
      console.error(error)
      // TODO: Add toast
    },
    refetchQueries: [
      {
        query: GetTagsDocument,
        variables: {
          filters: {
            workspace: {
              id: { eq: activeWorkspaceId },
            },
          },
        },
      },
    ],
  })

  const [updateTag] = useUpdateTagMutation({
    onCompleted: () => {
      onCloseClick()
    },
    onError: (error) => {
      console.error(error)
      // TODO: Add toast
    },
    refetchQueries: [
      {
        query: GetTagsDocument,
        variables: {
          filters: {
            workspace: {
              id: { eq: activeWorkspaceId },
            },
          },
        },
      },
    ],
  })

  const handleSubmit = (values: any) => {
    if (props.edit) {
      updateTag({
        variables: {
          id: props.id,
          input: {
            name: values.name,
          },
        },
      })
    } else {
      createTag({
        variables: {
          input: {
            name: values.name,
            workspace: activeWorkspaceId,
          },
        },
      })
    }
  }

  return (
    <Formik initialValues={props} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Modal title="Edit Tag" onCloseClick={onCloseClick}>
          <Modal.Body>
            <Form>
              <Grid container spacing="32px" padding="0 48px 32px">
                <Grid item sm={12}>
                  <InputField
                    name="name"
                    label="Name"
                    placeholder="Tag name"
                    required
                  />
                </Grid>
              </Grid>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Grid container item padding="0">
              <Grid item margin="0 16px 0 auto">
                <Button variant="secondary">Cancel</Button>
              </Grid>
              <Grid item>
                <Button onClick={submitForm}>Save</Button>
              </Grid>
            </Grid>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}

export default TagModal
