import React, { FC } from 'react'
import { Box } from '@mui/material'

import { useModal } from 'components/Modal/ModalContext'
import Button from 'components/Button/Button'
import CategoryList from '../components/CategoryList/CategoryList'
import CategoryModal from '../components/CategoryModal/CategoryModal'
import {
  useDeleteCategoryMutation,
  GetCategoriesDocument,
  useGetCategoriesQuery,
  CategoryFragment,
} from 'gqlTypes'
import { getLocalStorageItem } from 'utils/localStorage'

export const WorkspaceSettingsCategoriesView: FC = () => {
  const { hideModal, showModal } = useModal()
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  // Queries
  const { data } = useGetCategoriesQuery({
    variables: {
      workspaceId: activeWorkspaceId,
    },
  })

  // Mutations
  const [deleteCategory] = useDeleteCategoryMutation({
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const handleAddCategory = () => {
    showModal(CategoryModal, {
      onCloseClick: hideModal,
    })
  }

  const handleEditCategory = (item: CategoryFragment) => {
    showModal(CategoryModal, {
      edit: true,
      id: item.id!,
      name: item?.name!,
      color: item?.color!,
      onCloseClick: hideModal,
    })
  }

  const handleDeleteCategory = (id: string) => {
    deleteCategory({
      variables: {
        id,
      },
    })
  }

  return (
    <>
      <Box ml="auto">
        <Button onClick={handleAddCategory}>Add category</Button>
      </Box>

      <CategoryList
        items={data?.categories.entries}
        onEditClick={handleEditCategory}
        onDeleteClick={handleDeleteCategory}
      />
    </>
  )
}
