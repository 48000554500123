import Button from 'components/Button/Button'
import { useModal } from 'components/Modal/ModalContext'
import { useGetBudgetQuery } from 'gqlTypes'
import {
  BudgetFormModal,
  CreateBudgetForm,
} from 'modules/Projects/components/BudgetForm'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  AllocationsContainer,
  EmptyStateContainer,
  StatisticsContainer,
  StatisticsLeftToSpent,
  StatisticsLeftToSpentAmount,
  StatisticsProgress,
  AddAllocationText,
  StatisticsSpentAmount,
  StatisticsTotalAmount,
  StatisticsTotalsContainer,
  StatisticsSpentAmountText,
  StatisticsSpentAmountContainer,
  AddAllocationCard,
} from './Budget.styles'
import {
  BudgetAllocationFormModal,
  CreateBudgetAllocationForm,
} from 'modules/Projects/components/BudgetAllocationForm'
import moment from 'moment'
import { Allocation } from './Allocation'

export const Budget = () => {
  const { id } = useParams<{ workspaceId: string; id: string }>()
  const { showModal, hideModal } = useModal()

  const { data, loading } = useGetBudgetQuery({
    variables: {
      projectId: id,
    },
  })

  const handleCreateBudgetClick = () => {
    showModal(BudgetFormModal, {
      title: `Create Budget`,
      children: <CreateBudgetForm projectId={id} />,
      onCloseClick: hideModal,
    })
  }

  const handleCreateBudgetAllocationClick = () => {
    showModal(BudgetAllocationFormModal, {
      title: `Create Budget Allocation`,
      children: <CreateBudgetAllocationForm budgetId={data?.budget.id!} />,
      onCloseClick: hideModal,
    })
  }

  if (loading) return <div>Loading...</div>

  if (!data?.budget)
    return (
      <EmptyStateContainer>
        <Button onClick={handleCreateBudgetClick}>Add Budget</Button>
      </EmptyStateContainer>
    )

  const { totalAmount, spentAmount, allocations, endDate } = data.budget
  const numberOfDaysLeft = moment(endDate).diff(moment(), 'days')

  return (
    <>
      <StatisticsContainer>
        <StatisticsLeftToSpent>
          Left to spent for the next {numberOfDaysLeft} days
        </StatisticsLeftToSpent>
        <StatisticsLeftToSpentAmount>
          {totalAmount - spentAmount} €
        </StatisticsLeftToSpentAmount>
        <StatisticsProgress value={spentAmount} max={totalAmount} />

        <StatisticsTotalsContainer>
          <StatisticsSpentAmountContainer>
            <StatisticsSpentAmount>{spentAmount} €</StatisticsSpentAmount>
            <StatisticsSpentAmountText>Spent til now</StatisticsSpentAmountText>
          </StatisticsSpentAmountContainer>
          <StatisticsTotalAmount>{totalAmount} €</StatisticsTotalAmount>
        </StatisticsTotalsContainer>
      </StatisticsContainer>

      <AllocationsContainer>
        <AddAllocationCard
          variant="secondary"
          onClick={handleCreateBudgetAllocationClick}>
          <AddAllocationText>+ Allocation</AddAllocationText>
        </AddAllocationCard>

        {data?.budget.unallocatedAmount ? (
          <Allocation
            name="Others"
            color="#000000"
            spentAmount={data?.budget.unallocatedAmount}
          />
        ) : null}

        {allocations.map((allocation) => (
          <Allocation
            key={`allocation-${allocation.id}`}
            name={allocation.category.name}
            color={allocation.category.color}
            allocatedAmount={allocation.allocatedAmount}
            spentAmount={allocation.spentAmount}
            remainingAmount={
              allocation.allocatedAmount - allocation.spentAmount
            }
          />
        ))}
      </AllocationsContainer>
    </>
  )
}
