import { Card as RawCard, CardContainer } from 'components/Card'
import styled from 'styled-components'

export const EmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StatisticsLeftToSpent = styled.div`
  font-size: 12px;
  line-height: 1.2;
`

export const StatisticsLeftToSpentAmount = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
  color: hsl(0, 100%, 50%);
`

export const StatisticsProgress = styled.progress`
  width: 100%;
  height: 2.2rem;
  border-radius: 11px;
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    background: ${({ theme }) => theme.colors.mercury};
    border-radius: 11px;
  }

  &::-webkit-progress-value {
    background: ${({ theme }) => theme.colors.keppel};
    border-radius: 11px;
  }
`

export const StatisticsTotalsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
`

export const StatisticsSpentAmountContainer = styled.div`
  display: flex;
  flex-flow: column;
`

export const StatisticsSpentAmount = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: hsl(0, 0%, 17%);
`

export const StatisticsSpentAmountText = styled.span`
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
`

export const StatisticsTotalAmount = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: hsl(0, 100%, 50%);
`

/**
 * Allocations
 */
export const AllocationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  // To prevent shadows being cut
  padding-bottom: 1rem;
  margin-top: 4.8rem;

  ${CardContainer} {
    :not(:last-child) {
      margin-right: 2.4rem;
    }
  }
`

export const AddAllocationText = styled.span`
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray};
  width: 100%;
  text-align: center;
`

export const AddAllocationCard = styled(RawCard)`
  display: flex;
  min-width: 31.7rem;
  height: 6.2rem;
  align-items: center;
  cursor: pointer;
  padding: 1.4rem 1.6rem;
  margin-bottom: 2.4rem;

  :not(:first-child) {
    margin-left: 2.4rem;
  }

  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }
`
