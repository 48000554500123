import styled, { css } from 'styled-components'
import { AccountCircle } from '@mui/icons-material'
import { UnfoldMore } from '@mui/icons-material'

// Utils
import { breakpoint } from 'styles'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 2.2rem;
`

export const Avatar = styled(AccountCircle)`
  &.MuiSvgIcon-root {
    width: 48px;
    height: 48px;

    ${breakpoint('base', 'sm')`
      width: 32px;
      height: 32px;
  `}
  }
`

export const Arrow = styled(UnfoldMore)`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-left: auto;
  }
`

export const OptionItem = styled.div<{ $$active?: boolean }>`
  padding: 0.8rem 1.6rem;
  cursor: pointer;

  ${({ $$active }) =>
    $$active &&
    css`
      color: ${({ theme }) => theme.colors.surfieGreen};
      background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
    `}

  :hover {
    color: ${({ theme }) => theme.colors.surfieGreen};
    background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
  }
`

export const OptionSection = styled.div`
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.colors.bombay};
  font-size: 14px;
  text-transform: uppercase;
`
