// Color names from http://chir.ag/projects/name-that-color
export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  iron: '#dcdddf',
  gray: '#848484',
  mineShaft: '#2C2C2C',
  bombay: '#acadb4',
  concrete: '#F2F2F2',
  surfieGreen: '#0E8484',
  aquaHaze: '#EBF4F4',
  alto: '#D6D6D6',
  pomegranate: '#f32e2e',
  mercury: '#E6E6E6',
  keppel: '#3F9999',
  dustyGray: '#979797',
}
