import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    :root {
      font-size: 62.5%;
    }

    * {
      box-sizing: border-box;
      font-family: 'Montserrat', sans-serif;
    }

    html,
    body {
      width: 100%;
      height: 100%;
      margin: 0px;
      padding: 0px;
      color: ${({ theme }) => theme.colors.mineShaft};
    }

    body {
      display: flex;
      justify-content: center;
      font-size: 16px;
      
    }

    #root {
      width: 100%;
      height: 100%;
    }

    button {
      background-color: transparent;
    }

    h1,
    h2,
    h3 {
      font-weight: bold;
    }

    hr {
      height: 1px;
      width: 100%;
      border: 0;
      background-color: ${({ theme }) => theme.colors.alto};
    }
  `
