import BaseField from 'components/Form/BaseField/BaseField'
import React from 'react'
import { Container, Input } from './InviteMemberInput.styles'
import { Dropdown } from 'components/Dropdown'
import Button from 'components/Button/Button'
import { useFormikContext } from 'formik'

interface InviteMemberInputProps {
  onAdd: (values: any) => void
}

export const InviteMemberInput = ({ onAdd }: InviteMemberInputProps) => {
  const { values } = useFormikContext()

  return (
    <BaseField name="email" label="Email">
      <Container>
        <Input type="text" name="email" />

        <Dropdown
          toggle="Admin"
          toggleProps={{
            elementVariant: 'button',
          }}
          options={[
            {
              value: 'admin',
              text: 'Admin',
            },
            {
              value: 'member',
              text: 'Member',
            },
          ]}
        />
        <Button
          onClick={() =>
            onAdd({ ...(values as any), id: new Date().getTime() })
          }>
          Add
        </Button>
      </Container>
    </BaseField>
  )
}
