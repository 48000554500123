import styled from 'styled-components'

import { Fab } from '@mui/material'

export const FloatingButtonContainer = styled(Fab)`
  &.MuiFab-root {
    position: absolute;
    bottom: 2%;
    right: 4%;
    z-index: 1000;
    background: ${({ theme }) => theme.colors.surfieGreen};

    &:hover {
      background: ${({ theme }) => theme.colors.surfieGreen};
    }
  }
`
