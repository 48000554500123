import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Settings, Logout } from '@mui/icons-material'

// Context
import { useAccount } from 'context/AccountContext'

// Constants
import { routes } from 'constants/routes'

// Local Components
import Menu from './Menu'

// Styles
import { Container, ToggleContainer, Photo, Avatar } from './UserMenu.styles'

const UserMenu: FC = () => {
  const { push } = useHistory()
  const { user } = useAccount()

  const image = user?.photo?.url

  return (
    <Container>
      <Menu
        placement="top-end"
        toggle={
          <ToggleContainer>
            {image ? <Photo src={image} alt="user" /> : <Avatar />}
          </ToggleContainer>
        }
        options={[
          {
            icon: <Settings />,
            name: 'Settings',
            onClick: () => push(routes.SETTINGS.create({})),
          },
          {
            icon: <Logout />,
            name: 'Log out',
            onClick: () => push(routes.SING_OUT.create({})),
          },
        ]}
      />
    </Container>
  )
}

export default UserMenu
