import React, { FC } from 'react'

// GQL
import { TagFragment } from 'gqlTypes'

// Components
import ActionsMenu from 'components/ActionsMenu/ActionsMenu'

// Styles
import { Container, ActionsMenuColumn, Column, Row } from './TagList.styles'

// Types
interface ListProps {
  items?: TagFragment[]
  onClick?: (id: string) => void
  onEditClick: (item: TagFragment) => void
  onDeleteClick: (id: string) => void
}

const List: FC<ListProps> = ({
  items,
  onDeleteClick,
  onClick,
  onEditClick,
}) => {
  const handleRowClick = (id: string) => {
    onClick?.(id)
  }

  return (
    <Container>
      {items?.map((item) => (
        <Row key={item.id}>
          <Column onClick={() => handleRowClick(item.id!)} width="inherit">
            {item?.name!}
          </Column>
          <ActionsMenuColumn>
            <ActionsMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => onEditClick(item),
                },
                {
                  name: 'Delete',
                  onClick: () => onDeleteClick(item.id!),
                },
              ]}
            />
          </ActionsMenuColumn>
        </Row>
      ))}
    </Container>
  )
}

export default List
