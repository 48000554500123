import React, { useState, FC } from 'react'
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { CalendarViewMonth } from '@mui/icons-material'

// Components
import Button from 'components/Button/Button'

// Utils
import { useBreakpoint } from 'styles'

// Styles
import { Chevron } from './DateRangePicker.styles'

interface DatePickerProps {
  startText?: string
  endText?: string
  active?: boolean
  onOpen?: () => void
  onClose?: () => void
  onChange?: (value: DateRange<unknown>) => void
}

const DatePickerNew: FC<DatePickerProps> = ({
  startText,
  endText,
  active,
  onOpen,
  onClose,
  onChange,
}) => {
  const isMobile = !useBreakpoint('sm')

  const [value, setValue] = useState<DateRange<unknown>>([null, null])
  const [open, setOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText={startText}
        endText={endText}
        value={value}
        onChange={(newValue) => {
          setValue(newValue)

          const startDate = newValue?.[0]
          const endDate = newValue?.[1]
          onChange?.([startDate || null, endDate || null])
        }}
        open={isMobile ? open : undefined}
        onOpen={onOpen}
        onClose={() => {
          setOpen(false)
          onClose?.()
        }}
        renderInput={(startProps: any, endProps) => (
          <>
            <Button
              size="small"
              variant="tertiary"
              icon={<CalendarViewMonth />}
              active={active}
              onClick={() => {
                setOpen(true)
                startProps?.inputProps?.onFocus()
              }}>
              Date
              <Chevron />
            </Button>
          </>
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePickerNew
