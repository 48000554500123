import styled, { css } from 'styled-components'

import { ReactComponent as CrossIconUnstyled } from 'icons/cross-icon.svg'
import { breakpoint } from 'styles'

export const Backdrop = styled.div<{ mounted: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.mineShaft}30;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  transition: opacity 300ms linear;

  ${({ mounted }) =>
    mounted
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`

export const ModalContainer = styled.div<{ mounted: boolean }>`
  min-width: 450px;
  max-width: 616px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  padding: 6.4rem 0 3.2rem;
  position: relative;

  ${({ mounted }) => breakpoint('sm')`
    transition: opacity 200ms ease;
    opacity: ${mounted ? '1' : '0'};
  `}

  ${({ mounted }) => breakpoint('base', 'sm')`
    width: 100%;
    height: calc(100% - 68px);

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 3.2rem 0;
    
    transition: bottom 200ms ease;
    position: absolute;
    left: 0;
    bottom: ${mounted ? '0' : '-100%'};
  `}
`

export const ScrollableContainer = styled.div`
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;

  ${breakpoint('base', 'sm')`
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `}
`

export const CrossIcon = styled(CrossIconUnstyled)`
  width: 22px;
  position: absolute;
  top: 37px;
  right: 48px;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.mineShaft};
  padding: 0 4.8rem;
  margin-bottom: 40px;

  ${breakpoint('base', 'sm')`
    padding: 0 16px;
  `}
`
export const Body = styled.div<{ padded?: boolean }>`
  padding: ${({ padded }) => (padded ? '0 4.8rem 10.6rem' : '0')};

  ${({ padded }) => breakpoint('base', 'sm')`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${padded ? '0 16px' : '0'};
  `}
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 3.2rem;
  padding: 3.2rem 4.4rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.alto};
  background: ${({ theme }) => theme.colors.white};
`
