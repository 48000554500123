import React from 'react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'

import {
  InputField,
  DatePickerField,
  CategoryField,
  ButtonField,
} from 'components/Form'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'

export interface FormValues {
  name: string
  categoryId?: string
  tags?: string[]
  date: string
  description?: string | null
}

interface ProjectFormContentProps {
  loading: boolean
  submitButtonText: string
  onCancel?: () => void
}

const ProjectFormContent = ({
  loading,
  submitButtonText,
  onCancel,
}: ProjectFormContentProps) => {
  const { setFieldValue } = useFormikContext<FormValues>()

  if (loading) {
    return (
      <Modal.Body padded>
        <Spinner fullPage={false} />
      </Modal.Body>
    )
  }

  return (
    <>
      <Modal.Body padded>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputField name="name" label="Name" required />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              type="textarea"
              name="description"
              label="Description"
              placeholder="Additional notes"
            />
          </Grid>

          <Grid item xs={12}>
            <CategoryField
              name="categoryId"
              label="Category"
              onCreateNew={(category) =>
                setFieldValue('category', category?.id!)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <DatePickerField name="date" label="Date" required />
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="secondary" fullWidth onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ButtonField type="submit" fullWidth disabled={loading}>
              {submitButtonText}
            </ButtonField>
          </Grid>
        </Grid>
      </Modal.Footer>
    </>
  )
}

export default ProjectFormContent
