import styled from 'styled-components'
import { LayoutProps, layout } from 'styled-system'

export const Container = styled.div<{ maxHeight?: string }>`
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight || 'initial'};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.mineShaft};
  overflow: auto;
  padding: 16px 8px;
`

export const Row = styled.div<{ clickable?: boolean }>`
  width: 100%;
  display: flex;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);

  :not(:last-child) {
    margin-bottom: 16px;
  }

  :hover {
    background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
  }
`

export const Column = styled.div<LayoutProps>`
  ${layout}

  display: flex;
`

export const ActionsMenuColumn = styled(Column)`
  justify-content: center;
`
