import React, { FC } from 'react'

import { Container, Amount, Percentage } from './Progress.styles'

interface ProgressProps {
  label: string
  value: number
  color: string
}

const Progress: FC<ProgressProps> = ({ label, value, color }) => {
  return (
    <Container color={color}>
      <Amount htmlFor="file">{label}</Amount>
      <progress id="file" value={value} max="100">
        {value.toFixed(2)}%
      </progress>
      <Percentage>{value.toFixed(2)}%</Percentage>
    </Container>
  )
}

export default Progress
