import React, { FC } from 'react'
import { StylesConfig } from 'react-select'
import chroma from 'chroma-js'

// Constants
import { CATEGORY_COLORS_OPTIONS, ColorOption } from 'constants/colors'

// Components
import SelectField from '../SelectField/SelectField'
import { BaseFieldProps } from '../BaseField/BaseField'

// Custom option styles
const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const colourStyles: StylesConfig<ColorOption> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color)
    const optionStyle = {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    }

    return { ...optionStyle, ...dot(data.color) }
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
  }),
}

// Types
interface ColorPickerProps extends Omit<BaseFieldProps, 'value'> {}

const ColorPicker: FC<ColorPickerProps> = ({ name, label }) => {
  return (
    <SelectField<ColorOption>
      name={name}
      label={label}
      options={CATEGORY_COLORS_OPTIONS}
      selectStyles={colourStyles}
    />
  )
}

export default ColorPicker
