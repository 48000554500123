import React from 'react'
import {
  Route,
  RouteProps,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router'

// Constants
import { routes } from 'constants/routes'

// Context
import { useAccount } from 'context/AccountContext'

// Utils
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage'

interface PrivateRouteProps extends RouteProps {
  redirectPath?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...props }) => {
  const { isAuthenticated, user } = useAccount()
  const history = useHistory()

  const isEntryRoute = useRouteMatch({
    path: routes.ENTRY.template(),
    exact: true,
  })

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.SIGN_IN.create({}),
        }}
      />
    )
  }

  const activeWorkspace = getLocalStorageItem('activeWorkspace')
  let workspaceId
  if (!activeWorkspace) {
    workspaceId = user?.personalWorkspace?.id!
    setLocalStorageItem('activeWorkspace', workspaceId)
  }

  if (isEntryRoute && (workspaceId || activeWorkspace)) {
    history.replace(
      routes.OVERVIEW.create({
        workspaceId: workspaceId || activeWorkspace,
      })
    )
  }

  return <Route {...props}>{children}</Route>
}

export default PrivateRoute
