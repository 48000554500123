/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Upload: any;
};


export type Budget = {
  __typename?: 'Budget';
  id: Scalars['ID'];
  project: Project;
  totalAmount: Scalars['Float'];
  spentAmount: Scalars['Float'];
  unallocatedAmount: Scalars['Float'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  allocations: Array<BudgetAllocation>;
  recurrent?: Maybe<Scalars['Boolean']>;
};

export type BudgetAllocation = {
  __typename?: 'BudgetAllocation';
  id: Scalars['ID'];
  category: Category;
  allocatedAmount: Scalars['Float'];
  spentAmount: Scalars['Float'];
};

export type CategoriesResponse = {
  __typename?: 'CategoriesResponse';
  entries: Array<Category>;
  pagination?: Maybe<Pagination>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  workspace: Workspace;
};

export type CategoryFiltersInput = {
  name?: Maybe<Scalars['String']>;
};

export type CategoryStatisticsTotals = {
  __typename?: 'CategoryStatisticsTotals';
  total: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type CategorySummary = {
  __typename?: 'CategorySummary';
  category: Category;
  totals: CategoryStatisticsTotals;
};

export type CharYearValue = {
  __typename?: 'CharYearValue';
  year: Scalars['String'];
  months?: Maybe<Array<Maybe<ChartMonthValue>>>;
  total: Scalars['Float'];
};

export type ChartData = {
  __typename?: 'ChartData';
  data?: Maybe<Array<Maybe<CharYearValue>>>;
};

export type ChartMonthValue = {
  __typename?: 'ChartMonthValue';
  month: Scalars['String'];
  total: Scalars['Float'];
};

export type ConvertToParentExpenseInput = {
  name: Scalars['String'];
  categoryId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
};

export type CreateBudgetAllocationInput = {
  budgetId: Scalars['ID'];
  categoryId: Scalars['ID'];
  allocatedAmount: Scalars['Float'];
};

export type CreateBudgetInput = {
  projectId: Scalars['ID'];
  totalAmount: Scalars['Float'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  allocations?: Maybe<Array<Scalars['ID']>>;
  recurrent: Scalars['Boolean'];
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  color: Scalars['String'];
  workspace: Scalars['ID'];
};

export type CreateExpenseInput = {
  name: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  workspaceId: Scalars['ID'];
  pinned?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<Scalars['ID']>>;
  draft?: Maybe<Scalars['Boolean']>;
  type: TransactionType;
};

export type CreateProjectExpenseInput = {
  name: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  files?: Maybe<Array<Scalars['ID']>>;
  draft?: Maybe<Scalars['Boolean']>;
  type: TransactionType;
  projectId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};

export type CreateProjectInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  workspaceId: Scalars['ID'];
  categoryId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateTagInput = {
  name: Scalars['String'];
  workspace: Scalars['ID'];
};

export type CreateWorkspaceInput = {
  name: Scalars['String'];
  type: WorkspaceType;
};


export type DateRangeFilter = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  mimetype: Scalars['String'];
};

export type FileInput = {
  file: Scalars['Upload'];
  name: Scalars['String'];
};

export type InviteInput = {
  email: Scalars['ID'];
  role: MemberRole;
};

export type InviteMemberInput = {
  workspaceID: Scalars['ID'];
  invites: Array<InviteInput>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  jwt: Scalars['String'];
};

export enum MemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type Mutation = {
  __typename?: 'Mutation';
  createBudget: Budget;
  updateBudget: Budget;
  deleteBudget: Scalars['String'];
  createBudgetAllocation: BudgetAllocation;
  updateBudgetAllocation: BudgetAllocation;
  deleteBudgetAllocation: Scalars['String'];
  createCategory: Category;
  updateCategory: Category;
  deleteCategory: Scalars['String'];
  createExpense: Transaction;
  updateExpense: Transaction;
  deleteExpense: Scalars['String'];
  createProjectExpense: Transaction;
  pinOrUnpinExpense: Transaction;
  convertToParentExpense: Transaction;
  createProject: Project;
  updateProject: Project;
  deleteProject: Scalars['String'];
  uploadFile: File;
  deleteFile: Scalars['String'];
  createTag: Tag;
  updateTag: Tag;
  deleteTag: Scalars['String'];
  register: RegisterResponse;
  updateUser: User;
  login: LoginResponse;
  inviteWorkspaceMember?: Maybe<Scalars['String']>;
  acceptWorkspaceInvite?: Maybe<Scalars['String']>;
  deleteWorkspaceMember?: Maybe<Scalars['String']>;
  deleteWorkspaceInvitation?: Maybe<Scalars['String']>;
  createWorkspace: Workspace;
  updateWorkspace: Workspace;
  deleteWorkspace: Scalars['String'];
};


export type MutationCreateBudgetArgs = {
  input: CreateBudgetInput;
};


export type MutationUpdateBudgetArgs = {
  id: Scalars['ID'];
  input: UpdateBudgetInput;
};


export type MutationDeleteBudgetArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBudgetAllocationArgs = {
  input: CreateBudgetAllocationInput;
};


export type MutationUpdateBudgetAllocationArgs = {
  id: Scalars['ID'];
  input: UpdateBudgetAllocationInput;
};


export type MutationDeleteBudgetAllocationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateCategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateExpenseArgs = {
  input: CreateExpenseInput;
};


export type MutationUpdateExpenseArgs = {
  id: Scalars['ID'];
  input: UpdateExpenseInput;
};


export type MutationDeleteExpenseArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProjectExpenseArgs = {
  input: CreateProjectExpenseInput;
};


export type MutationPinOrUnpinExpenseArgs = {
  id: Scalars['ID'];
  input: PinOrUnpinExpenseInput;
};


export type MutationConvertToParentExpenseArgs = {
  id: Scalars['ID'];
  input: ConvertToParentExpenseInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  input: UpdateProjectInput;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type MutationUploadFileArgs = {
  input?: Maybe<FileInput>;
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationUpdateTagArgs = {
  id: Scalars['ID'];
  input: UpdateTagInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationInviteWorkspaceMemberArgs = {
  input: InviteMemberInput;
};


export type MutationAcceptWorkspaceInviteArgs = {
  token: Scalars['String'];
};


export type MutationDeleteWorkspaceMemberArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkspaceInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationUpdateWorkspaceArgs = {
  id: Scalars['ID'];
  input: UpdateWorkspaceInput;
};


export type MutationDeleteWorkspaceArgs = {
  id: Scalars['ID'];
};

export type Pagination = {
  __typename?: 'Pagination';
  limit: Scalars['Int'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type PinOrUnpinExpenseInput = {
  pinned: Scalars['Boolean'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  category?: Maybe<Category>;
  tags?: Maybe<Array<Tag>>;
  transactions: TransactionsResponse;
  workspace: Workspace;
};


export type ProjectTransactionsArgs = {
  filters?: Maybe<TransactionFiltersInput>;
  pagination?: Maybe<PaginationInput>;
};

export type ProjectFiltersInput = {
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  date?: Maybe<DateRangeFilter>;
};

export type ProjectResponse = {
  __typename?: 'ProjectResponse';
  entries: Array<Project>;
  pagination?: Maybe<Pagination>;
};

export type Query = {
  __typename?: 'Query';
  budget: Budget;
  category?: Maybe<Category>;
  categories: CategoriesResponse;
  transaction: Transaction;
  transactions: TransactionsResponse;
  statistics: Statistics;
  project: Project;
  projects: ProjectResponse;
  tag?: Maybe<Tag>;
  tags: TagsResponse;
  user: User;
  workspace?: Maybe<Workspace>;
  workspaces: WorkspacesResponse;
};


export type QueryBudgetArgs = {
  projectId: Scalars['ID'];
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCategoriesArgs = {
  workspaceId: Scalars['ID'];
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryTransactionsArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  filters?: Maybe<TransactionFiltersInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryStatisticsArgs = {
  workspaceId: Scalars['ID'];
  filters?: Maybe<StatisticsFilterInput>;
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectsArgs = {
  workspaceId: Scalars['ID'];
  filters?: Maybe<ProjectFiltersInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTagsArgs = {
  filters?: Maybe<TagFiltersInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryUserArgs = {
  filters: UserFiltersInput;
};


export type QueryWorkspaceArgs = {
  id: Scalars['ID'];
};


export type QueryWorkspacesArgs = {
  filters?: Maybe<WorkspaceFiltersInput>;
  pagination?: Maybe<PaginationInput>;
};

export type RegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  user: User;
  jwt: Scalars['String'];
};

export type Statistics = {
  __typename?: 'Statistics';
  chart?: Maybe<ChartData>;
  summary?: Maybe<StatisticsSummary>;
};

export type StatisticsFilterInput = {
  date?: Maybe<DateRangeFilter>;
  categoryId?: Maybe<Scalars['ID']>;
};

export type StatisticsSummary = {
  __typename?: 'StatisticsSummary';
  expenses?: Maybe<TransactionSummary>;
  categories: Array<CategorySummary>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  workspace: Workspace;
};

export type TagFiltersInput = {
  name?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

export type TagsResponse = {
  __typename?: 'TagsResponse';
  entries: Array<Tag>;
  pagination?: Maybe<Pagination>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  name: Scalars['String'];
  date: Scalars['DateTime'];
  amount: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  tags?: Maybe<Array<Tag>>;
  workspace: Workspace;
  pinned?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<File>>;
  draft?: Maybe<Scalars['Boolean']>;
  type: TransactionType;
};

export type TransactionFiltersInput = {
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  pinned?: Maybe<Scalars['Boolean']>;
  date?: Maybe<DateRangeFilter>;
};

export type TransactionSummary = {
  __typename?: 'TransactionSummary';
  total: Scalars['Float'];
};

export enum TransactionType {
  Income = 'INCOME',
  Expense = 'EXPENSE'
}

export type TransactionsResponse = {
  __typename?: 'TransactionsResponse';
  entries: Array<Transaction>;
  pagination?: Maybe<Pagination>;
  totals: TransactionsTotals;
};

export type TransactionsTotals = {
  __typename?: 'TransactionsTotals';
  expense: Scalars['Float'];
  income: Scalars['Float'];
};

export type UpdateBudgetAllocationInput = {
  categoryId: Scalars['ID'];
  allocatedAmount: Scalars['Float'];
};

export type UpdateBudgetInput = {
  totalAmount: Scalars['Float'];
  spentAmount: Scalars['Float'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  allocations: Array<Scalars['ID']>;
  recurrent: Scalars['Boolean'];
};

export type UpdateCategoryInput = {
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type UpdateExpenseInput = {
  name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pinned?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<Scalars['ID']>>;
  draft?: Maybe<Scalars['Boolean']>;
};

export type UpdateProjectInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  categoryId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateTagInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceInput = {
  name?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  personalWorkspace?: Maybe<Workspace>;
  workspaces?: Maybe<WorkspacesResponse>;
  photo?: Maybe<File>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type UserWorkspacesArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type UserFiltersInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: WorkspaceType;
  members: WorkspaceMembersResponse;
  owner: User;
  image?: Maybe<File>;
};


export type WorkspaceMembersArgs = {
  pagination?: Maybe<PaginationInput>;
};

export type WorkspaceEntry = WorkspaceMember | WorkspaceInvitation;

export type WorkspaceFiltersInput = {
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceInvitation = {
  __typename?: 'WorkspaceInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  role: MemberRole;
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  id: Scalars['ID'];
  user: User;
  workspace: Workspace;
  role: MemberRole;
};

export type WorkspaceMembersResponse = {
  __typename?: 'WorkspaceMembersResponse';
  entries: Array<WorkspaceEntry>;
  pagination?: Maybe<Pagination>;
};

export enum WorkspaceType {
  Personal = 'PERSONAL',
  Enterprise = 'ENTERPRISE'
}

export type WorkspacesResponse = {
  __typename?: 'WorkspacesResponse';
  entries: Array<Workspace>;
  pagination?: Maybe<Pagination>;
};
