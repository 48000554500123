import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

// Utils
import { breakpoint } from 'styles/breakpoints'

export const MainContainer = styled.main`
  display: flex;
  width: 100%;
  height: 100%;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}
`

export const ContentContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  overflow: hidden;
  max-width: 1200px;

  padding: 60px 80px;

  ${breakpoint('base', 'sm')`
    padding: 24px 16px;
    margin-top: 4rem;
  `}
`

export const Header = styled.header`
  display: flex;
  margin-bottom: 56px;
  justify-content: space-between;

  ${breakpoint('base', 'sm')`  
    margin-bottom: 56px;
  `}
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 32px;

  ${breakpoint('base', 'sm')`
    margin: 0;
  `}
`

export const Container = styled.div<{
  styleOverrides?: FlattenSimpleInterpolation
  alignItems: 'left' | 'center'
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ alignItems }) =>
    alignItems === 'center' &&
    css`
      align-items: center;
    `}

  ${({ styleOverrides }) => styleOverrides}
`
