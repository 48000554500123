export interface ColorOption {
  value: string
  label: string
  color: string
  isFixed?: boolean
  isDisabled?: boolean
}

export const CATEGORY_COLORS_OPTIONS: ColorOption[] = [
  { value: '#d63339', label: 'Valencia', color: '#d63339' },
  { value: '#2a87d0', label: 'CuriousBlue', color: '#2a87d0' },
  { value: '#379840', label: 'Apple', color: '#379840' },
  { value: '#7d3798', label: 'VividViolet', color: '#7d3798' },
  { value: '#985737', label: 'PottersClay', color: '#985737' },
  { value: '#378198', label: 'Astral', color: '#378198' },
  { value: '#000000', label: 'Black', color: '#000000' },
]

export const CATEGORY_COLORS = {
  valencia: '#d63339',
  curiousBlue: '#2a87d0',
  apple: '#379840',
  vividViolet: '#7d3798',
  pottersClay: '#985737',
  astral: '#378198',
  black: '#000000',
}

// TODO: Fix this
// export const CATEGORY_COLORS = CATEGORY_COLORS_OPTIONS.reduce(
//   (acc, { label, color }) => ({ ...acc, [label]: color }),
//   {}
// )
