import { useHistory } from 'react-router-dom'

import { useAccount } from 'context/AccountContext'
import { routes } from 'constants/routes'
import { useEffect } from 'react'

const SignOut = () => {
  const { logOut, isAuthenticated } = useAccount()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated) {
      logOut()
    } else {
      history.push(routes.SIGN_IN.create({}))
    }
  }, [history, isAuthenticated, logOut])

  return null
}

export default SignOut
