import React, { useState } from 'react'
import {
  Container,
  FilterContainer,
  FilterTitle,
  Chevron,
  DropdownFilterContent,
  TotalContainer,
  TotalTitle,
  TotalValueSpent,
  TotalValueIncome,
  DropdownToggle,
} from './ProjectTotals.styles'
import { Dropdown } from 'components/Dropdown'
import moment from 'moment'

interface ProjectTotalsProps {
  totalExpense: number
  totalIncome: number
  onChange: (value?: [string, string]) => void
}

type FilterOption = 'All time' | 'Current Month' | 'Last Month'

export const ProjectTotals = ({
  totalExpense,
  totalIncome,
  onChange,
}: ProjectTotalsProps) => {
  const [selectedOption, setSelectedOption] = useState<FilterOption>('All time')

  const handleSelectedOption = (option: FilterOption) => {
    setSelectedOption(option)

    switch (option) {
      case 'All time':
        onChange()
        break
      case 'Current Month':
        onChange([
          moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ])
        break
      case 'Last Month':
        onChange([
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
        ])
        break
    }
  }

  return (
    <Container>
      <FilterContainer>
        <FilterTitle>Balance</FilterTitle>
        <DropdownFilterContent>
          {/* TODO: Implement these filters on BE */}
          <Dropdown
            toggleProps={{
              elementVariant: 'custom',
            }}
            toggle={
              <DropdownToggle>
                <span>{selectedOption}</span>
                <Chevron />
              </DropdownToggle>
            }
            options={[
              {
                value: 'All time',
                text: 'All time',
                onClick: () => handleSelectedOption('All time'),
                active: selectedOption === 'All time',
              },
              {
                value: 'Current Month',
                text: 'Current Month',
                onClick: () => handleSelectedOption('Current Month'),
                active: selectedOption === 'Current Month',
              },
              {
                value: 'Last Month',
                text: 'Last Month',
                onClick: () => handleSelectedOption('Last Month'),
                active: selectedOption === 'Last Month',
              },
            ]}
          />
        </DropdownFilterContent>
      </FilterContainer>
      <TotalContainer>
        <TotalTitle>Spent</TotalTitle>
        <TotalValueSpent>{totalExpense.toFixed(2)} €</TotalValueSpent>
      </TotalContainer>
      <TotalContainer>
        <TotalTitle>Income</TotalTitle>
        <TotalValueIncome>{totalIncome.toFixed(2)} €</TotalValueIncome>
      </TotalContainer>
    </Container>
  )
}
