import React, { FC, useCallback, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import ObjectID from 'bson-objectid'
import { useField } from 'formik'

// Components
import BaseField, { BaseFieldProps } from '../BaseField/BaseField'
import { validateRequired } from 'components/Form'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

// Styles
import { colors } from 'styles/colors'
import {
  useCreateTagMutation,
  GetTagsDocument,
  useGetTagsQuery,
  TagFragment,
} from 'gqlTypes'

// Select styles
const singleValueStyles = (provided: any, state: any, override: any) => {
  const opacity = state.isDisabled ? 0.5 : 1
  const transition = 'opacity 300ms'

  return {
    ...provided,
    opacity,
    transition,
    fontSize: 12,
    fontWeight: 700,
    color: colors.black,
    background: '#e2e2e2',
    borderRadius: 4,
    padding: '2px 8px',
    textTransform: 'capitalize',
    ...override,
  }
}

const baseCustomStyles = {
  menu: (provided: any) => ({
    ...provided,
    fontSize: 12,
    textTransform: 'capitalize',
  }),

  control: (provided: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 300,
  }),

  singleValue: (provided: any, state: any) => {
    return singleValueStyles(provided, state, {})
  },

  multiValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return {
      ...provided,

      opacity,
      transition,
      fontSize: 12,
      fontWeight: 700,
      padding: '2px 8px',
      color: colors.dustyGray,
      background: colors.white,
      borderRadius: 4,
      borderWidth: 2,
      borderColor: colors.dustyGray,
      borderStyle: 'solid',
    }
  },

  multiValueLabel: (provided: any) => {
    return {
      ...provided,
      color: colors.dustyGray,
    }
  },
}

// Types
interface LocalValue {
  value: string
  label: string
}

interface TagFieldProps extends Omit<BaseFieldProps, 'value'> {
  multi?: boolean
  placeholder?: string
  onCreateNew?: (tag: TagFragment) => void
}

const TagField: FC<TagFieldProps> = ({
  name,
  label,
  onCreateNew,
  placeholder = '',
  required,
  validate,
  multi = false,
  ...styles
}) => {
  const workspaceId = getLocalStorageItem('activeWorkspace')

  const { data: tagsData } = useGetTagsQuery({
    variables: {
      filters: {
        workspaceId,
      },
    },
  })
  const tags = tagsData?.tags?.entries

  // eslint-disable-next-line no-empty-pattern
  const [{}, field, { setValue }] = useField({
    name,
    required,
    validate: validate ? validate : required ? validateRequired : undefined,
  })
  const [localValue, setLocalValue] = useState<LocalValue[]>([])

  const [customStyles, setCustomStyles] = useState(baseCustomStyles)

  const getTasFromTagIds = useCallback(() => {
    if (!tags?.length) return

    return field.value?.map((value: string) => {
      const tagFound = tags.find(({ id }) => id === value)

      return {
        value: tagFound?.id,
        label: tagFound?.name,
      }
    })
  }, [field.value, tags])

  useEffect(() => {
    if (field.value && !localValue.length) {
      const tagsWithDetails = getTasFromTagIds()

      if (!tagsWithDetails) return
      setLocalValue(tagsWithDetails)
    }
  }, [field.value, localValue.length, getTasFromTagIds])

  const [createTag] = useCreateTagMutation({
    refetchQueries: [
      {
        query: GetTagsDocument,
        variables: {
          workspaceId,
        },
      },
    ],
    onCompleted: (data) => {
      onCreateNew?.(data.createTag!)
    },
  })

  const tagsOptions =
    tags?.map((tag) => {
      return {
        value: tag.id,
        label: tag.name,
      }
    }) || []

  const handleCreateTag = ({ name }: { name: string }) => {
    createTag({
      variables: {
        input: {
          workspace: workspaceId,
          name,
        },
      },
    })
  }

  return (
    <BaseField name={name} label={label} {...styles}>
      <CreatableSelect
        isClearable
        isMulti={multi}
        options={tagsOptions}
        styles={customStyles}
        value={localValue}
        onChange={(option: any) => {
          if (!option) {
            setValue(null)
            return
          }

          const { ...newCustomStyles } = customStyles

          if (option?.__isNew__!) {
            const tagId = ObjectID.generate()

            handleCreateTag({
              name: option.label,
            })

            setLocalValue([
              {
                value: tagId,
                label: option.label,
              },
            ])
          } else {
            newCustomStyles.singleValue = (provided: any, state: any) =>
              singleValueStyles(provided, state, {
                background: option.color,
              })

            setLocalValue(option)
          }

          setCustomStyles(newCustomStyles)

          const tagIds = option.map(({ value }: any) => value)
          setValue(tagIds)
        }}
      />
    </BaseField>
  )
}

export default TagField
