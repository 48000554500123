import React, { FC, useEffect } from 'react'
import { LayoutProps, SpaceProps } from 'styled-system'
import { useFormikContext } from 'formik'

import Button, { ButtonProps } from 'components/Button/Button'

const ButtonField: FC<ButtonProps & SpaceProps & LayoutProps> = ({
  disabled,
  ...props
}) => {
  const { isSubmitting, isValid, validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return <Button {...props} disabled={!isValid || isSubmitting || disabled} />
}

export default ButtonField
