import styled, { css } from 'styled-components'

import { breakpoint } from 'styles'

export const FiltersContainer = styled.div<{ $$open: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${({ theme }) => theme.colors.white};

  transition: all 400ms linear;

  ${breakpoint('xs')`
    width: 100%;
    /* Apply this only on desktop, on mobile it's applied directly on the input field */
    border: 1px solid ${({ theme }) => theme.colors.iron};
    border-radius: 8px;
  `}

  ${({ $$open }) =>
    $$open &&
    css`
      box-shadow: 0 5px 5px -3px hsla(0, 0%, 0%, 0.16),
        0 8px 10px 1px hsla(0, 0%, 0%, 0.05),
        0 3px 14px 2px hsla(0, 0%, 0%, 0.08);
    `}
`

export const Menu = styled.div`
  display: flex;
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 16px;

  button {
    margin-right: 16px;
  }
`
