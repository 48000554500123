import React, { FC } from 'react'

// Local components
import Progress from '../Progress/Progress'

// Components
import Tag from 'components/Tag/Tag'

// Styles
import { Container } from './CategoryStatisticsItem.styles'

interface CategoryStatisticsItemProps {
  name: string
  color: string
  amount: number
  percentage: number
}

const CategoryStatisticsItem: FC<CategoryStatisticsItemProps> = ({
  name,
  color,
  amount,
  percentage,
}) => {
  return (
    <Container>
      <Tag tag={name} color={color} />
      <Progress
        label={`€ ${amount.toFixed(2)}`}
        value={percentage}
        color={color}
      />
    </Container>
  )
}

export default CategoryStatisticsItem
