import React, { FC } from 'react'

import { Container, Title } from './Panel.styles'

interface PanelProps {
  title?: string
}

const Panel: FC<PanelProps> = ({ children, title }) => {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {children}
    </Container>
  )
}

export default Panel
