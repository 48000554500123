import styled, { css } from 'styled-components'
import TagUnstyled from 'components/Tag/Tag'

export const ParentExpenseHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  h1 {
    margin: 0;
    font-size: 24px;
  }
`

export const Tag = styled(TagUnstyled)`
  margin-left: 10px;
`

export const layoutContainerStyles = css`
  /* These need to be override because of the box-shadows on the list items  */
  width: calc(100% + 16px);
  margin-left: -8px;
  margin-top: -16px;
  padding: 1.6rem 0.8rem;
`

export const ProjectInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
