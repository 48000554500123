import React, { useCallback } from 'react'
import { InView } from 'react-intersection-observer'

// GQL
import { GetExpensesQueryResult } from 'gqlTypes'

// Styles
import { ListItemPlaceholder } from 'modules/Expenses/components/ExpensesList/ExpensesList.styles'
import {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  OperationVariables,
} from '@apollo/client'

type FetchMoreFunc<TData = any, TVariables = OperationVariables> = (
  fetchMoreOptions: FetchMoreQueryOptions<TVariables, keyof TVariables> &
    FetchMoreOptions<TData, TVariables>
) => Promise<ApolloQueryResult<TData>>

interface ExpensesPaginationProps {
  data: NonNullable<GetExpensesQueryResult['data']>['transactions']['entries']
  fetchMore: FetchMoreFunc
  page: number
  limit?: number
  totalCount?: number
}

interface PlaceholdersWithObservableProps {
  count: number
  onLoadMore(): Promise<unknown>
}

const renderPlaceholders = ({
  count,
  onLoadMore,
}: PlaceholdersWithObservableProps) =>
  Array.from({
    length: count,
  }).map((_, index) => {
    if (index === 0) {
      return (
        <InView key={index} onChange={(inView) => inView && onLoadMore()}>
          <ListItemPlaceholder />
        </InView>
      )
    } else {
      return <ListItemPlaceholder key={index} />
    }
  })

export const useExpensesPagination = ({
  data,
  fetchMore,
  page = 1,
  totalCount = 0,
  limit: pageSize = 20,
}: ExpensesPaginationProps) => {
  const currentDataLength = data.length || 0
  const remaining =
    currentDataLength === totalCount ? 0 : totalCount - currentDataLength

  const placeholdersCount =
    currentDataLength === 0
      ? pageSize
      : pageSize <= remaining
      ? pageSize
      : remaining

  const handleFetchMore = useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          page,
          limit: 20,
        },
      },
    })
  }, [page, fetchMore])

  return {
    placeholders: renderPlaceholders({
      count: placeholdersCount,
      onLoadMore: handleFetchMore as any,
    }),
  }
}
