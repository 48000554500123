import React from 'react'
// import ReactDOM from 'react-dom'
import ExpensesList from '../../../Expenses/components/ExpensesList/ExpensesList'
import { ExpenseFormModal } from 'modules/Expenses/components/ExpenseFormModal'
import { UpdateExpense } from 'modules/Expenses/components/ExpenseForm'
import { useModal } from 'components/Modal/ModalContext'
import { Grid } from '@mui/material'
import { Filters, BaseFiltersType, FiltersState } from 'components/Filters'
import { useExpensesPagination } from 'modules/Expenses/components/ExpensesList/useExpensesPagination'
import {
  GetProjectDetailsDocument,
  useDeleteExpenseMutation,
  useGetProjectTransactionsQuery,
} from 'gqlTypes'
import { useParams } from 'react-router-dom'
import Spinner from 'components/Spinner/Spinner'
import { ExpenseListContainer, FilterContainer } from './Expenses.styles'

interface FiltersType extends BaseFiltersType {
  category?: string
}

// const LayoutHeaderPortal = ({ children }: React.PropsWithChildren<{}>) => {
//   const el = document.getElementById('layout-header-portal')
//   if (!el) return null

//   return ReactDOM.createPortal(children, el)
// }

export const Expenses = () => {
  const { id } = useParams<{ workspaceId: string; id: string }>()
  const { showModal, hideModal } = useModal()

  const {
    data: projectData,
    fetchMore,
    loading,
    refetch,
  } = useGetProjectTransactionsQuery({
    variables: {
      id,
      pagination: {
        limit: 20,
        page: 1,
      },
    },
  })
  const project = projectData?.project
  const expenses = project?.transactions.entries

  const [deleteExpense] = useDeleteExpenseMutation({
    refetchQueries: [
      {
        query: GetProjectDetailsDocument,
        variables: {
          id,
          pagination: {
            limit: 20,
            page: 1,
          },
        },
      },
    ],
  })

  const { placeholders } = useExpensesPagination({
    data: expenses || [],
    fetchMore,
    page: project?.transactions.pagination?.page! + 1,
    totalCount: project?.transactions.pagination?.total,
  })

  const handleDelete = (id: string) => {
    deleteExpense({
      variables: {
        id,
      },
    })
  }

  const handleFilterChange = <T extends keyof FiltersType>(
    name: T,
    value: NonNullable<FiltersType[T]>,
    { filters, setFilters, updateSearchValue }: FiltersState
  ) => {
    switch (name) {
      // case 'category': {
      //   setFilters({ ...filters, category: value as string })
      //   updateSearchValue(name as any, value)
      //   break
      // }

      default: {
        setFilters({ ...filters, [name]: value })
        updateSearchValue(name as any, value)
      }
    }
  }

  const handleSearch = async (filters: FiltersType) => {
    refetch({
      filters: {
        name: filters.name,
        // categoryId: filters.category,
        // date: {
        //   between: filters.date,
        // },
      },
    })
  }

  if (loading) {
    return <Spinner />
  }

  if (!expenses?.length) {
    return (
      <>
        <Grid container textAlign="center" spacing={2}>
          <Grid item sm={12}>
            You don't have expenses
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <FilterContainer>
        <Filters onChange={handleFilterChange} onSubmit={handleSearch} />
      </FilterContainer>

      <ExpenseListContainer>
        <ExpensesList
          items={expenses}
          canPinExpenses={false}
          onClick={(item) => {
            showModal(ExpenseFormModal, {
              title: `Edit expense`,
              children: <UpdateExpense id={item?.id} />,
              onCloseClick: hideModal,
            })
          }}
          onEditClick={(item) => {
            // TODO: Review this.
            // Does not make sense to have the same action twice
            showModal(ExpenseFormModal, {
              title: `Edit expense`,
              children: <UpdateExpense id={item?.id} />,
              onCloseClick: hideModal,
            })
          }}
          onDeleteClick={handleDelete}
          placeholders={placeholders}
        />
      </ExpenseListContainer>
    </>
  )
}
