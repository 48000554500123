import React from 'react'
import { Container } from './Card.styles'

interface CardProps {
  className?: string
  variant?: 'primary' | 'secondary'
  onClick?: () => void
}

export const Card = ({
  children,
  variant = 'primary',
  className,
  onClick,
}: React.PropsWithChildren<CardProps>) => {
  return (
    <Container
      className={className}
      borderStyle={variant === 'primary' ? 'solid' : 'dotted'}
      variant={variant}
      onClick={onClick}>
      {children}
    </Container>
  )
}
