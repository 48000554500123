import styled, { css } from 'styled-components'
import { PictureAsPdf, Delete, PictureInPicture } from '@mui/icons-material'

export const Input = styled.input`
  display: none;
`

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilePreviewContainer = styled.div<{ $loading?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  ${({ $loading }) =>
    $loading &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

export const FileLink = styled.a`
  width: 100%;
  color: ${({ theme }) => theme.colors.mineShaft};
  text-decoration: none;
`

export const PDFIcon = styled(PictureAsPdf)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.surfieGreen};
    font-size: 24px;
  }
`
export const PictureIcon = styled(PictureInPicture)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.surfieGreen};
    font-size: 24px;
  }
`

export const DeleteIcon = styled(Delete)`
  cursor: pointer;

  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.surfieGreen};
    font-size: 24px;
  }
`

export const FileName = styled.span`
  margin-left: 8px;
`

export const TextIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`
