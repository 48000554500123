import React from 'react'
import { Grid } from '@mui/material'

import { DatePickerField, ButtonField, PriceField } from 'components/Form'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'
import { CreateBudgetMutationVariables } from 'gqlTypes'

export interface FormValues
  extends Omit<CreateBudgetMutationVariables['input'], 'projectId'> {}

interface BudgetFormContentProps {
  loading: boolean
  submitButtonText: string
  onCancel?: () => void
}

export const BudgetFormContent = ({
  loading,
  submitButtonText,
  onCancel,
}: BudgetFormContentProps) => {
  if (loading) {
    return (
      <Modal.Body padded>
        <Spinner fullPage={false} />
      </Modal.Body>
    )
  }

  return (
    <>
      <Modal.Body padded>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <DatePickerField name="startDate" label="Date" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField name="endDate" label="Date" required />
          </Grid>
          <Grid item xs={12}>
            <PriceField name="totalAmount" label="Total Amount" required />
          </Grid>
          <Grid item xs={12}>
            Recurrent
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="secondary" fullWidth onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ButtonField type="submit" fullWidth disabled={loading}>
              {submitButtonText}
            </ButtonField>
          </Grid>
        </Grid>
      </Modal.Footer>
    </>
  )
}
