import styled from 'styled-components'
import { Card as CardRaw } from 'components/Card'
import { breakpoint } from 'styles'

export const Card = styled(CardRaw)`
  display: flex;
  flex-direction: column;
  min-width: 32rem;
  height: 13.8rem;
  box-shadow: 0 5px 5px -3 hsla(0, 0%, 0%, 0.16),
    0 8px 10px 1px hsla(0, 0%, 0%, 0.05), 0 3px 14px 2px hsla(0, 0%, 0%, 0.08);
  padding: 1.6rem;
  display: flex;

  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }

  ${breakpoint('xs')`
    width: 100%;
  `}
`

export const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: hsl(0, 0%, 17%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Date = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.bombay};
`

export const IncomeExpenseWrapper = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
`

export const IncomeExpenseContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const Separator = styled.div`
  display: flex;
  width: 0.1rem;
  height: 100%;
  background: ${({ theme }) => theme.colors.mercury};
  margin: 0 1.2rem;
`

export const ExpenseTitle = styled.div`
  color: hsl(0, 100%, 50%);
  font-size: 10px;
  font-weight: 600;
`

export const IncomeTitle = styled.div`
  color: hsl(180, 81%, 29%);
  font-size: 10px;
  font-weight: 600;
`

export const TextContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  img {
    width: 18px;
    height: 18px;
  }
`
