import React, { FC } from 'react'

import Modal, { ModalInjectProps } from 'components/Modal/Modal'

interface ProjectFormModalProps extends ModalInjectProps {
  children: React.ReactElement
}

export const ProjectFormModal: FC<ProjectFormModalProps> = ({
  title,
  onCloseClick,
  children,
}) => {
  return (
    <Modal title={title} onCloseClick={onCloseClick}>
      {children}
    </Modal>
  )
}
