import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
    margin-top: 10px;
  }
`

export const Title = styled.h1`
  margin-bottom: 32px;
  font-size: ${({ theme }) => theme.typography.heading.fontSize};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 400;
`
