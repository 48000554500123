import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid hsla(0, 0%, 17%, 0.2);
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  justify-content: center;
`

export const Name = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
`

export const Email = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: hsla(0, 0%, 17%, 0.6);
`

export const Role = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 4rem;
`

export const InvitationPending = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: hsla(0, 0%, 17%, 0.6);
`

export const RightContainer = styled.div`
  display: flex;
`
