import styled from 'styled-components'
import { lighten } from 'polished'

export const Container = styled.div<{ color: string }>`
  font-size: 16px;
  display: flex;
  align-items: center;

  progress[value] {
    appearance: none;
    height: 10px;

    ::-webkit-progress-bar {
      border-radius: 8px;
      background-color: ${({ color }) => lighten(0.45, color)};
    }

    ::-webkit-progress-value {
      border-radius: 8px;
      background-color: ${({ color }) => color};
    }
  }
`

export const Amount = styled.label`
  width: 80px;
  margin-right: 12px;
`

export const Percentage = styled.label`
  margin-left: 12px;
  font-size: 12px;
`
