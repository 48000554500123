import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { InputType } from 'components/Form/InputField/InputField'

export interface InputProps {
  type?: InputType
  placeholder?: string
  value?: string
  onChange?: any
  onBlur?: any
  onFocus?: any
}

export const BaseInputStyle = css`
  height: 40px;
  width: 100%;
  padding: 0 12px;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;

  :focus {
    outline: 0;
  }

  ::placeholder {
    font-size: 16px;
    opacity: 50%;
    color: ${({ theme }) => theme.colors.bombay};
  }
`

export const StyledInput = styled.input`
  ${BaseInputStyle}
`

const Input: FC<InputProps> = ({ type = 'text', ...props }) => {
  return <StyledInput type={type} {...props} />
}

export default Input
