import {
  SplitButton,
  SplitButtonProps,
} from 'components/SplitButton/SplitButton'
import { useField } from 'formik'
import React from 'react'

interface SplitButtonFieldProps extends SplitButtonProps {
  name: string
}

export const SplitButtonField = ({
  name,

  ...props
}: SplitButtonFieldProps) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ value }, {}, { setValue }] = useField({
    name,
  })

  return <SplitButton {...props} value={value} onChange={setValue} />
}
