import React, { FC } from 'react'

import { Add as AddIcon } from '@mui/icons-material'

import { FloatingButtonContainer } from './FloatingButton.styles'

interface FloatingButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const FloatingButton: FC<FloatingButtonProps> = ({ onClick }) => (
  <FloatingButtonContainer
    color="primary"
    aria-label="add"
    size="large"
    onClick={onClick}>
    <AddIcon />
  </FloatingButtonContainer>
)
