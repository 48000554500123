import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import moment from 'moment'

// Components
import { useModal } from 'components/Modal/ModalContext'

// Constants
import { routes } from 'constants/routes'

// Types
import { BudgetFormContent, FormValues } from './BudgetFormContent'
import { useCreateBudgetMutation } from 'gqlTypes'

const initialValues: Partial<FormValues> = {
  startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
}

interface CreateBudgetFormProps {
  projectId: string
}

export const CreateBudgetForm = ({ projectId }: CreateBudgetFormProps) => {
  const { hideModal } = useModal()

  // Route handling
  const { push } = useHistory()

  const [createBudget] = useCreateBudgetMutation({
    onCompleted: hideModal,
  })

  // Handles
  const handleCancel = () => {
    hideModal()
    push(routes.ENTRY.create({}))
  }

  const handleSubmit = (values: FormValues) => {
    createBudget({
      variables: {
        input: { ...values, projectId, recurrent: false },
      },
    })
  }

  return (
    <Formik initialValues={initialValues as any} onSubmit={handleSubmit}>
      <Form>
        <BudgetFormContent
          submitButtonText="Create Budget"
          loading={false}
          onCancel={handleCancel}
        />
      </Form>
    </Formik>
  )
}
