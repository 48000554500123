import { useAcceptWorkspaceInviteMutation } from 'gqlTypes'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const AcceptWorkspaceInviteView = () => {
  const { token } = useParams<{ token: string }>()
  const [acceptInvite, { loading, data }] = useAcceptWorkspaceInviteMutation()

  useEffect(() => {
    acceptInvite({ variables: { token } })
  }, [acceptInvite, token])

  if (loading) return <div>Loading...</div>

  return <div>{data?.acceptWorkspaceInvite}</div>
}
