import styled from 'styled-components'

import { SearchOutlined, Cancel } from '@mui/icons-material'

import { breakpoint } from 'styles'

export const Container = styled.div`
  height: 40px;
  width: 100%;

  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 24;
  overflow: hidden;

  padding: 0 14px;

  ${breakpoint('base', 'sm')`
    border: 1px solid ${({ theme }) => theme.colors.iron};
    border-radius: 8px;
  `}
`

export const SearchIcon = styled(SearchOutlined)`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
`

export const ClearIcon = styled(Cancel)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray};

  opacity: 1;
  transition: opacity 300ms linear;

  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
`

export const Input = styled.input`
  width: 100%;
  border: 0;
  margin-left: 10px;

  :focus {
    outline: 0;
  }

  ::placeholder {
    font-size: 16px;
    opacity: 50%;
    color: ${({ theme }) => theme.colors.bombay};
  }

  &[value=''] {
    ~ ${ClearIcon} {
      cursor: default;
      pointer-events: none;
      opacity: 0;
    }
  }
`
