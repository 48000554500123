import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { FormikValues, Formik } from 'formik'

// Components
import Layout from 'components/Layout'
import {
  InputField,
  FileUploadField,
  ListInputField,
  ButtonField,
} from 'components/Form'
import {
  WorkspaceMemberItem,
  WorkspaceMemberItemProps,
} from '../components/WorkspaceMemberItem'
import { useAccount } from 'context/AccountContext'
import { routes } from 'constants/routes'

// Styles
import { HeaderContainer } from './WorkspaceSettingsCategoriesView.styles'
import { FormikForm } from './CreateWorkspaceView.styles'

// GQL
import {
  GetWorkspacesDocument,
  useCreateWorkspaceMutation,
  useGetUserQuery,
} from 'gqlTypes'
import { useHistory } from 'react-router-dom'

interface WorkspaceFormValues {
  name?: string
  image?: string
  members?: WorkspaceMemberItemProps[]
}

export const CreateWorkspaceView: FC = () => {
  const { user } = useAccount()
  const { push } = useHistory()

  // GQL
  const { refetch } = useGetUserQuery({
    skip: true,
  })
  const [createWorkspace] = useCreateWorkspaceMutation({
    refetchQueries: [
      {
        query: GetWorkspacesDocument,
      },
    ],
    onCompleted: () => {
      push(routes.ENTRY.create({}))
    },
  })

  // Form
  const initialValues: WorkspaceFormValues = {
    name: '',
    members: [
      {
        id: user?.id!,
        email: user?.email,
      },
    ],
  }

  const handleAddMember = async (
    value: string,
    { values, setFieldValue }: FormikValues
  ) => {
    // Check if user exists
    const userResponse = await refetch({
      filters: {
        email: value,
      },
    })

    const userData = userResponse.data.user
    if (userData) {
      if (userData.id === user?.id) {
        console.error(
          'Your user is already on the list of members for this workspace'
        )
        return
      }

      const newMember = { id: userData.id, email: value }
      const newMembers = [...values['members'], ...[newMember]]
      setFieldValue('members', newMembers)
    } else {
      // TODO: Do something when user does not exist
      console.error('This user does not exist, please use a different email')
    }
  }

  const handleRemoveMember = (
    id: string,
    { values, setFieldValue }: FormikValues
  ) => {
    if (!values['members']) return

    const newMembers = values['members']?.slice()
    const memberIndex = newMembers.findIndex(
      (member: { id: string }) => member?.id === id
    )
    newMembers.splice(memberIndex, 1)
    setFieldValue('members', newMembers)
  }

  const handleSubmit = (values: WorkspaceFormValues) => {
    createWorkspace({
      variables: {
        input: {
          name: values.name!,
          type: 'PERSONAL' as any,
          // members: values.members?.length
          //   ? values.members.map((member) => ({
          //       id: member?.id,
          //       email: member.email!,
          //     }))
          //   : [],
        },
      },
    })
  }

  return (
    <Layout
      header={
        <HeaderContainer>
          <h1>Create your workspace</h1>
        </HeaderContainer>
      }
      alignItems="center"
      hideSidebarNavigation>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <FormikForm>
            {console.log(values)}
            <Grid container spacing={'48px'}>
              <Grid item sm={12}>
                <InputField
                  name="name"
                  label="Workspace name"
                  placeholder="Acme Inc"
                />
              </Grid>
              <Grid item sm={12}>
                <FileUploadField name="image" label="Workspace image" />
              </Grid>
              <Grid item sm={12}>
                <ListInputField
                  type="email"
                  name="members"
                  label="Members"
                  placeholder="Enter an email address"
                  items={(values.members || [])?.map((member) => (
                    <WorkspaceMemberItem
                      id={member.id}
                      name={member.name}
                      email={member.email}
                      removable={member.id !== user?.id}
                      onRemoveClick={(id) =>
                        handleRemoveMember(id, { values, setFieldValue })
                      }
                    />
                  ))}
                  onAddClick={(value) =>
                    handleAddMember(value, { values, setFieldValue })
                  }
                />
              </Grid>
              <Grid item sm={12}>
                <ButtonField type="submit" fullWidth>
                  Create Workspace
                </ButtonField>
              </Grid>
            </Grid>
          </FormikForm>
        )}
      </Formik>
    </Layout>
  )
}
