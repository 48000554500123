import React, { FC, useState, useContext, ReactNode } from 'react'

import { ModalInjectProps } from './Modal'

interface ModalProps {
  onSubmit?: () => void
}

type ShowModal = <T extends ModalInjectProps>(modal: FC<T>, props: T) => void

interface ModalContextProps extends ModalProps {
  showModal: ShowModal
  hideModal: () => void
}

const ModalContext = React.createContext<ModalContextProps | undefined>(
  undefined
)

export const ModalProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<ReactNode>(null)

  const showModal: ShowModal = (ModalComponent, props) => {
    setModal(<ModalComponent {...props} />)
  }

  const hideModal = () => {
    setModal(null)
  }

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modal}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}
