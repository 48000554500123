import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import { routes } from 'constants/routes'
import { useAccount } from 'context/AccountContext'

interface PublicOnlyRouteProps extends RouteProps {
  redirectPath?: string
}

const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = (props) => {
  const { redirectPath } = props
  const { isAuthenticated } = useAccount()

  if (!isAuthenticated) {
    return <Route {...props} />
  }

  return (
    <Redirect
      to={{
        pathname: redirectPath || routes.ENTRY.create({}),
      }}
    />
  )
}

export default PublicOnlyRoute
