import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

import { Variant, Size } from './Button'

export const getVariantStyles = ({ theme, $$variant, $$active }: any) => {
  switch ($$variant) {
    case 'primary': {
      return css`
        background: ${theme.colors.surfieGreen};
        color: ${theme.colors.white};
        border: 0;
      `
    }

    case 'secondary': {
      return css`
        background: ${theme.colors.white};
        color: ${theme.colors.surfieGreen};
        border: 1px solid ${theme.colors.surfieGreen};
      `
    }

    case 'tertiary': {
      return css`
        color: ${theme.colors.mineShaft};
        border: 1px solid ${theme.colors.dustyGray};

        :hover {
          background: ${theme.colors.aquaHaze};
          border: 1px solid transparent;
        }

        ${$$active &&
        css`
          background: ${theme.colors.aquaHaze};
          border: 1px solid transparent;
        `}
      `
    }
  }
}

export const getSizeStyles = ({ $$size }: any) => {
  switch ($$size) {
    case 'big': {
      return css`
        height: 48px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        padding: 0 24px;
      `
    }

    case 'small': {
      return css`
        height: 32px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        padding: 10px;
      `
    }

    default: {
      return css`
        height: 40px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        padding: 0 24px;
      `
    }
  }
}

export const ButtonContainer = styled.button<{
  $$variant: Variant
  $$size?: Size
  $$fullWidth?: boolean
  $$active?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ $$fullWidth }) => ($$fullWidth ? '100%' : 'fit-content')};
  flex-shrink: 0;

  border-radius: 8px;
  cursor: pointer;
  outline: 0;

  ${(props) => getVariantStyles(props)}

  ${(props) => getSizeStyles(props)}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .MuiSvgIcon-root {
    margin-right: 6px;
  }

  ${space}
  ${layout}
`

export const LinkContainer = styled.a`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.surfieGreen};
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
`

export const IconButtonContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 18px 0;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;

  outline: 0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.gray};
  }

  div {
    color: ${({ theme }) => theme.colors.mineShaft};
    margin-top: 16px;
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.surfieGreen};

    svg,
    div {
      color: ${({ theme }) => theme.colors.surfieGreen};
    }
  }

  ${space}
  ${layout}
`
