import styled, { css } from 'styled-components'
import { LayoutProps, layout } from 'styled-system'

import { breakpoint } from 'styles'

export const Container = styled.div<{ maxHeight?: string }>`
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight || 'initial'};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.mineShaft};
  overflow: auto;
  padding: 16px 8px;
`

export const Row = styled.div<{ clickable: boolean; expenseGroup: boolean }>`
  width: 100%;
  display: flex;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);

  ${({ expenseGroup, theme }) =>
    expenseGroup &&
    css`
      border-left: 4px solid ${theme.colors.surfieGreen};
    `}

  :not(:last-child) {
    margin-bottom: 16px;
  }

  :hover {
    background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
  }
`

export const Column = styled.div<
  LayoutProps & { $$flexDirection?: 'row' | 'column' }
>`
  ${layout}

  display: flex;
  flex-direction: ${({ $$flexDirection }) => $$flexDirection || 'row'};
  flex-wrap: wrap;

  > :not(:first-child) {
    margin-top: 8px;
  }

  ${breakpoint('base', 'md')`
   line-height: 1.5;
  `}
`

export const AmountColumnMobile = styled.div`
  position: relative;
  margin-left: auto;
`

export const ActionsMenuColumn = styled(Column)`
  justify-content: center;

  ${breakpoint('base', 'md')`
    position: absolute;
    top: -34px;
    right: -16px;
  `}
`

export const ListItemPlaceholder = styled.div`
  height: 56px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  margin-bottom: 16px;

  background: linear-gradient(
    -45deg,
    rgba(220, 221, 223, 1) 0%,
    rgba(14, 132, 132, 0.2315519957983193) 35%,
    rgba(231, 242, 242, 1) 100%
  );
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`
