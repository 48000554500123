import React from 'react'
import { Helmet } from 'react-helmet'

import Providers from 'containers/Providers'

import Router from './Router'

function App() {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Providers>
        <Router />
      </Providers>
    </>
  )
}

export default App
