import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'

import { Form, InputField } from 'components/Form'
import Button from 'components/Button/Button'
import { routes } from 'constants/routes'
import { useAccount } from 'context/AccountContext'

import { ReactComponent as LoginIllustration } from './login-illustration.svg'

const Container = styled.div`
  height: 100%;
  background: #f2f8f8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledLoginIllustration = styled(LoginIllustration)`
  position: absolute;
  bottom: 0;
  left: 0;
`

const FormContainer = styled.div`
  margin-right: 10%;
  position: relative;
  z-index: 10;
  max-width: 480px;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
`

const Title = styled.h1`
  font-size: 48px;
  line-height: 1.5;
  margin-bottom: 100px;
`

const Error = styled.div`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.pomegranate};
`

// const Span = styled.span`
//   margin: 10px 0;
//   text-align: center;
// `

const SignIn = () => {
  const { logIn } = useAccount()
  const { push } = useHistory()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true)

      await logIn(data.email, data.password)
      push(routes.ENTRY.create({}))
    } catch (error) {
      setError(error?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <StyledLoginIllustration />
      <FormContainer>
        <Title>
          Hello,
          <br />
          welcome to Penny.
        </Title>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField name="email" label="Email" required />
            </Grid>
            <Grid item xs={12}>
              <InputField
                type="password"
                name="password"
                label="Password"
                required
              />
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Error>{error}</Error>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" width="100%" disabled={loading}>
                Login
              </Button>
            </Grid>
          </Grid>
          {/* <Span>
            Don't have an account?{' '}
            <Button
              type="link"
              url={routes.SIGN_UP.create({})}
              onClick={() => push(routes.SIGN_UP.create({}))}>
              Sign up
            </Button>
          </Span> */}
        </StyledForm>
      </FormContainer>
    </Container>
  )
}

export default SignIn
