import {
  GetWorkspaceDocument,
  WorkspaceInvitationFragment,
  WorkspaceMemberFragment,
  useDeleteWorkspaceInvitationMutation,
  useDeleteWorkspaceMemberMutation,
  useGetWorkspaceQuery,
} from 'gqlTypes'
import React from 'react'
import { getLocalStorageItem } from 'utils/localStorage'
import { WorkspaceMemberItem } from '../WorkspaceMemberItem'

export const WorkspaceMembersList = () => {
  const { data } = useGetWorkspaceQuery({
    variables: {
      id: getLocalStorageItem('activeWorkspace'),
    },
  })

  const owner = data?.workspace?.owner

  const [deleteMember] = useDeleteWorkspaceMemberMutation({
    refetchQueries: [
      {
        query: GetWorkspaceDocument,
        variables: { id: getLocalStorageItem('activeWorkspace') },
      },
    ],
  })

  const [deleteInvitation] = useDeleteWorkspaceInvitationMutation({
    refetchQueries: [
      {
        query: GetWorkspaceDocument,
        variables: { id: getLocalStorageItem('activeWorkspace') },
      },
    ],
  })

  const handleRemoveWorkspaceMember = (
    type:
      | WorkspaceInvitationFragment['__typename']
      | WorkspaceMemberFragment['__typename'],
    id: string
  ) => {
    if (type === 'WorkspaceInvitation') {
      deleteInvitation({
        variables: { id },
      })
    } else {
      deleteMember({
        variables: { id },
      })
    }
  }

  return (
    <div>
      <WorkspaceMemberItem
        key={`member-${owner?.id}`}
        id={owner?.id}
        email={owner?.email}
        name={`${owner?.firstName} ${owner?.lastName}`}
        owner
      />

      {data?.workspace?.members.entries.map((member) => (
        <WorkspaceMemberItem
          key={`member-${member.id}`}
          type={member.__typename}
          id={member.id}
          email={
            member.__typename === 'WorkspaceMember'
              ? member.user.email
              : member.email
          }
          name={
            member.__typename === 'WorkspaceMember'
              ? `${member.user.firstName} ${member.user.lastName}`
              : member.email
          }
          removable
          onRemoveClick={handleRemoveWorkspaceMember}
        />
      ))}
    </div>
  )
}
