import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// Constants
import { routes } from 'constants/routes'

// Components
import SplashScreen from './components/SplashScreen/SplashScreen'

import PublicOnlyRoute from 'containers/PublicOnlyRoute'

// Pages
import SignIn from 'modules/SignIn/SignIn'
import SignOut from 'modules/SignOut/SignOut'
// import SignUp from 'pages/SignUp/SignUp'

import Overview from 'modules/Overview/views/Overview/Overview'
import { ExpensesView } from 'modules/Expenses/views/ExpensesView/ExpensesView'
import { ProjectDetailsLayout } from 'modules/Projects/components/ProjectDetailsLayout'
import { WorkspaceSettingsLayout } from 'modules/Workspace/components/WorkspaceSettingsLayout'

import AccountSettingsView from 'modules/Settings/views/AccountSettingsView'

import {
  CreateWorkspaceView,
  WorkspaceSettingsCategoriesView,
  WorkspaceSettingsGeneralView,
  WorkspaceSettingsMembersView,
  WorkspaceSettingsTagsView,
  AcceptWorkspaceInviteView,
} from 'modules/Workspace'

import StatisticsView from 'modules/Statistics/views/StatisticsView/StatisticsView'

// Context
import { useAccount } from 'context/AccountContext'
import PrivateRoute from 'containers/PrivateRoute'
import { Budget } from 'modules/Projects/views/ProjectDetailsView/Budget'
import { Expenses } from 'modules/Projects/views/ProjectDetailsView/Expenses'

const Router = () => {
  const { isInitialized, isAuthenticated } = useAccount()

  if (!isInitialized) return <SplashScreen />

  return (
    <Switch>
      {/* Non Auth routes */}
      <PublicOnlyRoute exact path={routes.SIGN_IN.template()}>
        <SignIn />
      </PublicOnlyRoute>
      <Route exact path={routes.SING_OUT.template()}>
        <SignOut />
      </Route>
      {/*
        <PublicOnlyRoute exact path={routes.SIGN_UP.template()}>
          <SignUp />
        </PublicOnlyRoute>
      */}

      {/* Auth routes */}
      {isAuthenticated ? (
        <>
          {/*
          FIXME: This route only works here, moving it further down will
          not work.
          https://github.com/pennyapp-finance/penny-website-frontend/issues/30
          */}
          <PrivateRoute exact path={routes.SETTINGS.template()}>
            <AccountSettingsView />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={[
              routes.ENTRY.template(),
              routes.OVERVIEW.template(),
              routes.GROUPS_NEW.template(),
              routes.NEW_GROUP_FROM_ACTIVE_GROUP.template(),
            ]}>
            <Overview />
          </PrivateRoute>
          <PrivateRoute exact path={[routes.EXPENSES.template()]}>
            <ExpensesView />
          </PrivateRoute>

          <PrivateRoute path={[routes.PROJECT_DETAILS.template()]}>
            <ProjectDetailsLayout>
              <Switch>
                <Route
                  path={[routes.PROJECT_BUDGET.template()]}
                  component={Budget}
                />
                <Route component={Expenses} />
              </Switch>
            </ProjectDetailsLayout>
          </PrivateRoute>

          <PrivateRoute exact path={routes.WORKSPACE_CREATE.template()}>
            <CreateWorkspaceView />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={routes.WORKSPACE_ACCEPT_INVITATION.template()}>
            <AcceptWorkspaceInviteView />
          </PrivateRoute>

          <PrivateRoute
            path={[
              routes.WORKSPACE_SETTINGS.template(),
              routes.WORKSPACE_SETTINGS_CATEGORIES.template(),
              routes.WORKSPACE_SETTINGS_TAGS.template(),
              routes.WORKSPACE_SETTINGS_MEMBERS.template(),
            ]}>
            <WorkspaceSettingsLayout>
              <Switch>
                <Route
                  exact
                  path={[routes.WORKSPACE_SETTINGS.template()]}
                  component={WorkspaceSettingsGeneralView}
                />
                <Route
                  exact
                  path={[routes.WORKSPACE_SETTINGS_CATEGORIES.template()]}
                  component={WorkspaceSettingsCategoriesView}
                />
                <Route
                  exact
                  path={[routes.WORKSPACE_SETTINGS_TAGS.template()]}
                  component={WorkspaceSettingsTagsView}
                />
                <Route
                  exact
                  path={[routes.WORKSPACE_SETTINGS_MEMBERS.template()]}
                  component={WorkspaceSettingsMembersView}
                />
              </Switch>
            </WorkspaceSettingsLayout>
          </PrivateRoute>

          <PrivateRoute exact path={routes.STATISTICS.template()}>
            <StatisticsView />
          </PrivateRoute>
        </>
      ) : (
        <Redirect to={routes.SIGN_IN.create({})} />
      )}
    </Switch>
  )
}

export default Router
