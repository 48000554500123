import React, { useState } from 'react'
import { Grid } from '@mui/material'
import moment from 'moment'

// Components
import Spinner from 'components/Spinner/Spinner'
import Layout from 'components/Layout'
import Panel from 'components/Panel/Panel'
import {
  Filters,
  BaseFiltersType,
  FiltersState,
} from 'components/Filters/Filters'

// GQL
import {
  useGetStatisticsQuery,
  GetStatisticsQuery,
  StatisticsFilterInput,
} from 'gqlTypes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

// Local Components
import Chart from 'modules/Statistics/components/Chart/Chart'

// Styles
import { ChartContainer } from './StatisticsView.styles'
import CategoryStatisticsItem from 'modules/Statistics/components/CategoryStatisticsItem/CategoryStatisticsItem'

// Types
interface ChartData {
  name: string
  amount: number
}

interface FiltersType extends BaseFiltersType {
  category?: string
}

// Helpers
const MONTH_NAMES = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dec',
}
type MonthNames = typeof MONTH_NAMES
type MonthNumber = keyof MonthNames

const getMonthName = (monthNumber: MonthNumber) => MONTH_NAMES[monthNumber]

const mapChartData = (
  data: NonNullable<GetStatisticsQuery['statistics']>['chart'],
  type: 'YEAR' | 'MONTH'
) => {
  let monthsData: any = []

  if (type === 'MONTH') {
    data?.data?.forEach((year) => {
      monthsData = year?.months?.map((month) => ({
        name: getMonthName(parseInt(month?.month!) as MonthNumber),
        amount: month?.total!,
      }))
    })
  }

  return monthsData
}

const currentYear = new Date().getUTCFullYear()
const INITIAL_DATE_FILTER = moment(new Date(`01/01/${currentYear}`)).format(
  'YYYY-MM-DD HH:mm:ss'
)

const StatisticsView = () => {
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  const [currentDateFilter, setCurrentDateFilter] = useState([
    INITIAL_DATE_FILTER,
  ])

  // Operations
  const { data, loading, refetch } = useGetStatisticsQuery({
    variables: {
      workspaceId: activeWorkspaceId,
      filters: {
        date: {
          startDate: INITIAL_DATE_FILTER,
          endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      },
    },
  })

  if (loading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    )
  }

  const handleFilterChange = <T extends keyof FiltersType>(
    name: T,
    value: NonNullable<FiltersType[T]>,
    { filters, setFilters, updateSearchValue }: FiltersState
  ) => {
    switch (name) {
      case 'category': {
        setFilters({ ...filters, category: value as string })
        updateSearchValue(name as any, value)
        break
      }

      case 'date': {
        if (value && Array.isArray(value)) setCurrentDateFilter(value as any)
        break
      }

      default: {
        setFilters({ ...filters, [name]: value })
        updateSearchValue(name as any, value)
      }
    }
  }

  const handleSearch = async (filters: FiltersType) => {
    const parsedFilters: StatisticsFilterInput = {
      categoryId: filters.category!,
    }

    if (filters.date?.length === 2 && filters.date[1] !== null) {
      parsedFilters.date = {
        startDate: filters.date[0] as string,
        endDate: filters.date[1] as string,
      }
    }

    refetch({
      filters: parsedFilters,
    })
  }

  const chartData = mapChartData(data?.statistics?.chart as any, 'MONTH')

  const getFormattedDateFilter = (): string => {
    if (!currentDateFilter)
      return moment(INITIAL_DATE_FILTER).format('DD-MM-yyyy')

    if (currentDateFilter.length === 1) {
      return moment(currentDateFilter[0]).format('DD-MM-yyyy')
    }

    const [date1, date2] = currentDateFilter
    return `${moment(date1).format('DD-MM-yyyy')} - ${moment(date2).format(
      'DD-MM-yyyy'
    )}`
  }

  return (
    <Layout
      header={
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Filters onChange={handleFilterChange} onSubmit={handleSearch} />
          </Grid>
        </Grid>
      }>
      {loading ? <Spinner /> : null}

      <Grid container spacing={4}>
        <Grid item sm={12} margin="10px">
          <Panel title={`Total spent, ${getFormattedDateFilter()}`}>
            <ChartContainer>
              <Chart<ChartData>
                data={chartData as any}
                dataKeyX="name"
                dataKeyY="amount"
              />
            </ChartContainer>
          </Panel>
        </Grid>
        <Grid item sm={12} md={8} margin="10px">
          <Panel title="Categories">
            {data?.statistics?.summary?.categories?.map((category) => (
              <CategoryStatisticsItem
                key={`summary-${category.category.id}`}
                name={category.category.name!}
                color={category.category.color!}
                amount={category.totals.total}
                percentage={category.totals.percentage}
              />
            ))}
          </Panel>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default StatisticsView
