import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'

// Components
import { useModal } from 'components/Modal/ModalContext'

// Operations
import { useCreateExpense, useCreateProjectExpense } from 'operations'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

// Types
import ExpenseForm, { FormValues } from './ExpenseForm'
import { TransactionType } from 'gqlTypes'

interface CreateExpenseProps {
  projectId?: string
}

const initialValues: FormValues = {
  name: '',
  date: moment().format('YYYY-MM-DD HH:mm:ss'),
  type: 'EXPENSE',
}

export const CreateExpense = ({ projectId }: CreateExpenseProps) => {
  const { hideModal } = useModal()
  const activeWorkspaceId = getLocalStorageItem('activeWorkspace')

  // Expenses operations
  const [createExpense] = useCreateExpense({
    onCompleted: () => {
      hideModal()
    },
  })
  const [createProjectExpense] = useCreateProjectExpense({
    id: projectId!,
    onCompleted: hideModal,
  })

  const handleSubmit = ({ amount, files, ...values }: FormValues) => {
    const filesMapped = files?.map((file) => file.id)

    const commonFormData = {
      ...values,
      amount: parseFloat(amount || '0'),
      files: filesMapped,
      workspaceId: activeWorkspaceId,
      type: values.type as TransactionType,
    }

    if (projectId) {
      createProjectExpense({
        projectId,
        ...commonFormData,
      })
    } else {
      createExpense(commonFormData)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <ExpenseForm
          loading={false}
          showDraftButton
          onCancel={hideModal}
          onSubmit={handleSubmit}
        />
      </Form>
    </Formik>
  )
}
