import styled, { css } from 'styled-components'

import { UnfoldMore, ExpandMore } from '@mui/icons-material'

import { breakpoint } from 'styles'

import UnstyledButton from 'components/Button/Button'

export const Toggle = styled.div<{ $$open: boolean }>`
  display: flex;
  align-items: center;
  height: min-content;
  padding: 7px 16px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.bombay};
  user-select: none;

  ${({ theme, $$open }) =>
    $$open &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.surfieGreen};
    `}

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.surfieGreen};
  }
`

export const Arrow = styled(UnfoldMore)`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }
`

export const Button = styled(UnstyledButton)`
  padding: 0;
  overflow: hidden;
`

export const ButtonTextContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    /* FIXME: Add Button hover styles to theme */
    background: rgba(255, 255, 255, 0.1);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    `}
`

export const ChevronContainer = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.alto};

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

export const Chevron = styled(ExpandMore)`
  justify-self: flex-end;

  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-right: 0;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 140px;
  width: max-content;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px HSLA(0, 0%, 0%, 0.16),
    0 8px 10px 1px HSLA(0, 0%, 0%, 0.05), 0 3px 14px 2px HSLA(0, 0%, 0%, 0.08);

  padding: 1.6rem 0;
  margin-top: 8px;

  z-index: 1;

  ${breakpoint('base', 'sm')`
    width: 100%;
    padding: 0;
    margin-top: 0;
    
    border-radius: 0;
    box-shadow: none;
  `}
`

export const OptionSection = styled.div`
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.colors.bombay};
  font-size: 14px;
  text-transform: uppercase;
`

export const OptionItem = styled.div<{ $$active?: boolean }>`
  padding: 0.8rem 1.6rem;
  cursor: pointer;

  ${({ $$active }) =>
    $$active &&
    css`
      color: ${({ theme }) => theme.colors.surfieGreen};
      background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
    `}

  :hover {
    color: ${({ theme }) => theme.colors.surfieGreen};
    background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
  }
`
