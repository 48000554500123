import React, { ChangeEvent, FC, ReactNode, useState } from 'react'

import { ItemsContainer, InputContainer } from './ListInputField.styles'
import BaseField, { BaseFieldProps } from 'components/Form/BaseField/BaseField'
import Input from 'components/Input/Input'
import Button from 'components/Button/Button'
import { InputType } from '../InputField/InputField'

interface ListInputFieldProps extends BaseFieldProps {
  type?: InputType
  items: ReactNode[]
  onAddClick: (text: string) => void
}

const ListInputField: FC<ListInputFieldProps> = ({
  type = 'text',
  name,
  label,
  placeholder,
  items,
  onAddClick,
  ...styles
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleAdd = () => {
    onAddClick(inputValue)
    setInputValue('')
  }

  return (
    <BaseField name={name} label={label} {...styles}>
      {items?.length ? <ItemsContainer>{items}</ItemsContainer> : null}
      <InputContainer>
        <Input
          type={type}
          value={inputValue}
          placeholder={placeholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setInputValue(event.target.value)
          }
        />
        <Button
          type="button"
          variant="secondary"
          height="40px"
          marginLeft="6px"
          disabled={!inputValue}
          onClick={handleAdd}>
          Add
        </Button>
      </InputContainer>
    </BaseField>
  )
}

export default ListInputField
