import React, { FC, useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { useBreakpoint } from 'styles'

import { ReactComponent as OptionsIcon } from 'icons/options-icon.svg'
import { MoreHoriz } from '@mui/icons-material'

// Styles
const ButtonContainer = styled.div`
  padding: 0 12px;
  cursor: pointer;
`

const OptionsIconStyled = styled(OptionsIcon)`
  color: ${({ theme }) => theme.colors.gray};
`

const OptionsIconMobile = styled(MoreHoriz)`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  min-width: 140px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  padding: 1.6rem 0;
  z-index: 1;
`

const OptionItem = styled.div`
  padding: 0.8rem 1.6rem;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.surfieGreen};
    background: ${({ theme }) => `${theme.colors.surfieGreen}10`};
  }
`

// Types
interface Option {
  name: string
  onClick: () => void
}

interface ActionsMenuProps {
  options: Option[]
}

const ActionsMenu: FC<ActionsMenuProps> = ({ options }) => {
  const [open, setOpen] = useState(false)

  const [
    referenceElement,
    setReferenceElement,
  ] = useState<SVGSVGElement | null>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'preventOverflow', enabled: false }],
  })

  const menuContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (menuContainerRef?.current?.contains(event.target as Node)) {
        return
      }

      setOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popperElement])

  const isMobile = !useBreakpoint('sm')

  return (
    <>
      <ButtonContainer onClick={() => setOpen(!open)}>
        {isMobile ? (
          <OptionsIconMobile ref={setReferenceElement} />
        ) : (
          <OptionsIconStyled ref={setReferenceElement} />
        )}
      </ButtonContainer>
      {open ? (
        <div ref={menuContainerRef}>
          <MenuContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            {options.map((option) => (
              <OptionItem key={option.name} onClick={option.onClick}>
                {option.name}
              </OptionItem>
            ))}
          </MenuContainer>
        </div>
      ) : null}
    </>
  )
}

export default ActionsMenu
