import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'

import {
  InputField,
  DatePickerField,
  PriceField,
  DropdownButtonField,
  CategoryField,
  FileUploadField,
  ButtonField,
  File,
  SplitButtonField,
} from 'components/Form'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import TagField from 'components/Form/TagField/TagField'
import Spinner from 'components/Spinner/Spinner'

export interface FormValues {
  name: string
  categoryId?: string
  tags?: string[]
  amount?: string
  date: string
  notes?: string | null
  files?: File[]
  draft?: boolean
  type: string
}

interface ExpenseFormProps {
  showDraftButton?: boolean
  loading: boolean
  onSubmit: (values: any) => void
  onCancel?: () => void
}

const ExpenseForm = ({
  showDraftButton = false,
  loading,
  onSubmit,
  onCancel,
}: ExpenseFormProps) => {
  const [uploadingFile, setUploadingFile] = useState(false)
  const { values, setFieldValue } = useFormikContext<FormValues>()

  if (loading) {
    return (
      <Modal.Body padded>
        <Spinner fullPage={false} />
      </Modal.Body>
    )
  }

  return (
    <>
      <Modal.Body padded>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SplitButtonField
              name="type"
              leftButton={{
                text: 'Expense',
                value: 'EXPENSE',
              }}
              rightButton={{
                text: 'Income',
                value: 'INCOME',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField name="name" label="Name" required />
          </Grid>
          <Grid item xs={12}>
            <CategoryField
              name="categoryId"
              label="Category"
              onCreateNew={(category) =>
                setFieldValue('category', category?.id!)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TagField
              multi
              name="tags"
              label="Tag"
              onCreateNew={(tag) => {
                setFieldValue('tags', [...(values?.tags ?? []), tag.id!])
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField name="date" label="Date" required />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PriceField name="amount" label="Amount" required={!values.draft} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              type="textarea"
              name="notes"
              label="Notes"
              placeholder="Additional notes"
            />
          </Grid>

          <Grid item xs={12}>
            <FileUploadField
              name="files"
              label="Files"
              onUploadStart={() => setUploadingFile(true)}
              onUploadEnd={() => setUploadingFile(false)}
            />
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="secondary" fullWidth onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            {showDraftButton ? (
              <DropdownButtonField
                type="submit"
                value={values.draft ? 'draft' : 'default'}
                onSubmit={(values) => {
                  onSubmit(values)
                }}
                options={[
                  {
                    text: 'Save',
                    value: 'default',
                    onClick: () => setFieldValue('draft', false),
                  },
                  {
                    text: 'Save as draft',
                    value: 'draft',
                    onClick: () => setFieldValue('draft', true),
                  },
                ]}
              />
            ) : (
              <ButtonField
                type="submit"
                fullWidth
                disabled={loading || uploadingFile}>
                Save
              </ButtonField>
            )}
          </Grid>
        </Grid>
      </Modal.Footer>
    </>
  )
}

export default ExpenseForm
