import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 0.4rem 0.8rem;
  height: 4.8rem;
  // TODO: Fix this color, it should be HSLA
  background: rgba(230, 230, 230, 0.3);
`

export const Button = styled.button<{ active: boolean }>`
  height: 4rem;
  font-size: 16px;
  font-weight: 500;

  border: 0;
  outline: 0;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 24px;

  :not(:last-child) {
    margin-right: 0.6rem;
  }

  :hover {
    font-weight: 600;
    background: ${({ theme }) => theme.colors.aquaHaze};
    color: ${({ theme }) => theme.colors.keppel};
    box-shadow: 0 5px 5px -3px hsla(0, 0%, 0%, 0.16),
      0 8px 10px 1px hsla(0, 0%, 0%, 0.05), 0 3px 14px 2px hsla(0, 0%, 0%, 0.05);
  }

  ${({ theme, active }) =>
    active
      ? css`
          font-weight: 600;
          background: ${theme.colors.aquaHaze};
          color: ${theme.colors.keppel};
          box-shadow: 0 5px 5px -3px hsla(0, 0%, 0%, 0.16),
            0 8px 10px 1px hsla(0, 0%, 0%, 0.05),
            0 3px 14px 2px hsla(0, 0%, 0%, 0.05);
        `
      : css`
          background: transparent;
          color: ${theme.colors.gray};
        `}
`
