import React, { FC } from 'react'
import { useField } from 'formik'
import moment from 'moment'

import DatePicker from 'components/DatePicker/DatePicker'
import BaseField, { BaseFieldProps } from '../BaseField/BaseField'
import { validateRequired } from '../Form'

interface DatePickerFieldProps extends Omit<BaseFieldProps, 'value'> {
  name: string
  label: string
  value?: Date
}

const DatePickerField: FC<DatePickerFieldProps> = ({
  name,
  label,
  // value,
  required,
  validate,
  ...styles
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ value }, {}, { setValue }] = useField({
    name,
    required,
    validate: validate ? validate : required ? validateRequired : undefined,
  })

  return (
    <BaseField name={name} value={value} label={label} {...styles}>
      <DatePicker
        value={value}
        onChange={(value: Date) => {
          setValue(moment(value).format('YYYY-MM-DD HH:mm:ss'))
        }}
      />
    </BaseField>
  )
}

export default DatePickerField
