import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { utc } from 'moment'
import { DateRange } from '@mui/lab/DateRangePicker'
import { debounce } from 'debounce'

import { BaseFiltersType } from './Filters'

// Utils
import { useOnClickOutside } from 'utils/useOnClickOutside'
import { useBreakpoint } from 'styles'

const FILTERS_WITH_VALUES_REGEX = /\w*:\w*/g

interface FilterControlsProps {
  onSubmit: (filters: BaseFiltersType) => void
}

export const useFilterControls = <F extends BaseFiltersType>({
  onSubmit,
}: FilterControlsProps) => {
  const isMobile = !useBreakpoint('sm')
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [datePickerOpen, setDatePickerOpen] = useState(false)

  const [filters, setFilters] = useState({} as F)
  const [searchValue, setSearchValue] = useState('')

  // Refs
  const searchInputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // Menu handlers
  const handleMenuOpen = () => {
    setFilterMenuOpen(true)

    searchInputRef.current?.focus()
  }

  const handleMenuClose = () => {
    setFilterMenuOpen(false)
    searchInputRef.current?.blur()
  }

  // Close menu when clicking outside
  useOnClickOutside(containerRef, () => {
    if (!datePickerOpen && !isMobile) handleMenuClose()
  })

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      // Open search when pressing cmd + k keys
      if (event.code === 'KeyK' && event.metaKey) {
        handleMenuOpen()
        return
      }

      // Close menu when Escape is pressed
      if (event.code === 'Escape') handleMenuClose()
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Update the search value including the multiple filters.
   */
  const updateSearchValue = <T extends keyof F>(
    name: T,
    value: NonNullable<F[T]>
  ) => {
    let newSearchValue = searchValue

    if (name in filters) {
      // Filter exists, let's remove it from the string
      if (name === 'date') {
        const DATE_BEFORE_WITH_VALUE_REGEX = /(from|to):(\w*-\w*)*/g
        newSearchValue = newSearchValue.replace(
          DATE_BEFORE_WITH_VALUE_REGEX,
          ''
        )
      } else {
        // eslint-disable-next-line no-useless-escape
        const FILTER_WITH_VALUE_REGEX = new RegExp(`${name}:\\w*`)

        newSearchValue = newSearchValue.replace(FILTER_WITH_VALUE_REGEX, '')
      }
    }

    // Only append boolean filters if the boolean value is true
    if (typeof value === 'boolean') {
      // For booleans make sure the value is true otherwise remove it
      if (value === true) {
        newSearchValue += `${newSearchValue && ' '}${name}:${value}`
      }
      setSearchValue(newSearchValue)
      return
    }

    // Append before/ after for dates
    if (name === 'date') {
      const dateValues = (value as unknown) as DateRange<unknown>

      let formattedValue = `${newSearchValue && ' '}from:${dateValues?.[0]}`
      if (dateValues?.[1]) {
        formattedValue += ` to:${dateValues?.[1]}`
      }

      newSearchValue += formattedValue
      setSearchValue(newSearchValue)
      return
    }

    if (typeof value !== 'boolean') {
      newSearchValue += `${newSearchValue && ' '}${name}:${value}`
      setSearchValue(newSearchValue)
      return
    }
  }

  const updateFiltersAccordingToSearchValue = (value: string) => {
    const capturedFilters = value.match(FILTERS_WITH_VALUES_REGEX)

    const newFilters: F =
      capturedFilters?.reduce((acc, curr) => {
        const [name, value] = curr.split(':')

        // For booleans
        // Check the type of the filter, for booleans grab the value on state
        // because the user can be typing words like "tru" can be are not valid
        const currentFilterValue = (filters as any)?.[name]
        if (typeof currentFilterValue === 'boolean') {
          return { ...acc, [name]: currentFilterValue }
        }

        // For dates
        if (name === 'from') {
          return { ...acc, date: [value] }
        }

        if (name === 'to') {
          return { ...acc, date: [filters.date?.[0], value] }
        }

        // For other filters just include filter name and value
        return { ...acc, [name]: value }
      }, {} as F) || ({} as F)

    // Exclude all selectable filters and grab only the "name" filter value
    const strValue: string = (value as string) || ''
    const nameFilterValue = strValue.replace(FILTERS_WITH_VALUES_REGEX, '')

    setFilters({ name: nameFilterValue.trim(), ...newFilters })
  }

  const handleFilterChange = <T extends keyof F>(
    name: T,
    value: NonNullable<F[T]>
  ) => {
    switch (name) {
      case 'name': {
        setSearchValue((value as unknown) as string)
        updateFiltersAccordingToSearchValue((value as unknown) as string)
        break
      }

      case 'date': {
        const [startDate, endDate] = (value as unknown) as DateRange<unknown>
        const formattedDates = [
          startDate ? utc(startDate as string).format('DD-MM-yyyy') : null,
          endDate ? utc(endDate as string).format('DD-MM-yyyy') : null,
        ]

        setFilters({
          ...filters,
          date: (value as unknown) as DateRange<unknown>,
        })
        updateSearchValue(name, formattedDates as any)
        break
      }
    }
  }

  // Form submit
  const submit = useCallback(onSubmit, [])

  const debouncedFormSubmit = useMemo(() => debounce(submit, 300), [submit])

  useEffect(() => {
    // Trigger refetch query with all filter values
    if (Object.keys(filters).length) debouncedFormSubmit(filters)
  }, [debouncedFormSubmit, filters])

  const handleClearFilters = () => {
    setFilters({} as F)
    setSearchValue('')
    handleMenuClose()
    debouncedFormSubmit({})
  }

  return {
    containerRef,

    searchInputRef,
    searchValue,

    filters,
    handleFilterChange,
    setFilters,
    updateSearchValue,
    handleClearFilters,

    canShowMobileMenu: isMobile && filterMenuOpen,
    filterMenuOpen,
    handleMenuOpen,
    handleMenuClose,

    setDatePickerOpen,
  }
}
