import styled from 'styled-components'
import { AccountCircle } from '@mui/icons-material'

// Utils
import { breakpoint } from 'styles'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2.2rem;
`

export const Avatar = styled(AccountCircle)`
  &.MuiSvgIcon-root {
    width: 48px;
    height: 48px;

    ${breakpoint('base', 'sm')`
      width: 32px;
      height: 32px;
  `}
  }
`

export const Photo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
`
