import React from 'react'
import { Formik, Form } from 'formik'

import { useModal } from 'components/Modal/ModalContext'

// Types
import {
  GetProjectDetailsDocument,
  useGetProjectDetailsQuery,
  useUpdateProjectMutation,
} from 'gqlTypes'
import ProjectFormContent, { FormValues } from './ProjectFormContent'

interface UpdateProjectProps {
  id: string
}

export const UpdateProject = ({ id }: UpdateProjectProps) => {
  const { hideModal } = useModal()

  // Operations
  const handleOnCompleted = () => {
    hideModal()
  }

  const { data, loading } = useGetProjectDetailsQuery({
    variables: {
      id,
    },
  })
  const project = data?.project

  const [updateProject] = useUpdateProjectMutation({
    refetchQueries: [
      {
        query: GetProjectDetailsDocument,
        variables: {
          id,
        },
      },
    ],
    onCompleted: handleOnCompleted,
  })

  // Handles
  const handleSubmit = (values: FormValues) => {
    updateProject({
      variables: {
        id,
        input: values,
      },
    })
  }

  const getInitialFormValues = (): FormValues => {
    const tags = project?.tags?.map(({ id }) => id)

    // Let's support only one file for now
    // TODO: Implement this
    // const files = project?.files?.map((file) => ({
    //   id: file.id,
    //   name: file.name,
    //   url: file.url,
    //   ext: file.mimetype,
    // }))

    return {
      name: project?.name!,
      categoryId: project?.category?.id || undefined,
      tags,
      date: project?.date || new Date(),
      description: project?.description || null,
    }
  }

  return (
    <Formik
      initialValues={getInitialFormValues()}
      onSubmit={handleSubmit}
      enableReinitialize>
      <Form>
        <ProjectFormContent
          submitButtonText="Update Project"
          loading={loading}
        />
      </Form>
    </Formik>
  )
}
