import { BREAKPOINTS_IN_PX } from './breakpoints'
import { colors } from './colors'
import { typography } from './typography'
import { transitions } from './transitions'

const theme = {
  colors,
  typography,
  breakpoints: BREAKPOINTS_IN_PX,
  transitions,
}

export default theme
