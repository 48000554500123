import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { ReactComponent as DeleteIconUnstyled } from 'icons/cross-icon.svg'

export interface TagValue {
  text: string
  value: string
}

interface BaseTagProps {
  tag: TagValue | string
  onDeleteClick?: (tag: TagValue) => void
}

interface CategoryVariantProps extends BaseTagProps {
  variant?: 'category'
  color: string
}

interface TagVariantProps extends BaseTagProps {
  variant: 'tag'
  color?: never
}

type TagProps = CategoryVariantProps | TagVariantProps

const Container = styled.div<{ color: string; variant: 'category' | 'tag' }>`
  display: flex;
  border-radius: 4px;
  padding: 2px 8px;
  width: fit-content;
  text-transform: uppercase;
  letter-spacing: 1;

  ${({ theme, color, variant }) =>
    variant === 'category'
      ? css`
          background: ${color};
        `
      : css`
          color: ${theme.colors.mercury};
          border: 1px solid ${theme.colors.dustyGray};
        `}

  ${space}
`

const Text = styled.span<{ variant: 'category' | 'tag' }>`
  width: min-content;
  align-self: center;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme, variant }) =>
    variant === 'category' ? theme.colors.white : theme.colors.dustyGray};
`

const DeleteIcon = styled(DeleteIconUnstyled)`
  width: 10px;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 8px;
  cursor: pointer;
`

const Tag: FC<SpaceProps & TagProps> = ({
  tag,
  color,
  variant = 'category',
  onDeleteClick,
  ...styles
}) => {
  return (
    <Container variant={variant} {...styles} color={color || ''}>
      <Text variant={variant}>{typeof tag === 'string' ? tag : tag?.text}</Text>
      {typeof tag !== 'string' && onDeleteClick && (
        <DeleteIcon onClick={() => onDeleteClick(tag)} />
      )}
    </Container>
  )
}

export default Tag
