import styled, { css } from 'styled-components'

export const SplitButtonContainer = styled.div`
  display: flex;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.iron};
`

export const ButtonContainer = styled.button<{
  $$active?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin: 0.4rem;
  padding: 0 24px;

  cursor: pointer;
  border-radius: 8px;
  outline: 0;
  border: 0;

  height: 36px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;

  ${({ theme, $$active }) =>
    $$active
      ? css`
          background: ${theme.colors.surfieGreen};
          color: ${({ theme }) => theme.colors.white};
        `
      : css`
          background: ${theme.colors.white};
          color: ${theme.colors.surfieGreen};
          border: 1px solid ${theme.colors.surfieGreen};
        `}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .MuiSvgIcon-root {
    margin-right: 6px;
  }
`
