import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useHistory } from 'react-router'

import { BaseUserFragment, useLoginMutation, useGetUserQuery } from 'gqlTypes'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage'
import localStorageKeys from 'constants/localStorageKeys'
import { routes } from 'constants/routes'

interface AccountContextProps {
  user?: BaseUserFragment | null
  isInitialized: boolean
  isAuthenticated: boolean
  refreshAuthenticationCheck: () => void
  logIn: (email: string, password: string) => void
  logOut: () => void
}

export const AccountContext = createContext<AccountContextProps | undefined>(
  undefined
)

export const AccountProvider: FC = ({ children }) => {
  const [loginMutation] = useLoginMutation()
  const history = useHistory()

  const [isInitialized, setIsInitialized] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const { data: userData, refetch: refetchUser } = useGetUserQuery({
    skip: !isAuthenticated,
  })

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const token = getLocalStorageItem('accessToken')
      const user: BaseUserFragment = getLocalStorageItem('user')

      if (token && user) {
        try {
          const { data: userData } = await refetchUser({
            filters: {
              id: user.id!,
            },
          })

          setLocalStorageItem('user', JSON.stringify(userData.user))
          setIsAuthenticated(true)
          setIsInitialized(true)
        } catch {
          logOut()
          history.push(routes.SIGN_IN.create({}))
        }
      } else {
        setIsInitialized(true)
      }
    }

    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshAuthenticationCheck = () => {
    setIsAuthenticated(
      Boolean(getLocalStorageItem('accessToken') && getLocalStorageItem('user'))
    )
  }

  const logIn = async (email: string, password: string) => {
    try {
      const { data } = await loginMutation({
        variables: {
          email,
          password,
        },
      })
      setLocalStorageItem('accessToken', data?.login.jwt!)

      const refetchUserData = await refetchUser({
        filters: { id: data?.login.user.id! },
      })
      setLocalStorageItem('user', JSON.stringify(refetchUserData.data.user))

      setIsAuthenticated(true)

      return data
    } catch (error) {
      throw error
    }
  }

  const logOut = () => {
    setIsAuthenticated(false)
    localStorage.removeItem(localStorageKeys.accessToken)
    localStorage.removeItem(localStorageKeys.user)
  }

  return (
    <AccountContext.Provider
      value={{
        isInitialized,
        user: userData?.user || null,
        isAuthenticated,
        refreshAuthenticationCheck,
        logIn,
        logOut,
      }}>
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  const context = useContext(AccountContext)

  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountContext')
  }

  return context
}
