import styled, { css } from 'styled-components'

// Components
import MenuUnstyled from 'components/Layout/Menu/Menu'
import { Menu as ToggleUnstyled } from '@mui/icons-material'

// Utils
import { breakpoint } from 'styles'

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.mercury};
  margin-top: 32px;
`

export const Menu = styled(MenuUnstyled)`
  margin-top: 32px;

  ${breakpoint('base', 'sm')`
    margin-top: 32px;
  `}
`

export const Container = styled.div<{ $$open: boolean }>`
  display: flex;
  flex-direction: column;
  width: 10rem;
  border-right: 2px solid ${({ theme }) => theme.colors.mercury};
  padding: 3.2rem 0 1.6rem 0;
  background: ${({ theme }) => theme.colors.white};

  ${({ $$open }) => breakpoint('base', 'sm')`
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    padding: 12px 0;
    transition: all .5s linear;
    border-right: 0;
    box-shadow: 10px 0px 8px -2px ${({ theme }) => theme.colors.mercury};

    ${
      $$open &&
      css`
        top: 0;
        left: 0;
      `
    }
  `};
`

export const UserMenuContainer = styled.div`
  margin-top: auto;
`

export const Toggle = styled(ToggleUnstyled)<{ $$open: boolean }>`
  position: absolute;
  top: 12px;
  left: 16px;

  &.MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
`
