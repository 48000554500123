import styled from 'styled-components'

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
`
export const ExpenseListContainer = styled.div`
  display: flex;
  margin: 0 -0.8rem;
  overflow: auto;
`
