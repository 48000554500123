import styled from 'styled-components'
import { Euro as EuroUnstyled } from '@mui/icons-material'

// Components
import { Input as StyledInput } from 'components/Form/InputField/InputField.styles'

export const InputContainer = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.iron};
  border-radius: 8px;
  justify-content: space-between;
`

export const Currency = styled.div`
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin-left: 6px;
`

export const Input = styled(StyledInput)`
  width: 100%;
  border: 0;
  padding-left: 0;
  margin-left: 11px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const EuroIcon = styled(EuroUnstyled)`
  color: ${({ theme }) => theme.colors.mineShaft};
`
