import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'

// Components
import { useModal } from 'components/Modal/ModalContext'

// Constants
import { routes } from 'constants/routes'

// Types
import {
  BudgetAllocationFormContent,
  FormValues,
} from './BudgetAllocationFormContent'
import { useCreateBudgetAllocationMutation } from 'gqlTypes'

const initialValues: Partial<FormValues> = {}

interface CreateBudgetFormProps {
  budgetId: string
}

export const CreateBudgetAllocationForm = ({
  budgetId,
}: CreateBudgetFormProps) => {
  const { hideModal } = useModal()

  // Route handling
  const { push } = useHistory()

  const [createBudget] = useCreateBudgetAllocationMutation({
    onCompleted: hideModal,
  })

  // Handles
  const handleCancel = () => {
    hideModal()
    push(routes.ENTRY.create({}))
  }

  const handleSubmit = (values: FormValues) => {
    createBudget({
      variables: {
        input: { ...values, budgetId },
      },
    })
  }

  return (
    <Formik initialValues={initialValues as any} onSubmit={handleSubmit}>
      <Form>
        <BudgetAllocationFormContent
          submitButtonText="Create Budget Allocation"
          loading={false}
          onCancel={handleCancel}
        />
      </Form>
    </Formik>
  )
}
