import React, { FC } from 'react'
import { ReactComponent as TrashIcon } from 'icons/trash-icon.svg'

import {
  Container,
  TextContainer,
  Name,
  Email,
  Role,
  RightContainer,
  InvitationPending,
} from './WorkspaceMemberItem.styles'
import { WorkspaceInvitationFragment, WorkspaceMemberFragment } from 'gqlTypes'

export interface WorkspaceMemberItemProps {
  type?:
    | WorkspaceInvitationFragment['__typename']
    | WorkspaceMemberFragment['__typename']
  id?: string
  name?: string
  role?: string
  email?: string
  onRemoveClick?: (
    type:
      | WorkspaceInvitationFragment['__typename']
      | WorkspaceMemberFragment['__typename'],
    id: string
  ) => void
  removable?: boolean
  owner?: boolean
  invitePending?: boolean
}

export const WorkspaceMemberItem: FC<WorkspaceMemberItemProps> = ({
  type,
  id,
  name,
  email,
  role,
  onRemoveClick,
  removable,
  invitePending,
  owner,
}) => {
  const renderRole = () => {
    if (owner) return <Role>Owner</Role>

    if (role) return <Role>Role</Role>
  }

  return (
    <Container>
      <TextContainer>
        <Name>{name || email}</Name>
        {email ? <Email>{email}</Email> : null}
      </TextContainer>
      <RightContainer>
        {invitePending ? (
          <InvitationPending>Invitation pending</InvitationPending>
        ) : null}
        {renderRole()}
        {removable && (
          <TrashIcon
            onClick={() => onRemoveClick && id && onRemoveClick(type!, id)}
          />
        )}
      </RightContainer>
    </Container>
  )
}
