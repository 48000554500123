import React from 'react'
import ReactDOM from 'react-dom'
import ExpensesList from '../../../Expenses/components/ExpensesList/ExpensesList'
import { ExpenseFormModal } from 'modules/Expenses/components/ExpenseFormModal'
import { UpdateExpense } from 'modules/Expenses/components/ExpenseForm'
import { useModal } from 'components/Modal/ModalContext'
import { Grid } from '@mui/material'

import { useExpensesPagination } from 'modules/Expenses/components/ExpensesList/useExpensesPagination'
import {
  GetExpensesDocument,
  useDeleteExpenseMutation,
  useGetExpensesQuery,
} from 'gqlTypes'
import { useParams } from 'react-router-dom'
import Spinner from 'components/Spinner/Spinner'

import { Filters, BaseFiltersType, FiltersState } from 'components/Filters'

interface FiltersType extends BaseFiltersType {
  category?: string
}

const LayoutHeaderPortal = ({ children }: React.PropsWithChildren<{}>) => {
  const el = document.getElementById('layout-header-portal')
  if (!el) return null

  return ReactDOM.createPortal(children, el)
}

export const Expenses = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const { showModal, hideModal } = useModal()

  const { data, fetchMore, networkStatus, refetch } = useGetExpensesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      workspaceId,
      pagination: {
        limit: 20,
        page: 1,
      },
    },
  })

  const expenses = data?.transactions.entries

  const [deleteExpense] = useDeleteExpenseMutation({
    refetchQueries: [
      {
        query: GetExpensesDocument,
        variables: {
          workspaceId,
          pagination: {
            limit: 20,
            page: 1,
          },
        },
      },
    ],
  })

  const handleDelete = (id: string) => {
    deleteExpense({
      variables: {
        id,
      },
    })
  }

  const { placeholders } = useExpensesPagination({
    data: expenses || [],
    fetchMore,
    page: data?.transactions.pagination?.page! + 1,
    totalCount: data?.transactions.pagination?.total,
  })

  // TODO: Implement this
  // const [convertToParentExpense] = useConvertToParentExpenseMutation({
  //   onCompleted: hideModal,
  // })

  // const handleConvertExpenseGroup = (item: BaseExpenseDetailsFragment) => {
  //   showModal(ConvertToExpenseGroupModal, {
  //     onSubmit: (data: { name: string; category: { value: string } }) => {
  //       convertToParentExpense({
  //         variables: {
  //           id: item.id!,
  //           input: {
  //             name: data.name,
  //             categoryId: data?.category?.value,
  //           },
  //         },
  //       })
  //     },
  //     onCloseClick: hideModal,
  //   })
  // }

  const handleFilterChange = <T extends keyof FiltersType>(
    name: T,
    value: NonNullable<FiltersType[T]>,
    { filters, setFilters, updateSearchValue }: FiltersState
  ) => {
    switch (name) {
      // case 'category': {
      //   setFilters({ ...filters, category: value as string })
      //   updateSearchValue(name as any, value)
      //   break
      // }

      default: {
        setFilters({ ...filters, [name]: value })
        updateSearchValue(name as any, value)
      }
    }
  }

  const handleSearch = async (filters: FiltersType) => {
    refetch({
      filters: {
        // parentExpense: id,
        name: filters.name,
        // categoryId: filters.category,
        // date: {
        //   between: filters.date,
        // },
      },
    })
  }

  const layoutHeader = (
    <LayoutHeaderPortal>
      <Filters onChange={handleFilterChange} onSubmit={handleSearch} />
    </LayoutHeaderPortal>
  )

  if (networkStatus === 1) {
    return <Spinner />
  }

  if (!expenses?.length) {
    return (
      <>
        {layoutHeader}

        <Grid container textAlign="center" spacing={2}>
          <Grid item sm={12}>
            You don't have expenses
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {layoutHeader}

      <ExpensesList
        items={expenses}
        canPinExpenses={false}
        onClick={(item) => {
          showModal(ExpenseFormModal, {
            title: `Edit expense`,
            children: <UpdateExpense id={item?.id} />,
            onCloseClick: hideModal,
          })
        }}
        onEditClick={(item) => {
          // TODO: Review this.
          // Does not make sense to have the same action twice
          showModal(ExpenseFormModal, {
            title: `Edit expense`,
            children: <UpdateExpense id={item?.id} />,
            onCloseClick: hideModal,
          })
        }}
        onDeleteClick={handleDelete}
        placeholders={placeholders}
      />
    </>
  )
}
