import React, { FC } from 'react'
import { FlattenSimpleInterpolation } from 'styled-components'

import Sidebar from './Sidebar'
import {
  MainContainer,
  ContentContainer,
  Header,
  Container,
} from './Layout.styles'

interface LayoutProps {
  header?: React.ReactNode
  alignItems?: 'left' | 'center'
  containerStyleOverrides?: FlattenSimpleInterpolation
  hideSidebarNavigation?: boolean
}

const Layout: FC<LayoutProps> = ({
  header,
  alignItems = 'left',
  containerStyleOverrides,
  hideSidebarNavigation,
  children,
}) => {
  return (
    <MainContainer>
      <Sidebar hideMenuItems={hideSidebarNavigation} />

      <ContentContainer>
        {header ? (
          <Header>
            <div
              id="layout-header-portal"
              style={{ display: 'flex', flex: 1 }}></div>
            {header}
          </Header>
        ) : null}

        <Container
          styleOverrides={containerStyleOverrides}
          alignItems={alignItems}>
          {children}
        </Container>
      </ContentContainer>
    </MainContainer>
  )
}

export default Layout
