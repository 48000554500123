import { Card } from 'components/Card'
import styled, { css } from 'styled-components'
import { breakpoint } from 'styles'

export const WorkspaceTitle = styled.h1`
  width: 100%;
  margin: 0;
  font-size: 2.8rem;
`

export const OverviewGridContainer = styled.div`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: start;
  max-width: 100%;
  margin-right: auto;

  ${breakpoint('xs')`
    margin-right: 0;
  `}
`

export const layoutContainerStyles = css`
  /* These need to be override because of the box-shadows on the list items  */
  width: calc(100% + 16px);
  margin-left: -8px;
  margin-top: -16px;
  padding: 1.6rem 0.8rem;
`

export const FutureItem = styled.div<{
  marginTop?: boolean
  marginBottom?: boolean
}>`
  border-radius: 8px;
  background: #e3e3e3;
  padding: 2rem;

  ${({ marginTop }) => marginTop && 'margin-top: 2rem;'}

  ${({ marginBottom }) => marginBottom && 'margin-bottom: 2rem;'}

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
  }
`

export const AddProjectCard = styled(Card)`
  min-width: 32rem;
  height: 13.8rem;
  align-items: center;

  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }
`
AddProjectCard.defaultProps = {
  variant: 'secondary',
}

export const AddProjectText = styled.span`
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray};
  width: 100%;
  text-align: center;
`
