import React from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as SpinnerIcon } from './spinner.svg'
import { breakpoint } from 'styles'

const Overlay = styled.div<{ fullPage?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ fullPage }) =>
    fullPage &&
    css`
      width: calc(100% - 80px);
      height: 100%;
      position: absolute;
      top: 0;
      left: 80px;

      ${breakpoint('base', 'sm')`
            width: 100%;
            left: 0;
          `}
    `};
`

interface SpinnerProps {
  fullPage?: boolean
}

const Spinner = ({ fullPage = true }: SpinnerProps) => {
  return (
    <Overlay fullPage={fullPage}>
      <SpinnerIcon />
    </Overlay>
  )
}

export default Spinner
