import React, { FC } from 'react'

import { Container, MenuButton } from './Menu.styles'

// Types
interface MenuItems {
  text: string
  icon: any
  url: string
  active: boolean
  onClick: () => void
}

interface MenuProps {
  items: MenuItems[]
  className?: string
}

const Menu: FC<MenuProps> = ({ items, className }) => {
  return (
    <Container className={className}>
      {items.map((item) => (
        <MenuButton
          key={`menu-item-${item.text}`}
          href={item.url}
          $$active={item.active}
          onClick={(event) => {
            event.preventDefault()
            item.onClick()
          }}>
          {item.icon}
          {item.text}
        </MenuButton>
      ))}
    </Container>
  )
}

export default Menu
