import React, { FC } from 'react'

import { ReactComponent as Logo } from 'icons/logo.svg'
import { Container } from './SplashScreen.styles'

const SlashScreen: FC = () => (
  <Container>
    <Logo />
  </Container>
)

export default SlashScreen
