// GQL
import {
  CreateProjectExpenseMutationVariables,
  GetProjectDetailsDocument,
  useCreateProjectExpenseMutation,
} from 'gqlTypes'

// Utils
import { getLocalStorageItem } from 'utils/localStorage'

interface UseCreateProjectExpense {
  id: string
  onCompleted: () => void
}

export const useCreateProjectExpense = ({
  id,
  onCompleted,
}: UseCreateProjectExpense) => {
  const [mutate] = useCreateProjectExpenseMutation({
    refetchQueries: [
      {
        query: GetProjectDetailsDocument,
        variables: {
          id,
          pagination: {
            limit: 20,
            page: 1,
          },
        },
      },
    ],
    onCompleted,
  })

  const createProjectExpense = ({
    ...data
  }: CreateProjectExpenseMutationVariables['input']) => {
    mutate({
      variables: {
        input: {
          ...data,
          workspaceId: getLocalStorageItem('activeWorkspace'),
        },
      },
    })
  }

  return [createProjectExpense]
}
