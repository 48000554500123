import React, { FC } from 'react'

// Components
import BaseField, { BaseFieldProps } from 'components/Form/BaseField/BaseField'
import { validateRequired } from 'components/Form'

// Styles
import { InputContainer, Input, Currency, EuroIcon } from './PriceField.styles'

interface PriceFieldProps extends BaseFieldProps {
  onChange?: any
}

const PriceField: FC<PriceFieldProps> = ({
  name,
  label,
  placeholder,
  required,
  validate,
  disabled,
  ...styles
}) => {
  return (
    <BaseField name={name} label={label} {...styles}>
      <InputContainer>
        <Currency>
          <EuroIcon fontSize="large" />
        </Currency>
        <Input
          type="number"
          id={name}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          validate={
            validate ? validate : required ? validateRequired : undefined
          }
          step=".01"
        />
      </InputContainer>
    </BaseField>
  )
}

export default PriceField
